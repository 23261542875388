import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Ffr from '../fOOTER/Ffr';
import FHeader from '../Header/FHeader';


const Sfr = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
  return (
    <div>
      <FHeader/>
      
      <div id='titlescontainersound'>
  <div id='titlessound'>
    <h1><strong>Outil de Test Audio en Ligne</strong></h1>
    <h2>Vérifiez les écouteurs ou les haut-parleurs gauche et droit en un seul clic</h2>
  </div>
</div>

<div id='buttonscontainer'>
  <div id='buttons'>
    <button title='Cliquez pour Vérification Gauche' onClick={leftplay}>
      <HiPlay id='ilclass' />
      <span>Haut-Parleur Gauche</span>
    </button>
    
    <button title='Cliquez pour Vérification des Deux Haut-Parleurs' onClick={bothplay}>
      <BsSpeaker id='iclass'/>
      <span>Les Deux</span>
    </button>

    <button title='Cliquez pour Vérification Droite' onClick={rightplay}>
      <HiPlay id='iclass' />
      <span>Haut-Parleur Droit</span>
    </button>
  </div>
</div>

<div id='hw'>
  <div className='howto'>
    <h3>Comment utiliser l'Outil de Test Audio</h3>
    <ol>
      <li>Cliquez sur le bouton <strong>Haut-Parleur Gauche</strong> pour vérifier si le haut-parleur gauche de votre appareil fonctionne.</li>
      <li>Cliquez sur <strong>Les Deux</strong> pour vérifier si le haut-parleur droit de votre appareil fonctionne.</li>
      <li>Appuyez sur le bouton <strong>Haut-Parleur Droit</strong> au milieu pour tester les deux haut-parleurs simultanément.</li>
    </ol>
  </div>
</div>


<div className='faqsound'>
  <div id='fnqsound'>
    <div>
      <h4>Quel est l'objectif d'un contrôle de haut-parleur gauche-droite ?</h4>
      <p>Un contrôle de haut-parleur gauche-droite permet de vérifier si les canaux gauche et droit de votre casque ou de vos haut-parleurs fonctionnent correctement. Il contribue à maintenir une restitution audio équilibrée pour une expérience d'écoute optimale avec différents types de médias.</p>
    </div>

    <div>
      <h4>Comment savoir si mes haut-parleurs ont besoin d'un contrôle gauche-droite ?</h4>
      <p>Si vous remarquez des déséquilibres sonores, tels qu'un côté plus fort ou plus silencieux que l'autre, ou si vous rencontrez des distorsions ou un manque de clarté lors de la lecture audio, il est conseillé d'effectuer un contrôle gauche-droite des haut-parleurs.</p>
    </div>

    <div>
      <h4>Est-il possible d'effectuer un contrôle de haut-parleur gauche-droite sur des écouteurs et des haut-parleurs externes ?</h4>
      <p>Oui, notre outil vous permet d'effectuer des contrôles de haut-parleur gauche-droite sur des écouteurs et des haut-parleurs externes. Que vous utilisiez des appareils filaires ou sans fil, notre outil offre une solution pratique pour vérifier l'équilibre audio et la fonctionnalité.</p>
    </div>

    <div>
      <h4>Cet outil est-il compatible avec les appareils iOS ?</h4>
      <p>Absolument. Cet outil est compatible avec tous les appareils. Ouvrez simplement votre navigateur et accédez à MicTestAudio.com pour accéder au test audio. Notre site web est accessible sur différents navigateurs, y compris Chrome, Safari, Edge et Firefox.</p>
    </div>

    <div>
      <h4>Que faire si je rencontre des problèmes lors du contrôle de haut-parleur gauche-droite ?</h4>
      <p>Si vous rencontrez des problèmes, tels qu'un canal ne produisant aucun son ou des différences de volume flagrantes entre les canaux gauche et droit, vous devez vérifier vos paramètres audio. Cela peut inclure l'ajustement des paramètres de l'appareil, la vérification des connexions de câbles ou la recherche d'une assistance professionnelle si nécessaire.</p>
    </div>

    <div>
      <h4>Est-il possible d'effectuer un contrôle de haut-parleur gauche-droite en ligne ?</h4>
      <p>Oui, notre outil de test audio en ligne vous permet d'effectuer facilement un contrôle de haut-parleur gauche-droite depuis votre appareil, sans nécessiter de logiciel supplémentaire ou de téléchargements. Il vous suffit de vous rendre sur notre site web via votre navigateur web et de suivre les instructions pour garantir une restitution audio équilibrée.</p>
    </div>

    <div>
      <h4>Comment puis-je vérifier si mes haut-parleurs ou mes écouteurs fonctionnent correctement ?</h4>
      <p>Effectuer un test audio est un moyen fiable de déterminer la fonctionnalité des haut-parleurs ou des écouteurs. En diffusant de l'audio via différents canaux (gauche et droite), les utilisateurs peuvent confirmer que les deux côtés produisent un son sans distorsion.</p>
    </div>

    <div>
      <h4>Comment savoir si le haut-parleur fonctionne ou non ?</h4>
      <p>Lorsque votre casque ou vos haut-parleurs sont allumés, vous devez cliquer sur les boutons étiquetés gauche, droite et les deux pour les tester individuellement. Si vous cliquez sur le bouton gauche et n'entendez aucun son du côté gauche, cela signifie que le haut-parleur gauche ne fonctionne pas. Il en va de même pour le côté droit et les deux côtés.</p>
    </div>

  </div>
</div>


      <Ffr/>

    </div>
  )
}

export default Sfr