import React, { Component } from 'react';
import './another.css'
import Fes from '../fOOTER/Fes';
import ESHeader from '../Header/ESHeader';

 
class Des extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
        <div>
        <ESHeader/>
        <div id='tmaintone'>
          <div id='titlestone'>
            <h1>Generador de Tonos Online</h1>
            <p>Por favor, elija la frecuencia deseada y haga clic en el botón de reproducción para comenzar el proceso de generación de tonos. Esta herramienta es gratuita, pruébela y experimente con sus auriculares o altavoces. El generador de tonos ofrece cuatro formas de onda diferentes: Seno, Cuadrada, Sierra y Triangular. Puede seleccionar la forma de onda deseada haciendo clic en los botones correspondientes.</p>
          </div>
        </div>

        <div id='mainarea'>
  <div id='tonearea'>
    <div className='frequency'>
      <label className='labels'>Frecuencia</label>
      <div id='insidefreq'>
        <div id='frdiv'>
          <input
            id='frange'
            type="range"
            min="20"
            max="2000"
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}
          />
        </div>
        <div id='hzz'>
          <div id='fnumbers'>{this.state.frequency}</div>
        </div>
        <div id='hz'>Hz</div>
      </div>
    </div>

    <div className='playbuttons'>
      <div className='buttons'>
        <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
          Reproducir
        </button>
        <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
          Detener
        </button>
      </div>
    </div>

    <div className='waveform'>
      <label>Forma de Onda</label>
      <div className='waveforms'>
        <label>
          <input
            type="radio"
            value="sine"
            checked={this.state.waveform === 'sine'}
            onChange={this.handleWaveformChange}
          />
          Seno
        </label>
        <label>
          <input
            type="radio"
            value="square"
            checked={this.state.waveform === 'square'}
            onChange={this.handleWaveformChange}
          />
          Cuadrada
        </label>
        <label>
          <input
            type="radio"
            value="triangle"
            checked={this.state.waveform === 'triangle'}
            onChange={this.handleWaveformChange}
          />
          Triangular
        </label>
        <label>
          <input
            type="radio"
            value="sawtooth"
            checked={this.state.waveform === 'sawtooth'}
            onChange={this.handleWaveformChange}
          />
          Sierra
        </label>
      </div>
    </div>

    <div className='volume'>
      <label className='labels'>Volumen</label>
      <div id='insidevol'>
        <div id='voldiv'>
          <input
            id='vrange'
            type="range"
            min="0"
            max="15"
            step="1"
            value={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        </div>
        <div id='volumenumber'>
          {this.state.volume}
        </div>  
      </div>
    </div>

    <div className='balance'>
      <div id='bal'>
        <label>Balance</label>
        <div id='balancediv'>
          <div>Izq</div>
          <input
            id='brange'
            type="range"
            min="-100"
            max="100"
            value={this.state.balance}
            onChange={this.handleBalanceChange}
          />
          <div>Der</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='headi'>
  <div id='hhdiv'>
    <h5>Recuerde mantener bajos los niveles de volumen de sus auriculares o altavoces para evitar dañar sus oídos o equipo. Algunos equipos de audio pueden no manejar bien frecuencias extremas. Tenga cuidado con las capacidades de sus altavoces o auriculares al generar tonos, especialmente en frecuencias altas o bajas, para evitar posibles daños.</h5>
  </div>
</div>

<div id='hdiv'>
  <div id='howto'>
    <h3>Cómo Usar el Generador de Tonos</h3>
    <ol>
      <li><strong>Selección de Frecuencia:</strong> Comience ajustando la frecuencia deseada utilizando el control deslizante de rango de frecuencia.</li>
      <li><strong>Selección de Forma de Onda:</strong> Elija la forma de onda adecuada de entre las opciones disponibles en la sección de forma de onda.</li>
      <li><strong>Ajuste del Volumen:</strong> Se recomienda ajustar inicialmente el volumen a un nivel bajo antes de generar tonos.</li>
      <li><strong>Reproducción de Tonos:</strong> Haga clic en el botón Reproducir para comenzar la generación de tonos. Simplemente haga clic en el botón Detener para detener la generación de tonos.</li>
      <li><strong>Ajuste del Balance de Canal:</strong> Utilice el control de rango de balance para ajustar finamente el equilibrio entre los canales izquierdo y derecho.</li>
    </ol>
  </div>
</div>


      
<div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>¿Qué es un generador de tonos?</h4>
      <p>Un generador de tonos es una herramienta que produce tonos de sonido en diferentes frecuencias. Se utiliza comúnmente para probar equipos de audio, crear música o como una herramienta terapéutica.</p>
    </div>

    <div>
      <h4>¿Cómo funciona un generador de tonos?</h4>
      <p>Una aplicación de generador de tonos genera tonos de sonido produciendo ondas de sonido a frecuencias específicas. Los usuarios pueden ajustar la frecuencia, amplitud y forma de onda de los tonos generados.</p>
    </div>

    <div>
      <h4>¿Para qué puedo usar un generador de tonos?</h4>
      <p>Un generador de tonos puede utilizarse para diversos fines, como probar equipos de audio, ajustar instrumentos musicales, realizar experimentos de ingeniería de sonido e incluso para relajación.</p>
    </div>

    <div>
      <h4>¿Puedo usar un generador de tonos para ajustar instrumentos musicales?</h4>
      <p>Sí, puede usar un generador de tonos reproduciendo tonos con frecuencias específicas para ajustar instrumentos musicales.</p>
    </div>

    <div>
      <h4>¿Esta herramienta es compatible con dispositivos iOS?</h4>
      <p>Definitivamente. Esta herramienta es compatible con todos los dispositivos. Simplemente abra su navegador y vaya a MicTestAudio.com para acceder al Generador de Tonos en línea. Nuestro sitio web es accesible en varios navegadores, incluidos Chrome, Safari, Edge y Firefox.</p>
    </div>

    <div>
      <h4>¿Qué tipos de formas de onda admite el generador de tonos?</h4>
      <p>El generador de tonos generalmente admite varias formas de onda, incluidas sinusoidal, cuadrada, triangular y de sierra. Cada forma de onda produce una característica de sonido diferente.</p>
    </div>

    <div>
      <h4>¿Qué tan precisas son las frecuencias generadas por el generador de tonos?</h4>
      <p>Las frecuencias generadas por el generador de tonos son generalmente precisas dentro de un cierto nivel de tolerancia, dependiendo de la sensibilidad del algoritmo de generación de sonido y las capacidades del hardware del dispositivo.</p>
    </div>

    <div>
      <h4>¿La aplicación de generador de tonos es gratuita?</h4>
      <p>Muchas aplicaciones de generador de tonos ofrecen funcionalidad básica de forma gratuita, mientras que características avanzadas suelen estar disponibles a través de compras dentro de la aplicación o versiones premium.</p>
    </div>

    <div>
      <h4>¿La aplicación de generador de tonos es compatible con todos los dispositivos y navegadores?</h4>
      <p>La compatibilidad puede variar según el dispositivo y el navegador. Se recomienda verificar los requisitos del sistema de la aplicación para obtener un rendimiento óptimo.</p>
    </div>
  </div>
</div>


      <Fes/>
      </div>
    );
  }
}

export default Des;
