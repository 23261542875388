import React from 'react'
import './privacy.css'
import {Helmet} from 'react-helmet'
import Footer from '../fOOTER/Footer';
import Header from '../Header/Header';

export default function Privacy() {
  return (
    <div>
      <Header/>
      <Helmet>
      <meta charSet="utf-8" />
      <title>Privacy Policy</title>
      </Helmet>
      <div id='mainprivacy'>
        <div id='titlesprivacy'>
        <h1>Privacy Policy</h1>
<p>Last updated: February 17, 2024</p>
<h2>No User Data Stored</h2>
<p>We want to assure you that we do not store any user data on our servers. Your privacy and security are of utmost importance to us. There is no requirement to create account or fill any kind of data to use our service.</p>
<h2>Embedded Content Disclaimer</h2>
<p>Our website may contain embedded content from other websites, which is provided solely for informational and guidance purposes. It's important to note that these external websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with the embedded content. This includes tracking your interaction with the embedded content if you have an account and are logged in to that particular website.

We encourage you to review the privacy policies of these external websites to understand how they collect, use, and protect your data. While we strive to provide accurate and helpful information, we cannot control the privacy practices of these third-party websites.</p>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>

<p>By email: sevensitexd@gmail.com</p>

</div>
</div>

<Footer/>
    </div>
  )
}
