import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { Helmet } from 'react-helmet';
import Gheader from '../Header/Gheader';
import Fde from '../fOOTER/Fde';

const Mde = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(<div id='failedaccess'><h6>Zugriff auf das Mikrofon fehlgeschlagen.</h6><h6>Bitte überprüfen Sie Ihre Browsereinstellungen und aktivieren Sie den Mikrofonzugriff.</h6><IoIosWarning id='sicon'/></div>);
      }
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch(error){
        setError(<div id='failedaccess'><h6>Zugriff auf das Mikrofon fehlgeschlagen.</h6><h6>Bitte überprüfen Sie Ihre Browsereinstellungen und aktivieren Sie den Mikrofonzugriff.</h6><IoIosWarning id='sicon'/></div>);
      }
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <Gheader/>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Mikrofontest Online</title>
                <meta name='description' content='Einfacher Weg, um zu überprüfen, ob Ihr Mikrofon funktioniert oder nicht. Klicken Sie einfach auf das Mikrofonsymbol, um zu überprüfen, ob Ihr Mikrofon einwandfrei funktioniert. Um es zu verwenden, klicken Sie einfach auf das Mikrofonsymbol.'/>
                <meta name='keywords' content='Mikrofontest-App, mein Mikrofon testen, Hallo Mikrofontest, Mikrofontest online, Google Mikrofontest'/>

            </Helmet>

      <div className='headingsarea'>
        <div id='titlesmic'>
          <h1><span><IoMicOutline className='icons'/></span>Mikrofon-Soundtest</h1>
          <h2>Testen Sie das Mikrofon Ihres Geräts, nehmen Sie Audio auf und laden Sie es herunter</h2>
          </div>

      </div>
      <div className='micarea'>
        <div className='mictester'>
          <div id='left'>
            <h3>Befolgen Sie diese 3 Schritte, um Ihr Mikrofon zu testen:</h3>
            <ul id='ulleft'>
              <li><strong>Schritt 1: </strong>Klick auf das<strong> „Mikrofonsymbol“ </strong>um den Test zu starten <strong> und erlaube die Erlaubnis. </strong></li>
              <li><strong>Schritt 2:</strong> Sagen Sie etwas in Ihr Mikrofon, wie zum Beispiel <strong>Hallo</strong>, um zu überprüfen, ob es funktioniert.</li>
            <li><strong>Schritt 3:</strong> Wenn Sie <strong>Geräuschewellen</strong> sehen, dann funktioniert Ihr Mikrofon einwandfrei.</li>

            </ul>

          </div>
          <div id='right'>
            <div className='rightone'>
            {error && <div id='errorpara'>{error}</div>}
            {!error && (
              <>
              
              {layout===1 && (
                 <div id='firststep'>
                 <h6>Klicken Sie auf „Mikrofon“, um den Mikrofontest zu starten</h6>
                 
                 <button onClick={startRecording}><FaMicrophone id='micicon' title='Start Recording'/></button>
               </div>
              )}

            {layout === 2 && (
              
            <div id='secondstep'>
              <div id='xdiv'>
              <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
              </div>
            
            <button onClick={stopRecording}><FaSquare id='stopicon' title='Stop Recording'/></button>
              </div>
             )}

            {layout === 3 && (
              <div>
               {audioUrl && (
              <div id='thirdstep'>

                <div id='urldiv'>
              <audio controls src={audioUrl}></audio>

              </div>

              <div id='bdiv'>
              <button onClick={refreshPage}>Versuchen Sie es erneut</button>
              <button onClick={downloadAudio}>Audio herunterladen</button>
              </div>
            </div>
          )}
        </div>
      )}
              </>
            )}
              
          
            </div>
          
          </div>
          
        </div>

      </div>
      
      <div className='faqmic'>
        <div id='fnqmic'>
        <div>
  <h4>Wie teste ich mein Mikrofon mit dieser App?</h4>
  <p>Um Ihr Mikrofon zu testen, navigieren Sie einfach zum Hauptfunktionsbereich der App und klicken Sie auf die dafür vorgesehene Schaltfläche "Mikrofon testen". Befolgen Sie alle Bildschirmanweisungen, die von der App bereitgestellt werden, um den Test abzuschließen.</p>
</div>

<div>
  <h4>Was soll ich tun, wenn mein Mikrofon während des Tests nicht funktioniert?</h4>
  <p>Wenn Ihr Mikrofon während des Tests nicht funktioniert, stellen Sie zunächst sicher, dass es ordnungsgemäß mit Ihrem Gerät verbunden ist und dass der App die erforderlichen Berechtigungen zum Zugriff auf Ihr Mikrofon erteilt wurden. Wenn das Problem weiterhin besteht, versuchen Sie, die Seite neu zu laden oder Ihr Gerät neu zu starten.</p>
</div>

<div>
  <h4>Kann ich die Mikrofoneinstellungen innerhalb der App anpassen?</h4>
  <p>Derzeit bietet die App möglicherweise keine direkten Einstellungen für die Mikrofoneinstellung. Sie können jedoch normalerweise die Mikrofoneinstellungen über die Systemeinstellungen oder das Einstellungsmenü Ihres Geräts anpassen. Lesen Sie die Dokumentation Ihres Geräts für spezifische Anweisungen zur Anpassung der Mikrofoneinstellungen.</p>
</div>

<div>
  <h4>Welche Browser werden für den Mikrofontest unterstützt?</h4>
  <p>Unsere App unterstützt eine Vielzahl moderner Webbrowser, darunter Google Chrome, Mozilla Firefox, Safari und Microsoft Edge. Stellen Sie sicher, dass Sie die neueste Version Ihres bevorzugten Browsers verwenden, um eine optimale Leistung zu erzielen.</p>
</div>

<div>
  <h4>Werden meine Mikrofondaten während des Tests gespeichert oder aufgezeichnet?</h4>
  <p>Nein, die App speichert oder zeichnet keine Daten aus Ihren Mikrofontests auf. Ihre Privatsphäre und Sicherheit sind uns wichtig, und wir speichern keine Audioaufnahmen oder persönlichen Informationen, die während des Tests gesammelt werden.</p>
</div>

<div>
  <h4>Ich höre während des Mikrofontests Störungen oder Hintergrundgeräusche. Was soll ich tun?</h4>
  <p>Störungen oder Hintergrundgeräusche während des Mikrofontests können durch verschiedene Faktoren wie Umwelteinflüsse oder Hardwareprobleme des Mikrofons verursacht werden. Versuchen Sie, Ihr Mikrofon in einer ruhigeren Umgebung zu testen und stellen Sie sicher, dass es ordnungsgemäß positioniert ist. Wenn das Problem weiterhin besteht, erwägen Sie die Verwendung eines anderen Mikrofons oder wenden Sie sich an den technischen Support um Hilfe.</p>
</div>

<div>
  <h4>Ist dieses Tool mit iOS-Geräten kompatibel?</h4>
  <p>Absolut. Dieses Tool ist mit allen Geräten kompatibel. Öffnen Sie einfach Ihren Browser und navigieren Sie zu MicTestAudio.com, um den Mikrofontest durchzuführen. Unsere Website ist auf verschiedenen Browsern wie Chrome, Safari, Edge und Firefox zugänglich.</p>
</div>

<div>
  <h4>Wie genau sind die Mikrofontestergebnisse, die von der App bereitgestellt werden?</h4>
  <p>Die Mikrofontestergebnisse, die von der App bereitgestellt werden, sind im Allgemeinen genau, um die grundlegende Mikrofonfunktionalität zu bewerten. Bitte beachten Sie jedoch, dass die Genauigkeit des Tests je nach Faktoren wie Gerätehardware, Softwarekompatibilität und Umgebungsbedingungen variieren kann.</p>
</div>

<div>
  <h4>Ich habe den Mikrofontest abgeschlossen, aber ich habe immer noch Probleme mit Audioein- und -ausgabe. Was soll ich tun?</h4>
  <p>Wenn Sie den Mikrofontest abgeschlossen haben, aber immer noch Probleme mit der Audioein- oder -ausgabe haben, versuchen Sie, die Audioeinstellungen Ihres Geräts zu überprüfen oder wenden Sie sich an den technischen Support für weitere Unterstützung. Möglicherweise liegt das Problem an Softwarekonflikten, Treiberupdates oder Hardwarefehlern.</p>
</div>


        </div>
      </div>
      <Fde/> 
    </div>
  )
}

export default Mde