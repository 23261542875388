import React from 'react'

export const left = require("./assets/left.mp3")
 export  const right = require("./assets/right.mp3")
 export  const both = require("./assets/both.mp3")

const Audios = () => {
  return (
    <>
    <audio  id='left' src={left} autoPlay  />
    <audio id='right' src={right} autoPlay  />
    <audio id='both' src={both} autoPlay  />
    </>
  )
}

export default Audios