import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Fro from '../fOOTER/Fro';
import { Helmet } from 'react-helmet';
import Rheader from '../Header/Rheader';

const Mro = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>Accesul la microfon a eșuat.</h6>
            <h6>Vă rugăm să verificați setările browser-ului și să activați accesul la microfon.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>Accesul la microfon a eșuat.</h6>
            <h6>Vă rugăm să verificați setările browser-ului și să activați accesul la microfon.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <Rheader/>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Test de Microfon Online</title>
  <meta name='description' content='Modalitate ușoară de a testa dacă microfonul tău funcționează sau nu. Doar dă clic pe iconița microfonului pentru a verifica dacă microfonul tău funcționează perfect. Pentru a-l utiliza, dă pur și simplu clic pe iconița microfonului.'/>
  <meta name='keywords' content='aplicație test de microfon, testați microfonul meu, salut test de microfon, test de microfon online, test de microfon Google'/>
</Helmet>

<div className='headingsarea'>
  <div id='titlesmic'>
    <h1><span><IoMicOutline className='icons'/></span>Test de Sunet Microfon</h1>
    <h2>Testează Microfonul Dispozitivului Tău, Înregistrează Audio și Descarcă</h2>
  </div>
</div>
<div className='micarea'>
  <div className='mictester'>
    <div id='left'>
      <h3>Urmărește acești 3 pași pentru a-ți testa microfonul:</h3>
      <ul id='ulleft'>
        <li><strong>Pasul 1:</strong> Dă clic pe <strong>"iconița microfonului"</strong> pentru a începe testul <strong>și permiteți permisiunea.</strong></li>
        <li><strong>Pasul 2:</strong> Spune ceva în microfonul tău, cum ar fi <strong>"Salut"</strong>, pentru a verifica dacă funcționează.</li>
        <li><strong>Pasul 3:</strong> Dacă vezi <strong>unde sonore,</strong> atunci microfonul tău funcționează perfect.</li>
      </ul>
    </div>
    <div id='right'>
      <div className='rightone'>
        {error && <div id='errorpara'>{error}</div>}
        {!error && (
          <>
            {layout === 1 && (
              <div id='firststep'>
                <h6>Dă Clic Pe Microfon Pentru a Începe Testul Microfonului</h6>
                <button onClick={startRecording}><FaMicrophone id='micicon' title='Începe Înregistrarea'/></button>
              </div>
            )}
            {layout === 2 && (
              <div id='secondstep'>
                <div id='xdiv'>
                  <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                </div>
                <button onClick={stopRecording}><FaSquare id='stopicon' title='Opriți Înregistrarea'/></button>
              </div>
            )}
            {layout === 3 && (
              <div>
                {audioUrl && (
                  <div id='thirdstep'>
                    <div id='urldiv'>
                      <audio controls src={audioUrl}></audio>
                    </div>
                    <div id='bdiv'>
                      <button onClick={refreshPage}>Încercați Din Nou</button>
                      <button onClick={downloadAudio}>Descărcați Audio</button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  </div>
</div>

      
<div className='faqmic'>
  <div id='fnqmic'>
    <div>
      <h4>Cum pot să îmi testezi microfonul folosind această aplicație?</h4>
      <p>Pentru a-ți testa microfonul, navighează pur și simplu către zona principală de funcționalitate a aplicației și dă clic pe butonul desemnat Testare Microfon. Urmează orice instrucțiuni afișate pe ecran oferite de aplicație pentru a finaliza testul.</p>
    </div>

    <div>
      <h4>Ce ar trebui să fac dacă microfonul meu nu funcționează în timpul testului?</h4>
      <p>Dacă microfonul tău nu funcționează în timpul testului, asigură-te mai întâi că este conectat corect la dispozitivul tău și că aplicației i-au fost acordate permisiunile necesare pentru accesul la microfonul tău. Dacă problema persistă, încearcă să reîmprospătezi pagina sau să repornești dispozitivul tău.</p>
    </div>

    <div>
      <h4>Pot să ajustez setările microfonului în cadrul aplicației?</h4>
      <p>În prezent, aplicația poate să nu ofere setări directe pentru ajustarea microfonului. Cu toate acestea, poți ajusta de obicei setările microfonului prin preferințele sau meniul de setări al sistemului dispozitivului tău. Consultă documentația dispozitivului tău pentru instrucțiuni specifice privind ajustarea setărilor microfonului.</p>
    </div>

    <div>
      <h4>Ce browsere sunt suportate pentru testarea microfonului?</h4>
      <p>Aplicația noastră suportă o gamă largă de browsere web moderne, inclusiv Google Chrome, Mozilla Firefox, Safari și Microsoft Edge. Asigură-te că folosești versiunea cea mai recentă a browserului preferat pentru performanță optimă.</p>
    </div>

    <div>
      <h4>Datele de la microfon sunt stocate sau înregistrate în timpul testului?</h4>
      <p>Nu, aplicația nu stochează sau înregistrează nicio dată din testele microfonului tău. Confidențialitatea și securitatea ta sunt importante pentru noi, iar noi nu reținem nicio înregistrare audio sau informație personală colectată în timpul testului.</p>
    </div>

    <div>
      <h4>Aud un zgomot static sau de fundal în timpul testului de microfon. Ce ar trebui să fac?</h4>
      <p>Zgomotul static sau de fundal în timpul testului de microfon poate fi cauzat de diferiți factori, cum ar fi interferența ambientală sau problemele hardware ale microfonului. Încearcă să îți testezi microfonul într-un mediu mai liniștit și asigură-te că este poziționat corect. Dacă problema persistă, ia în considerare utilizarea unui alt microfon sau contactarea suportului tehnic pentru asistență.</p>
    </div>

    <div>
      <h4>Acest instrument este compatibil cu dispozitivele iOS?</h4>
      <p>În mod absolut. Acest instrument este compatibil cu toate dispozitivele. Pur și simplu deschide-ți browser-ul și navighează la MicTestAudio.com pentru a accesa testul de microfon. Site-ul nostru este accesibil pe diferite browsere, inclusiv Chrome, Safari, Edge și Firefox.</p>
    </div>

    <div>
      <h4>Cât de precise sunt rezultatele testului de microfon furnizate de aplicație?</h4>
      <p>Rezultatele testului de microfon furnizate de aplicație sunt în general precise pentru evaluarea funcționalității de bază a microfonului. Cu toate acestea, te rugăm să ții cont că precizia testului poate varia în funcție de factori precum hardware-ul dispozitivului, compatibilitatea software și condițiile ambientale.</p>
    </div>

    <div>
      <h4>Am finalizat testul de microfon, dar tot întâmpin probleme cu intrarea/ieșirea audio. Ce ar trebui să fac?</h4>
      <p>Dacă ai finalizat testul de microfon, dar tot întâmpini probleme cu intrarea sau ieșirea audio, ia în considerare remedierea setărilor audio ale dispozitivului sau consultarea suportului tehnic pentru asistență suplimentară. Este posibil ca problema să fie legată de conflicte de software, actualizări de drivere sau defecțiuni hardware.</p>
    </div>
  </div>
</div>

      <Fro/> 
    </div>
  )
}

export default Mro