import React from 'react';
import './about.css';
import Rheader from '../Header/Rheader';
import Fro from '../fOOTER/Fro';

export default function Aro() {
  return (
    <div>
      <Rheader/>
      <div className='mainabout'>
      <div className='titlesabout'>
  <h1>Despre</h1>
  <p>Bun venit pe MicTestAudio.com! Sunt Pardeep și sunt încântat să vă prezint această platformă, o descoperire în lumea programării.</p>
  <p>Ideea MicTestAudio.com a pornit de la o provocare personală pe care am întâmpinat-o când unul dintre difuzoarele căștilor mele nu funcționa. Hotărât să depășesc această piedică, am decis să dezvolt un instrument simplu, dar eficient, pentru testarea sunetului pe canalele stâng și drept. Pe măsură ce mi-au crescut abilitățile de programare, am văzut oportunitatea de a transforma acest instrument într-o resursă cuprinzătoare pentru testarea și depanarea sunetului.</p>
  <p>MicTestAudio.com a evoluat în timp, includând instrumente și caracteristici suplimentare pentru a satisface diversele nevoi de testare a sunetului. Cu toate acestea, călătoria noastră nu s-a încheiat încă. Sunt hotărât să îmbunătățesc această platformă adăugând mai multe instrumente și caracteristici pentru a vă servi mai bine.</p>
  <p>Părerea și sugestiile dumneavoastră sunt extrem de valoroase pentru noi. Dacă aveți orice idee sau sugestie cu privire la modul în care putem îmbunătăți MicTestAudio.com, vă rugăm să nu ezitați să ne contactați la adresa sevensitexd@gmail.com. Opiniile dvs. ne oferă oportunitatea de a evolua și de a răspunde mai bine nevoilor dvs. de testare a sunetului.</p>
  <p>Vă mulțumim încă o dată că ați vizitat MicTestAudio.com. Suntem recunoscători pentru sprijinul dumneavoastră și anticipăm cu nerăbdare să vă servim mai bine în viitor.</p>
</div>

      </div>
      <Fro/>
    </div>
  );
}
