import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Gheader from '../Header/Gheader';
import Fde from '../fOOTER/Fde';


const Sde = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
  return (
    <div>
      <Gheader/>
      <div id='titlescontainersound'>
  <div id='titlessound'>
    <h1><strong>Online-Soundtest-Tool</strong></h1>
    <h2>Überprüfen Sie Kopfhörer oder Lautsprecher links/rechts mit einem einzigen Klick</h2>
  </div>
</div>
<div id='buttonscontainer'>
  <div id='buttons'>
    <button title='Klicken Sie für die linke Überprüfung' onClick={leftplay}>
      <HiPlay id='ilclass' />
      <span>Linker Lautsprecher</span>
    </button>
    
    <button title='Klicken Sie für die Überprüfung beider Lautsprecher' onClick={bothplay}>
      <BsSpeaker id='iclass'/>
      <span>Beide</span>
    </button>

    <button title='Klicken Sie für die rechte Überprüfung' onClick={rightplay}>
      <HiPlay id='iclass' />
      <span>Rechter Lautsprecher</span>
    </button>
  </div>
</div>
<div id='hw'>
  <div className='howto'>
    <h3>So verwenden Sie das Audio-Test-Tool</h3>
    <ol>
      <li>Klicken Sie auf die Schaltfläche <strong>Linker Lautsprecher</strong>, um zu überprüfen, ob der linke Lautsprecher Ihres Geräts funktioniert.</li>
      <li>Klicken Sie auf <strong>Beide</strong>, um zu überprüfen, ob der rechte Lautsprecher Ihres Geräts funktioniert.</li>
      <li>Drücken Sie die Schaltfläche <strong>Rechter Lautsprecher</strong> in der Mitte, um beide Lautsprecher gleichzeitig zu testen.</li>
    </ol>
  </div>
</div>

      <div className='faqsound'>
        <div id='fnqsound'>
          <div>
            <h4>Was ist der Zweck eines Links-Rechts-Sprecherchecks?</h4>
            <p>Eine Links-Rechts-Lautsprecherprüfung stellt sicher, dass sowohl der linke als auch der rechte Kanal Ihrer Kopfhörer oder Lautsprecher ordnungsgemäß funktionieren. Es trägt dazu bei, eine ausgewogene Audioausgabe für ein optimales Hörerlebnis bei verschiedenen Medientypen aufrechtzuerhalten.</p>
          </div>

          <div>
            <h4>Woher weiß ich, ob meine Lautsprecher eine Links-Rechts-Prüfung benötigen?</h4>
            <p>Wenn Sie Ungleichgewichte im Ton bemerken, beispielsweise wenn eine Seite lauter oder leiser ist als die andere, oder wenn Sie Verzerrungen oder mangelnde Klarheit bei der Audiowiedergabe feststellen, ist es eine gute Idee, eine Überprüfung des linken und rechten Lautsprechers durchzuführen.</p>
          </div>

          <div>
            <h4>Kann eine Links-Rechts-Lautsprecherprüfung sowohl bei Kopfhörern als auch bei externen Lautsprechern durchgeführt werden?</h4>
            <p>Ja, mit unserem Tool können Sie die Überprüfung der linken und rechten Lautsprecher sowohl an Kopfhörern als auch an externen Lautsprechern durchführen. Unabhängig davon, ob Sie kabelgebundene oder kabellose Geräte verwenden, bietet unser Tool eine praktische Lösung zur Überprüfung der Audiobalance und -funktionalität.</p>
          </div>

          <div>
            <h4>Ist dieses Tool mit iOS-Geräten kompatibel?</h4>
            <p>Absolut. Dieses Tool ist mit allen Geräten kompatibel. Öffnen Sie einfach Ihren Browser und navigieren Sie zu MicTestAudio.com, um auf den Klangtest zuzugreifen. Auf unsere Website kann über verschiedene Browser zugegriffen werden, darunter Chrome, Safari, Edge und Firefox.</p>
          </div>

          <div>
            <h4>Was soll ich tun, wenn ich bei der Überprüfung des linken und rechten Lautsprechers Probleme feststelle?</h4>
            <p>Wenn Sie auf Probleme stoßen, wie zum Beispiel, dass ein Kanal keinen Ton erzeugt oder deutliche Unterschiede in der Lautstärke zwischen dem linken und dem rechten Kanal auftreten, müssen Sie möglicherweise eine Fehlerbehebung bei Ihrem Audio-Setup durchführen. Dies kann das Anpassen von Geräteeinstellungen, das Überprüfen von Kabelverbindungen oder bei Bedarf die Suche nach professioneller Hilfe umfassen.</p>
          </div>

          <div>
            <h4>Ist es möglich, einen Links-Rechts-Lautsprecher-Check online durchzuführen?</h4>
            <p>Ja, mit unserem Soundtest-Tool auf der Website können Sie bequem von Ihrem Gerät aus einen Links-Rechts-Lautsprechertest durchführen, ohne dass zusätzliche Software oder Downloads erforderlich sind. Greifen Sie einfach über Ihren Webbrowser auf unsere Website zu und befolgen Sie die Anweisungen, um eine ausgewogene Audioausgabe zu gewährleisten.</p>
          </div>

          <div>
            <h4>Wie kann ich feststellen, ob meine Lautsprecher oder Kopfhörer ordnungsgemäß funktionieren?</h4>
            <p>Die Durchführung eines Klangtests ist eine zuverlässige Möglichkeit, die Funktionalität von Lautsprechern oder Kopfhörern festzustellen. Durch die Audiowiedergabe über verschiedene Kanäle (links und rechts) können Benutzer sicherstellen, dass beide Seiten den Ton gleichmäßig und ohne Verzerrung erzeugen.</p>
          </div>

          <div>
            <h4>Woher weiß ich, ob der Lautsprecher funktioniert oder nicht?</h4>
            <p>Wenn Ihre Kopfhörer oder Lautsprecher eingeschaltet sind, müssen Sie auf die Schaltflächen „Links“, „Rechts“ und „Beide“ klicken, um sie nacheinander zu überprüfen. Wenn Sie auf die linke Schaltfläche klicken und auf der linken Seite keinen Ton hören, bedeutet das, dass der linke Lautsprecher nicht funktioniert. Das Gleiche gilt für die rechte Seite und beide Seiten.</p>
          </div>

        </div>
      </div>
      <Fde/>

    </div>
  )
}

export default Sde