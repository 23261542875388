import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Fnl from '../fOOTER/Fnl';
import NHeader from '../Header/NHeader';

const Hnl = () => {
  return (
    <div>
      <NHeader/>
      <div id='alpha'>
        <div className='titles'>
          <h1><strong>Ses Testi Audio</strong></h1>
          <h2>Ses ayarlarınızı, online hoparlör, kulaklık sesi ve mikrofon testini yapın</h2>
        </div>
      </div>
      <div className='flexdiv'>
        <ul className='toollist'>
          <li>
            <Link id='rlinks' title='Online Ses Testi' to="/nl/sound-test"><LuSpeaker className='icons'/>Ses Testi</Link>
          </li>
          <li>
            <Link id='rlinks' title='Online Mikrofon Testi' to='/nl/mic-test'><IoMicOutline className='icons'/>Mikrofon Testi</Link>
          </li>
          <li>
            <Link id='rlinks' title='Ton Üreteci' to='/nl/tone-generator'><PiWaveSineLight className='icons'/>Ton Üreteci</Link>
          </li>
        </ul>
      </div>
      <div className='fdiv'>
        <div id='features'>
          <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
            <h6>Hoge Prestaties en Efficiëntie</h6>
            <p>Onze tools bieden een snelle en effectieve manier om uw microfoon, koptelefoon en luidsprekers te testen. Deze gratis tools vereisen geen downloads of software-installaties en zijn gemakkelijk te gebruiken. Gebruikers kunnen ze herhaaldelijk gebruiken, zolang ze maar een stabiele internetverbinding hebben.</p>
          </div>
          <div id='indiv'>
            <MdDevices id='hicons'/>
            <h6>Ondersteuning voor Meerdere Apparaten</h6>
            <p>Onze tools ondersteunen verschillende apparaten en bieden platformonafhankelijke compatibiliteit voor verschillende configuraties. Van desktopcomputers tot laptops, tablets tot smartphones, u kunt gemakkelijk toegang krijgen tot en realtime tests uitvoeren op verschillende apparaten.</p>
          </div>
          <div id='indiv'>
            <IoIosLock id='hicons'/>
            <h6>Veiligheid en Privacy</h6>
            <p>Uw veiligheid en privacy zijn belangrijk voor ons. Onze website vereist geen accountregistratie of het delen van persoonlijke gegevens. Uw microfoongeluid wordt nooit opgenomen; het wordt automatisch gewist wanneer u de pagina opnieuw laadt. Deze benadering stelt u in staat om onze functies te verkennen zonder onnodige hindernissen.</p>
          </div>
          <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
            <h6>Functies</h6>
            <p>Onze website biedt veel geweldige functies voor audio-liefhebbers. Gebruikers, of ze nu professioneel zijn, tech-enthousiastelingen of gewoon geïnteresseerd zijn in geluid, kunnen functies verkennen, geluiden genereren, hun microfoons testen en controleren hoe hun apparaten geluid produceren.</p>
          </div>
        </div>
      </div>
      <Fnl/>
    </div>
  )
}

export default Hnl;
