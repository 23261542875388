import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Fes from '../fOOTER/Fes';
import ESHeader from '../Header/ESHeader';

const Hes = () => {
  return (
    <div>
      <ESHeader/>
      <div id='alpha'>
        <div className='titles'>
          <h1><strong>Prueba de Sonido en Línea</strong></h1>
          <h2>Realice ajustes de sonido, pruebas de altavoces en línea, auriculares y micrófono</h2>
        </div>
      </div>
      <div className='flexdiv'>
        <ul className='toollist'>
          <li>
            <Link id='rlinks' title='Prueba de Sonido en Línea' to="/es/sound-test"><LuSpeaker className='icons'/>Prueba de Sonido</Link>
          </li>
          <li>
            <Link id='rlinks' title='Prueba de Micrófono en Línea' to='/es/mic-test'><IoMicOutline className='icons'/>Prueba de Micrófono</Link>
          </li>
          <li>
            <Link id='rlinks' title='Generador de Tonos' to='/es/tone-generator'><PiWaveSineLight className='icons'/>Generador de Tonos</Link>
          </li>
        </ul>
      </div>
      <div className='fdiv'>
        <div id='features'>
          <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
            <h6>Rendimiento y Eficiencia Rápidos</h6>
            <p>Nuestras herramientas ofrecen una forma rápida y efectiva de probar su micrófono, sus auriculares y sus altavoces. Estas herramientas gratuitas no requieren descargas ni instalación de software, y son fáciles de usar. Los usuarios pueden usarlas una y otra vez siempre que tengan una conexión a internet estable.</p>
          </div>
          <div id='indiv'>
            <MdDevices id='hicons'/>
            <h6>Soporte para Múltiples Dispositivos</h6>
            <p>Nuestras herramientas admiten diferentes dispositivos y ofrecen compatibilidad entre plataformas para diferentes configuraciones. Puede acceder fácilmente y realizar pruebas en tiempo real en una variedad de dispositivos, desde computadoras de escritorio y portátiles hasta tabletas y teléfonos inteligentes.</p>
          </div>
          <div id='indiv'>
            <IoIosLock id='hicons'/>
            <h6>Seguridad y Privacidad</h6>
            <p>Su seguridad y privacidad son importantes para nosotros. Nuestro sitio web no requiere registro de cuenta ni intercambio de datos personales. El sonido de su micrófono nunca se graba; se borra automáticamente cuando actualiza la página. Este enfoque le permite explorar nuestras características sin encontrar obstáculos innecesarios.</p>
          </div>
          <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
            <h6>Características</h6>
            <p>Nuestro sitio web ofrece muchas características excelentes para los entusiastas del sonido. Ya sea que sea un profesional del sonido, un entusiasta de la tecnología o simplemente tenga curiosidad por el sonido, puede explorar nuestras características, crear sonidos, probar su micrófono y controlar cómo suenan sus dispositivos.</p>
          </div>
        </div>
      </div>
      <Fes/>
    </div>
  )
}

export default Hes;
