import React from 'react';
import './about.css';
import Pheader from '../Header/Pheader';
import Fpt from '../fOOTER/Fpt';

export default function Apt() {
  return (
    <div>
      <Pheader/>
      <div className='mainabout'>
  <div className='titlesabout'>
  <h1>Sobre</h1>
<p>Saudações e bem-vindo ao MicTestAudio.com! Eu sou Pardeep e estou feliz em apresentar esta plataforma, que surgiu da minha exploração do mundo da programação.</p>
<p>A ideia para o MicTestAudio.com surgiu de um desafio pessoal, quando o alto-falante esquerdo dos meus fones de ouvido parou de funcionar. Determinado a superar esse obstáculo, comecei a desenvolver uma ferramenta simples, mas eficaz, para verificar o áudio dos canais esquerdo e direito. Com o aumento do conhecimento de programação, percebi a oportunidade de transformar essa ferramenta em um recurso abrangente para testes de áudio e solução de problemas.</p>
<p>O MicTestAudio.com evoluiu ao longo do tempo e incorporou ferramentas e recursos adicionais para atender a várias necessidades de teste de áudio. No entanto, nossa jornada está longe de terminar. Estou empenhado em melhorar continuamente esta plataforma, introduzindo mais ferramentas e recursos para melhor atendê-lo.</p>
<p>Seus feedbacks e sugestões são muito importantes para nós. Se você tiver ideias ou recomendações para melhorar o MicTestAudio.com, não hesite em entrar em contato comigo em sevensitexd@gmail.com. Suas contribuições nos ajudam a evoluir e a atender melhor às suas necessidades de teste de áudio.</p>
<p>Obrigado novamente por visitar o MicTestAudio.com. Agradecemos seu apoio e estamos ansiosos para servi-lo ainda melhor no futuro.</p>

  </div>
</div>

      <Fpt/>
    </div>
  );
}
