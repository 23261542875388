import React from 'react';
import './contact.css'
import { Helmet } from 'react-helmet';
import Header from '../Header/Header';

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:sevensitexd@gmail.com";
  };

  return (
    <div id='cs'>
      <Header/>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us</title>
                <meta name='description' content='mictestaudio.com is a free tool to test your headphones, mic. | Contact Us At '/>
      </Helmet>
        <div id='cdiv'>
      <h1>Contact Us</h1>
      <h2>Write Us Any Query At <span id='sp' onClick={handleEmailClick}>sevensitexd@gmail.com</span></h2>
      
      </div>
    </div>
  );
};

export default Contact;
