import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';

const Fnl = () => {
  return (
    <div>
      <footer id='footer'>
        <div id='foodiv'>
          <div id='one'>
            <ul>
              <Link id='rslinks' title='Home' to="/nl/" >Home</Link><span>|</span> 
              <Link id='rslinks' title='Geluidstest' to="/nl/sound-test" >Geluidstest</Link><span>|</span>
              <Link id='rslinks' title='Microfoontest' to="/nl/mic-test" >Microfoontest</Link><span>|</span>
              <Link id='rslinks' title='Toongenerator' to="/nl/tone-generator" >Toongenerator</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='two'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/nl/" >MicTestAudio.com</Link> <span>|</span>
              <Link id='rslinks' title='Over' to="/nl/about" >Over</Link> <span>|</span>
              <Link id='rslinks' title='Privacybeleid' to="/privacy-policy" >Privacybeleid</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact" >Contact</Link>
            </ul>
          </div>

          <div id='three'>
            <ul>
              <Link id='rslinks' title='Home' to="/nl/" >Home</Link><span>|</span> 
              <Link id='rslinks' title='Geluidstest' to="/nl/sound-test" >Geluidstest</Link><span>|</span>
              <Link id='rslinks' title='Microfoontest' to="/nl/mic-test" >Microfoontest</Link>
            </ul>
          </div>

          <div id='four'>
            <ul>
              <Link id='rslinks' title='Toongenerator' to="/nl/tone-generator" >Toongenerator</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>

          <div id='five'>
            <ul>
              <Link id='rslinks' title='Over' to="/nl/about" >Over</Link> <span>|</span>
              <Link id='rslinks' title='Privacybeleid' to="/privacy-policy" >Privacybeleid</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact" >Contact</Link>
            </ul>
          </div>

          <div id='six'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/nl/" >MicTestAudio.com</Link>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Fnl;
