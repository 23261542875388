import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Footer from '../fOOTER/Footer';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header';

const Mic = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(<div id='failedaccess'><h6>Failed to access microphone.</h6><h6>Please check your browser settings and enable microphone access.</h6><IoIosWarning id='sicon'/></div>);
      }
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch(error){
        setError(<div id='failedaccess'><h6>Failed to access microphone.</h6><h6>Please check your browser settings and enable microphone access.</h6><IoIosWarning id='sicon'/></div>);
      }
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <Header/>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Mic Test Online</title>
                <meta name='description' content='Easy way to test your mic is working or not. Just click on the microphone icon to check if your mic is working flawlessly.To use it, simply click on the microphone icon.'/>
                <meta name='keywords' content='mic test app, test my mic, hello mic test, mic test online, google mic test'/>
            </Helmet>
      <div className='headingsarea'>
        <div id='titlesmic'>
          <h1><span><IoMicOutline className='icons'/></span>Mic Sound Test</h1>
          <h2>Test Your Device Mic, Record Audio and Download</h2>
          </div>

      </div>
      <div className='micarea'>
        <div className='mictester'>
          <div id='left'>
            <h3>Follow these 3 steps to test your microphone:</h3>
            <ul id='ulleft'>
              <li><strong>Step 1: </strong>Click on the <strong> "microphone icon" </strong>to start the test <strong> and allow permission.</strong></li>
              <li><strong>Step 2: </strong>Say something in your microphone like <strong>Hello </strong>to check if it works.</li>
              <li><strong>Step 3: </strong> If you see <strong>sound waves,</strong> then your mic is working perfectly.</li>
            </ul>

          </div>
          <div id='right'>
            <div className='rightone'>
            {error && <div id='errorpara'>{error}</div>}
            {!error && (
              <>
              
              {layout===1 && (
                 <div id='firststep'>
                 <h6>Click On Mic To Start Mic Test</h6>
                 
                 <button onClick={startRecording}><FaMicrophone id='micicon' title='Start Recording'/></button>
               </div>
              )}

            {layout === 2 && (
              
            <div id='secondstep'>
              <div id='xdiv'>
              <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
              </div>
            
            <button onClick={stopRecording}><FaSquare id='stopicon' title='Stop Recording'/></button>
              </div>
             )}

            {layout === 3 && (
              <div>
               {audioUrl && (
              <div id='thirdstep'>

                <div id='urldiv'>
              <audio controls src={audioUrl}></audio>

              </div>

              <div id='bdiv'>
              <button onClick={refreshPage}>Try Again</button>
              <button onClick={downloadAudio}>Download Audio</button>
              </div>
            </div>
          )}
        </div>
      )}
              </>
            )}
              
          
            </div>
          
          </div>
          
        </div>

      </div>
      
      <div className='faqmic'>
        <div id='fnqmic'>
          <div>
            <h4>How do I test my microphone using this app?</h4>
            <p>To test your microphone, simply navigate to the main functionality area of the app and click on the designated Test Microphone button. Follow any on-screen instructions provided by the app to complete the test.</p>
          </div>

          <div>
            <h4>What should I do if my microphone is not working during the test?</h4>
            <p>If your microphone is not working during the test, first ensure that it is properly connected to your device and that the necessary permissions are granted to the app to access your microphone. If the issue persists, try refreshing the page or restarting your device.</p>
          </div>

          <div>
            <h4>Can I adjust the microphone settings within the app?</h4>
            <p>Currently, the app may not offer direct settings for microphone adjustment. However, you can usually adjust microphone settings through your device's system preferences or settings menu. Refer to your device's documentation for specific instructions on adjusting microphone settings.</p>
          </div>

          <div>
            <h4>What browsers are supported for microphone testing?</h4>
            <p>Our app supports a wide range of modern web browsers including Google Chrome, Mozilla Firefox, Safari, and Microsoft Edge. Ensure that you are using the latest version of your preferred browser for optimal performance.</p>
          </div>

          <div>
            <h4>Is my microphone data stored or recorded during the test?</h4>
            <p>No, the app does not store or record any data from your microphone tests. Your privacy and security are important to us, and we do not retain any audio recordings or personal information gathered during the test.</p>
          </div>

          <div>
            <h4>I hear static or background noise during the microphone test. What should I do?</h4>
            <p>Static or background noise during the microphone test may be caused by various factors such as environmental interference or microphone hardware issues. Try testing your microphone in a quieter environment and ensure that it is properly positioned. If the issue persists, consider using a different microphone or contacting technical support for assistance.</p>
          </div>

          <div>
            <h4>Is this tool compatible with iOS devices?</h4>
            <p>Absolutely. This tool is compatible with all devices. Simply open your browser and navigate to MicTestAudio.com to access the Mic test. Our website is accessible on various browsers including Chrome, Safari, Edge, and Firefox.</p>
          </div>

          <div>
            <h4>How accurate are the microphone test results provided by the app?</h4>
            <p>The microphone test results provided by the app are generally accurate for assessing basic microphone functionality. However, please note that the accuracy of the test may vary depending on factors such as device hardware, software compatibility, and environmental conditions.</p>
          </div>

          <div>
            <h4>I have completed the microphone test, but I'm still experiencing issues with audio input/output. What should I do?</h4>
            <p>If you have completed the microphone test but are still experiencing issues with audio input or output, consider troubleshooting your device's audio settings or consulting with technical support for further assistance. It's possible that the issue may be related to software conflicts, driver updates, or hardware malfunctions.</p>
          </div>

        </div>
      </div>
      <Footer/> 
    </div>
  )
}

export default Mic