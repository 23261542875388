import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import FHeader from '../Header/FHeader';
import Ffr from '../fOOTER/Ffr';

const Hfr = () => {
    return (
        <div>
          <FHeader/>
          <div id='alpha'>
            <div className='titles'>
              <h1><strong>Test Audio en Ligne</strong></h1>
              <h2>Testez le son de votre configuration en ligne, testez les écouteurs et le microphone</h2>
            </div>
          </div>
          <div className='flexdiv'>
            <ul className='toollist'>
              <li>
                <Link id='rlinks' title='Test Audio en Ligne' to="/fr/sound-test"><LuSpeaker className='icons'/>Test Audio</Link>
              </li>
              <li>
                <Link id='rlinks' title='Test Microphone en Ligne' to='/fr/mic-test'><IoMicOutline className='icons'/>Test Microphone</Link>
              </li>
              <li>
                <Link id='rlinks' title='Générateur de Ton' to='/fr/tone-generator'><PiWaveSineLight className='icons'/>Générateur de Ton</Link>
              </li>
            </ul>
          </div>
          <div className='fdiv'>
            <div id='features'>
              <div id='indiv'>
                <BiSelectMultiple id='hicons'/>
                <h6>Performances Rapides et Efficaces</h6>
                <p>Nos outils offrent une méthode rapide et efficace pour tester votre microphone, vos écouteurs et vos haut-parleurs. Ces utilitaires gratuits éliminent le besoin de téléchargements ou d'installations de logiciels et garantissent une accessibilité pratique. Les utilisateurs peuvent les utiliser de manière répétée tant qu'ils disposent d'une connexion internet stable.</p>
              </div>
              <div id='indiv'>
                <MdDevices id='hicons'/>
                <h6>Compatibilité avec Plusieurs Appareils</h6>
                <p>Nos outils prennent en charge divers appareils et offrent une compatibilité interplateforme pour différentes configurations. Accédez facilement depuis un ordinateur de bureau, un ordinateur portable, une tablette ou un smartphone pour effectuer des tests en temps réel et générer des sons.</p>
              </div>
              <div id='indiv'>
                <IoIosLock id='hicons'/>
                <h6>Sécurité et Confidentialité</h6>
                <p>Votre sécurité et votre confidentialité sont notre priorité absolue. Notre site ne nécessite pas d'inscription ni de partage de données personnelles. Votre audio de microphone ne sera jamais stocké ; il est automatiquement supprimé à chaque fois que vous rechargez la page. Cette approche vous permet de vous concentrer pleinement sur l'exploration de nos fonctionnalités sans rencontrer d'obstacles inutiles.</p>
              </div>
              <div id='indiv'>
                <MdOutlineFeaturedVideo id='hicons'/>
                <h6>Caractéristiques</h6>
                <p>Notre site propose de nombreuses fonctionnalités intéressantes pour les passionnés de l'audio. Les utilisateurs peuvent facilement explorer les fonctionnalités, créer des sons, tester leurs microphones et vérifier le son de leur appareil. Il est facile à utiliser, que vous soyez un professionnel, un passionné de technologie ou simplement curieux du son.</p>
              </div>
            </div>
          </div>
          <Ffr/>
        </div>
      )
      
}

export default Hfr;
