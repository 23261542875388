import React from 'react';
import './about.css';
import Fes from '../fOOTER/Fes';
import ESHeader from '../Header/ESHeader';

export default function Aes() {
  return (
    <div>
      <ESHeader/>
      <div className='mainabout'>
        <div className='titlesabout'>
          <h1>Acerca de</h1>
          <p>¡Saludos y bienvenidos a MicTestAudio.com! Soy Pardeep, y estoy emocionado de presentarte esta plataforma que ha surgido de mi exploración en el mundo de la programación.</p>
          <p>La idea para MicTestAudio.com surgió de un desafío personal que enfrenté cuando el altavoz izquierdo de mis auriculares dejó de funcionar. Determinado a superar este obstáculo, me propuse desarrollar una herramienta sencilla pero eficiente para probar audio de canal izquierdo-derecho. A medida que mis habilidades de programación crecieron, reconocí la oportunidad de expandir esta herramienta en un recurso integral para pruebas y solución de problemas de audio.</p>
          <p>MicTestAudio.com ha evolucionado con el tiempo, incorporando herramientas y características adicionales para satisfacer una variedad de necesidades de prueba de audio. Sin embargo, nuestro viaje está lejos de terminar. Estoy dedicado a mejorar aún más esta plataforma mediante la introducción de más herramientas y características para servirte mejor.</p>
          <p>Tus comentarios y sugerencias significan mucho para nosotros. Si tienes alguna idea o recomendación para mejorar MicTestAudio.com, no dudes en ponerte en contacto conmigo en sevensitexd@gmail.com. Tus aportaciones nos permiten evolucionar y satisfacer mejor tus necesidades de prueba de audio.</p>
          <p>Una vez más, gracias por visitar MicTestAudio.com. Estamos agradecidos por tu apoyo y esperamos poder servirte mejor en el futuro.</p>
        </div>
      </div>
      <Fes/>
    </div>
  );
}
