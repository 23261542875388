import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Pheader from '../Header/Pheader';
import Fpt from '../fOOTER/Fpt';

const Hpt = () => {
  return (
    <div>
      <Pheader/>
      <div id='alpha'>
        <div className='titles'>
          <h1><strong>Teste de Áudio Online</strong></h1>
          <h2>Teste o som do seu setup online, testes de fones de ouvido e microfone</h2>
        </div>
      </div>
      <div className='flexdiv'>
        <ul className='toollist'>
          <li>
            <Link id='rlinks' title='Teste de Som Online' to="/pt/sound-test"><LuSpeaker className='icons'/>Teste de Som</Link>
          </li>
          <li>
            <Link id='rlinks' title='Teste de Microfone Online' to='/pt/mic-test'><IoMicOutline className='icons'/>Teste de Microfone</Link>
          </li>
          <li>
            <Link id='rlinks' title='Gerador de Tom' to='/pt/tone-generator'><PiWaveSineLight className='icons'/>Gerador de Tom</Link>
          </li>
        </ul>
      </div>
      <div className='fdiv'>
        <div id='features'>
          <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
            <h6>Desempenho e Eficiência Rápidos</h6>
            <p>Nossas ferramentas oferecem um método rápido e eficaz para testar seu microfone, fones de ouvido e alto-falantes. Essas utilidades gratuitas eliminam a necessidade de downloads ou instalações de software e garantem acessibilidade conveniente. Os usuários podem usá-los repetidamente, desde que tenham uma conexão de internet estável.</p>
          </div>
          <div id='indiv'>
            <MdDevices id='hicons'/>
            <h6>Compatibilidade com Múltiplos Dispositivos</h6>
            <p>Nossas ferramentas suportam diversos dispositivos e oferecem compatibilidade entre plataformas para diferentes configurações. Acesse facilmente em desktops, laptops, tablets ou smartphones para realizar testes em tempo real e gerar sons.</p>
          </div>
          <div id='indiv'>
            <IoIosLock id='hicons'/>
            <h6>Segurança e Privacidade</h6>
            <p>Sua segurança e privacidade são nossa maior prioridade. Nosso site não requer registro de conta ou compartilhamento de dados pessoais. Seu áudio de microfone nunca será armazenado; ele é automaticamente excluído sempre que você recarrega a página. Esse abordagem permite que você se concentre totalmente em explorar nossos recursos sem encontrar obstáculos desnecessários.</p>
          </div>
          <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
            <h6>Recursos</h6>
            <p>Nosso site oferece muitos recursos legais para entusiastas de áudio. Os usuários podem explorar facilmente funcionalidades, criar sons, testar seus microfones e verificar como seu dispositivo soa. É fácil de usar, seja você um profissional, um aficionado por tecnologia ou apenas curioso sobre som.</p>
          </div>
        </div>
      </div>
      <Fpt/>
    </div>
  )
}

export default Hpt;
