import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Footer from '../fOOTER/Footer';
import Header from '../Header/Header';


const Sound = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
  return (
    <div>
      <Header/>
      
      <div id='titlescontainersound'>
        <div id='titlessound'>
          <h1><strong>Online Sound Test Tool</strong></h1>
          <h2>Check Headphones or Speaker Left Right With Single Click</h2>

        </div>
      </div>
      <div id='buttonscontainer'>
        <div id='buttons'>
          <button title='Click for Left Check' onClick={leftplay}>
            <HiPlay id='ilclass' />
          <span>Left Speaker</span>
          </button>
          
          <button title='Click for Both Speaker Check' onClick={bothplay}>
          <BsSpeaker id='iclass'/>
          <span>Both</span>
          </button>

          <button title='Click for Right Check' onClick={rightplay}>
            <HiPlay id='iclass' />
            <span>Right Speaker</span>
            </button>
        </div>

      </div>
      <div id='hw'>
        <div className='howto'>
          <h3>How To use Audio Test Tool</h3>
          <ol>
            <li>Click the <strong>Left Speaker</strong> button to check if your device's left speaker is working.</li>
            <li>Click the<strong> Both</strong> to check if your device's right speaker is working.</li>
            <li>Press the<strong> Right Speaker</strong> button in the middle to test both speakers at once.</li>
          </ol>
        </div>
      </div>
      <div className='faqsound'>
        <div id='fnqsound'>
          <div>
            <h4>What is the purpose of a left-right speaker check?</h4>
            <p>A left-right speaker check ensures that both the left and right channels of your headphones or speakers are functioning correctly. It helps maintain balanced audio output for an optimal listening experience across various media types.</p>
          </div>

          <div>
            <h4>How do I know if my speakers need a left-right check?</h4>
            <p>If you notice imbalances in sound, such as one side being louder or quieter than the other, or if you experience distortion or lack of clarity in audio playback, it's a good idea to perform a left-right speaker check.</p>
          </div>

          <div>
            <h4>Can a left-right speaker check be done on both headphones and external speakers?</h4>
            <p>Yes, our tool allows you to perform left-right speaker checks on both headphones and external speakers. Whether you're using wired or wireless devices, our tool provides a convenient solution for verifying audio balance and functionality.</p>
          </div>

          <div>
            <h4>Is this tool compatible with iOS devices?</h4>
            <p>Absolutely. This tool is compatible with all devices. Simply open your browser and navigate to MicTestAudio.com to access the sound test. Our website is accessible on various browsers including Chrome, Safari, Edge, and Firefox.</p>
          </div>

          <div>
            <h4>What should I do if I detect issues during the left-right speaker check?</h4>
            <p>If you encounter issues such as one channel not producing sound or noticeable differences in volume between the left and right channels, you may need to troubleshoot your audio setup. This could involve adjusting device settings, checking cable connections, or seeking professional assistance if necessary.</p>
          </div>

          <div>
            <h4>Is it possible to perform a left-right speaker check online?</h4>
            <p>Yes, our website sound test tool enables you to perform a left-right speaker check conveniently from your device, without the need for additional software or downloads. Simply access our website through your web browser and follow the instructions to ensure balanced audio output.</p>
          </div>

          <div>
            <h4>How can I tell if my speakers or headphones are working properly?</h4>
            <p>Performing a sound test is a reliable way to determine the functionality of speakers or headphones. By playing audio through different channels (left and right), users can confirm that both sides produce sound evenly and without distortion.</p>
          </div>

          <div>
            <h4>How do I know if the speaker is working or not?</h4>
            <p>When your headphones or speakers are turned on, you need to click on the buttons labeled left, right, and both to check them one by one. If you click on the left button and don't hear any sound from the left side, it means the left speaker isn't working. The same goes for the right side and both sides.</p>
          </div>

        </div>
      </div>
      <Footer/>

    </div>
  )
}

export default Sound