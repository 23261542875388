import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dts from './Components/DTS/Dts'
import Sound from './Components/SoundTest/Sound'
import Mic from './Components/Mic/Mic'
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Privacy from './Components/Privacy Policy/Privacy';
import NotFound from './Components/Notfound';
import Hde from './Components/Homes/Hde';
import Sde from './Components/SoundTest/Sde';
import Mde from './Components/Mic/Mde';
import Ade from './Components/About/Ade';
import Dde from './Components/DTS/Dde';
import Htr from './Components/Homes/Htr';
import Dtr from './Components/DTS/Dtr';
import Str from './Components/SoundTest/Str';
import Atr from './Components/About/Atr';
import Mtr from './Components/Mic/Mtr';
import Hes from './Components/Homes/Hes';
import Ses from './Components/SoundTest/Ses';
import Mes from './Components/Mic/Mes';
import Des from './Components/DTS/Des';
import Aes from './Components/About/Aes';
import Apt from './Components/About/Apt';
import Hpt from './Components/Homes/Hpt';
import Spt from './Components/SoundTest/Spt';
import Mpt from './Components/Mic/Mpt';
import Dpt from './Components/DTS/Dpt';
import Hro from './Components/Homes/Hro';
import Sro from './Components/SoundTest/Sro';
import Mro from './Components/Mic/Mro';
import Dro from './Components/DTS/Dro';
import Aro from './Components/About/Aro';
import Anl from './Components/About/Anl';
import Snl from './Components/SoundTest/Snl';
import Mnl from './Components/Mic/Mnl';
import Hnl from './Components/Homes/Hnl';
import Dnl from './Components/DTS/Dnl';
import Hfr from './Components/Homes/Hfr';
import Afr from './Components/About/Afr';
import Sfr from './Components/SoundTest/Sfr';
import Dfr from './Components/DTS/Dfr';
import Mfr from './Components/Mic/Mfr';


function App() {
  return (
   <Router>
    

    <Routes>
    <Route path='/' element={<Home/>}  />
    <Route path='/sound-test' element={<Sound/>}/>
    <Route path='/mic-test' element={<Mic/>} />
    <Route path='/tone-generator' element={<Dts/>} />
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/privacy-policy' element={<Privacy/>}/>
    <Route path='*' element={<NotFound/>}/>
    <Route path='/de/' element={<Hde/>}/>
    <Route path='/de/sound-test' element={<Sde/>}/>
    <Route path='/de/mic-test' element={<Mde/>}/>
    <Route path='/de/tone-generator' element={<Dde/>}/>
    <Route path='/de/about' element={<Ade/>}/>
    <Route path='/tr/' element={<Htr/>}/>
    <Route path='/tr/sound-test' element={<Str/>}/>
    <Route path='/tr/mic-test' element={<Mtr/>}/>
    <Route path='/tr/tone-generator' element={<Dtr/>}/>
    <Route path='/tr/about' element={<Atr/>}/>
    <Route path='/es/' element={<Hes/>}/>
    <Route path='/es/sound-test' element={<Ses/>}/>
    <Route path='/es/mic-test' element={<Mes/>}/>
    <Route path='/es/tone-generator' element={<Des/>}/>
    <Route path='/es/about' element={<Aes/>}/>
    <Route path='/pt/' element={<Hpt/>}/>
    <Route path='/pt/sound-test' element={<Spt/>}/>
    <Route path='/pt/mic-test' element={<Mpt/>}/>
    <Route path='/pt/tone-generator' element={<Dpt/>}/>
    <Route path='/pt/about' element={<Apt/>}/>
    <Route path='/ro/' element={<Hro/>}  />
    <Route path='/ro/sound-test' element={<Sro/>}  />
    <Route path='/ro/mic-test' element={<Mro/>}  />
    <Route path='/ro/tone-generator' element={<Dro/>}  />
    <Route path='/ro/about' element={<Aro/>}  />
    <Route path='/nl/about' element={<Anl/>}  />
    <Route path='/nl/sound-test' element={<Snl/>}  />
    <Route path='/nl/mic-test' element={<Mnl/>}  />
    <Route path='/nl/' element={<Hnl/>}  />
    <Route path='/nl/tone-generator' element={<Dnl/>}  />
    <Route path='/fr/' element={<Hfr/>} />
    <Route path='/fr/sound-test' element={<Sfr/>} />
    <Route path='/fr/mic-test' element={<Mfr/>} />
    <Route path='/fr/tone-generator' element={<Dfr/>} />
    <Route path='/fr/about' element={<Afr/>} />

    </Routes>

    
   </Router>
  );
}

export default App;
