import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Fnl from '../fOOTER/Fnl';
import NHeader from '../Header/NHeader';


const Snl = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
  return (
    <div>
      <NHeader/>
      
      <div id='titlescontainersound'>
  <div id='titlessound'>
    <h1><strong>Online Geluidstest Tool</strong></h1>
    <h2>Controleer Hoofdtelefoon of Luidspreker Links Rechts Met Een Enkele Klik</h2>
  </div>
</div>

<div id='buttonscontainer'>
  <div id='buttons'>
    <button title='Klik voor Linker Controle' onClick={leftplay}>
      <HiPlay id='ilclass' />
      <span>Linker Luidspreker</span>
    </button>
    
    <button title='Klik voor Beide Luidspreker Controle' onClick={bothplay}>
      <BsSpeaker id='iclass'/>
      <span>Beide</span>
    </button>

    <button title='Klik voor Rechter Controle' onClick={rightplay}>
      <HiPlay id='iclass' />
      <span>Rechter Luidspreker</span>
    </button>
  </div>
</div>

<div id='hw'>
  <div className='howto'>
    <h3>Hoe de Audio Test Tool te gebruiken</h3>
    <ol>
      <li>Klik op de <strong>Linker Luidspreker</strong> knop om te controleren of de linkerluidspreker van uw apparaat werkt.</li>
      <li>Klik op <strong>Beide</strong> om te controleren of de rechterluidspreker van uw apparaat werkt.</li>
      <li>Druk op de <strong>Rechter Luidspreker</strong> knop in het midden om beide luidsprekers tegelijkertijd te testen.</li>
    </ol>
  </div>
</div>

<div className='faqsound'>
  <div id='fnqsound'>
    <div>
      <h4>Wat is het doel van een links-rechts luidsprekercontrole?</h4>
      <p>Een links-rechts luidsprekercontrole zorgt ervoor dat zowel de linker- als rechterkanalen van uw koptelefoon of luidsprekers correct functioneren. Het helpt bij het behouden van een gebalanceerde audioweergave voor een optimale luisterervaring bij verschillende mediatypen.</p>
    </div>

    <div>
      <h4>Hoe weet ik of mijn luidsprekers een links-rechts controle nodig hebben?</h4>
      <p>Als u onbalansen in geluid opmerkt, zoals één kant die luider of stiller is dan de andere, of als u vervorming of gebrek aan helderheid ervaart bij het afspelen van audio, is het een goed idee om een links-rechts luidsprekercontrole uit te voeren.</p>
    </div>

    <div>
      <h4>Kan een links-rechts luidsprekercontrole worden uitgevoerd op zowel koptelefoons als externe luidsprekers?</h4>
      <p>Ja, onze tool stelt u in staat om links-rechts luidsprekercontroles uit te voeren op zowel koptelefoons als externe luidsprekers. Of u nu bedrade of draadloze apparaten gebruikt, onze tool biedt een handige oplossing voor het verifiëren van audiobalans en functionaliteit.</p>
    </div>

    <div>
      <h4>Is deze tool compatibel met iOS-apparaten?</h4>
      <p>Zeker weten. Deze tool is compatibel met alle apparaten. Open eenvoudig uw browser en ga naar MicTestAudio.com om toegang te krijgen tot de geluidstest. Onze website is toegankelijk op verschillende browsers, waaronder Chrome, Safari, Edge en Firefox.</p>
    </div>

    <div>
      <h4>Wat moet ik doen als ik problemen constateer tijdens de links-rechts luidsprekercontrole?</h4>
      <p>Als u problemen ondervindt, zoals één kanaal dat geen geluid produceert of opvallende verschillen in volume tussen de linker- en rechterkanalen, moet u uw audio-instellingen controleren. Dit kan het aanpassen van apparaatinstellingen, controleren van kabelverbindingen, of het zoeken naar professionele hulp omvatten indien nodig.</p>
    </div>

    <div>
      <h4>Is het mogelijk om online een links-rechts luidsprekercontrole uit te voeren?</h4>
      <p>Ja, onze website-geluidstesttool stelt u in staat om gemakkelijk vanaf uw apparaat een links-rechts luidsprekercontrole uit te voeren, zonder de noodzaak van extra software of downloads. Ga eenvoudig naar onze website via uw webbrowser en volg de instructies om een gebalanceerde audioweergave te garanderen.</p>
    </div>

    <div>
      <h4>Hoe kan ik controleren of mijn luidsprekers of koptelefoon goed werken?</h4>
      <p>Het uitvoeren van een geluidstest is een betrouwbare manier om de functionaliteit van luidsprekers of koptelefoons te bepalen. Door audio af te spelen via verschillende kanalen (links en rechts) kunnen gebruikers bevestigen dat beide zijden geluid produceren zonder vervorming.</p>
    </div>

    <div>
      <h4>Hoe weet ik of de luidspreker werkt of niet?</h4>
      <p>Wanneer uw koptelefoon of luidsprekers zijn ingeschakeld, moet u op de knoppen met de labels links, rechts en beide klikken om ze één voor één te controleren. Als u op de linker knop klikt en geen geluid hoort aan de linkerkant, betekent dit dat de linker luidspreker niet werkt. Hetzelfde geldt voor de rechterkant en beide kanten.</p>
    </div>

  </div>
</div>

      <Fnl/>

    </div>
  )
}

export default Snl