import React, { Component } from 'react';
import './another.css'
import Gheader from '../Header/Gheader';
import Fde from '../fOOTER/Fde';
 
class Dde extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
        <div>
          <Gheader/>
        <div id='tmaintone'>
          <div id='titlestone'>
            <h1>Online-Ton-Generator</h1>
            <p>Bitte wählen Sie Ihre bevorzugte Frequenz und klicken Sie auf die Wiedergabetaste, um den Ton-Generierungsprozess zu starten. Dieses Tool ist kostenlos zu verwenden. Testen Sie Ihre Kopfhörer oder Lautsprecher mit diesem Online-Ton-Generator. Der Ton-Generator bietet vier verschiedene Wellenformen: Sinus, Rechteck, Sägezahn und Dreieck. Sie können die gewünschte Wellenform auswählen, indem Sie auf die entsprechenden Schaltflächen klicken.</p>
          </div>
        </div>
      
        <div id='mainarea'>
          <div id='tonearea'>
            <div className='frequency'>
              <label className='labels'>Frequenz</label>
              <div id='insidefreq'>
                <div id='frdiv'>
                  <input
                    id='frange'
                    type="range"
                    min="20"
                    max="2000"
                    value={this.state.frequency}
                    onChange={this.handleFrequencyChange}
                  />
                </div>
                <div id='hzz'>
                  <div id='fnumbers'>{this.state.frequency}</div>
                </div>
                <div id='hz'>Hz</div>
              </div>
            </div>
      
            <div className='playbuttons'>
              <div className='buttons'>
                <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
                  Abspielen
                </button>
                <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
                  Stoppen
                </button>
              </div>
            </div>
      
            <div className='waveform'>
              <label>Wellenform</label>
              <div className='waveforms'>
                <label>
                  <input
                    type="radio"
                    value="sinus"
                    checked={this.state.waveform === 'sinus'}
                    onChange={this.handleWaveformChange}
                  />
                  Sinus
                </label>
                <label>
                  <input
                    type="radio"
                    value="rechteck"
                    checked={this.state.waveform === 'rechteck'}
                    onChange={this.handleWaveformChange}
                  />
                  Rechteck
                </label>
                <label>
                  <input
                    type="radio"
                    value="dreieck"
                    checked={this.state.waveform === 'dreieck'}
                    onChange={this.handleWaveformChange}
                  />
                  Dreieck
                </label>
                <label>
                  <input
                    type="radio"
                    value="sägezahn"
                    checked={this.state.waveform === 'sägezahn'}
                    onChange={this.handleWaveformChange}
                  />
                  Sägezahn
                </label>
              </div>
            </div>
      
            <div className='volume'>
              <label className='labels'>Lautstärke</label>
              <div id='insidevol'>
                <div id='voldiv'>
                  <input
                    id='vrange'
                    type="range"
                    min="0"
                    max="15"
                    step="1"
                    value={this.state.volume}
                    onChange={this.handleVolumeChange}
                  />
                </div>
                <div id='volumenumber'>
                  {this.state.volume}
                </div>
              </div>
            </div>
      
            <div className='balance'>
              <div id='bal'>
                <label>
                  Balance
                </label>
                <div id='balancediv'>
                  <div>L</div>
                  <input
                    id='brange'
                    type="range"
                    min="-100"
                    max="100"
                    value={this.state.balance}
                    onChange={this.handleBalanceChange}
                  />
                  <div>R</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div id='headi'>
          <div id='hhdiv'>
            <h5>Bitte denken Sie daran, die Lautstärke Ihrer Kopfhörer oder Lautsprecher niedrig zu halten, um Schäden an Ihren Ohren oder der Ausrüstung zu vermeiden. Einige Audiogeräte können extreme Frequenzen möglicherweise nicht gut verarbeiten. Beachten Sie die Fähigkeiten Ihrer Lautsprecher oder Kopfhörer beim Generieren von Tönen, insbesondere bei hohen oder niedrigen Frequenzen, um mögliche Schäden zu vermeiden.</h5>
          </div>
        </div>
      
        <div id='hdiv'>
          <div id='howto'>
            <h3>Wie benutzt man den Ton-Generator</h3>
            <ol>
              <li><strong>Frequenzauswahl:</strong> Beginnen Sie damit, die gewünschte Frequenz mit dem bereitgestellten Frequenzbereichsregler einzustellen.</li>
              <li><strong>Wellenformauswahl:</strong> Wählen Sie die geeignete Wellenform aus der Auswahl im Wellenformabschnitt.</li>
              <li><strong>Lautstärkeeinstellung:</strong> Es wird empfohlen, vor dem Generieren von Tönen die Lautstärke zunächst auf ein niedrigeres Niveau einzustellen.</li>
              <li><strong>Töne abspielen:</strong> Klicken Sie auf die Wiedergabetaste, um die Ton-Erzeugung zu starten. Um die Ton-Erzeugung zu stoppen, klicken Sie einfach auf die Stopptaste.</li>
              <li><strong>Kanalausgleichsanpassung:</strong> Feinabstimmung des Gleichgewichts zwischen den linken und rechten Kanälen mithilfe der Balance-Bereichssteuerung.</li>
            </ol>
          </div>
        </div>
      
      
        <div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>Was ist ein Ton-Generator?</h4>
      <p>Ein Ton-Generator ist ein Werkzeug, das Audio-Töne unterschiedlicher Frequenzen erzeugt. Es wird häufig zum Testen von Audiogeräten, zum Erstellen von Musik oder als therapeutisches Werkzeug verwendet.</p>
    </div>

    <div>
      <h4>Wie funktioniert der Ton-Generator?</h4>
      <p>Die Ton-Generator-App erzeugt Audio-Töne, indem sie spezifische Frequenzen von Schallwellen erzeugt. Benutzer können die Frequenz, Amplitude und Wellenform der erzeugten Töne anpassen.</p>
    </div>

    <div>
      <h4>Wofür kann ich den Ton-Generator verwenden?</h4>
      <p>Der Ton-Generator kann für verschiedene Zwecke verwendet werden, wie z. B. zum Testen von Audiogeräten, zum Stimmen von Musikinstrumenten, zum Durchführen von Experimenten in der Tontechnik und sogar zur Entspannung.</p>
    </div>

    <div>
      <h4>Kann ich den Ton-Generator verwenden, um Musikinstrumente zu stimmen?</h4>
      <p>Ja, Sie können den Ton-Generator verwenden, um Musikinstrumente zu stimmen, indem Sie Töne bestimmter Frequenzen spielen, die verschiedenen Noten entsprechen.</p>
    </div>

    <div>
      <h4>Ist dieses Tool mit iOS-Geräten kompatibel?</h4>
      <p>Absolut. Dieses Tool ist mit allen Geräten kompatibel. Öffnen Sie einfach Ihren Browser und navigieren Sie zu MicTestAudio.com, um auf den Online-Ton-Generator zuzugreifen. Unsere Website ist auf verschiedenen Browsern wie Chrome, Safari, Edge und Firefox zugänglich.</p>
    </div>

    <div>
      <h4>Welche Arten von Wellenformen unterstützt der Ton-Generator?</h4>
      <p>Der Ton-Generator unterstützt typischerweise verschiedene Wellenformen, darunter Sinus-, Rechteck-, Dreieck- und Sägezahnwellen. Jede Wellenform erzeugt eine charakteristische Klangqualität.</p>
    </div>

    <div>
      <h4>Wie genau sind die Frequenzen, die vom Ton-Generator erzeugt werden?</h4>
      <p>Die vom Ton-Generator erzeugten Frequenzen sind im Allgemeinen innerhalb eines bestimmten Toleranzbereichs genau, abhängig von der Genauigkeit des Audioerzeugungsalgorithmus und den Hardwarefähigkeiten des Geräts.</p>
    </div>

    <div>
      <h4>Ist die Ton-Generator-App kostenlos?</h4>
      <p>Viele Ton-Generator-Apps bieten grundlegende Funktionen kostenlos an, während erweiterte Funktionen über In-App-Käufe oder Premium-Versionen verfügbar sein können.</p>
    </div>

    <div>
      <h4>Ist die Ton-Generator-App mit allen Geräten und Browsern kompatibel?</h4>
      <p>Die Kompatibilität kann je nach Gerät und Browser variieren. Es wird empfohlen, die Systemanforderungen der App für optimale Leistung zu überprüfen.</p>
    </div>
  </div>
</div>

      <Fde/>
      </div>
    );
  }
}

export default Dde;
