import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Fes from '../fOOTER/Fes';
import ESHeader from '../Header/ESHeader';


const Ses = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
    return (
        <div>
            <ESHeader/>
    
            <div id='titlescontainersound'>
                <div id='titlessound'>
                    <h1><strong>Herramienta de Prueba de Sonido en Línea</strong></h1>
                    <h2>Control de Sonido Izquierdo y Derecho de Auriculares o Altavoces con un Solo Clic</h2>
                </div>
            </div>
            <div id='buttonscontainer'>
                <div id='buttons'>
                    <button title='Haga clic para el Control Izquierdo' onClick={leftplay}>
                        <HiPlay id='ilclass' />
                        <span>Altavoz Izquierdo</span>
                    </button>
                    <button title='Haga clic para Controlar Ambos' onClick={bothplay}>
                        <BsSpeaker id='iclass'/>
                        <span>Ambos</span>
                    </button>
                    <button title='Haga clic para el Control Derecho' onClick={rightplay}>
                        <HiPlay id='iclass' />
                        <span>Altavoz Derecho</span>
                    </button>
                </div>
            </div>
            <div id='hw'>
                <div className='howto'>
                    <h3>¿Cómo Utilizar la Herramienta de Prueba de Sonido?</h3>
                    <ol>
                        <li>Haga clic en el botón del <strong>Altavoz Izquierdo</strong> para comprobar si funciona correctamente el altavoz izquierdo de su dispositivo.</li>
                        <li>Haga clic en el botón del <strong>Altavoz Derecho</strong> para comprobar si funciona correctamente el altavoz derecho de su dispositivo.</li>
                        <li>Haga clic en el botón <strong>Ambos</strong> para probar ambos altavoces simultáneamente.</li>
                    </ol>
                </div>
            </div>
            <div className='faqsound'>
                <div id='fnqsound'>
                    <div>
                        <h4>¿Cuál es el propósito del control de altavoces izquierdo y derecho?</h4>
                        <p>El control de altavoces izquierdo y derecho asegura que los auriculares o altavoces reproduzcan el sonido de los canales izquierdo y derecho correctamente. Esto mantiene un equilibrio en la salida de sonido para una experiencia auditiva óptima en diferentes entornos.</p>
                    </div>
                    <div>
                        <h4>¿Mis altavoces necesitan control de izquierda y derecha?</h4>
                        <p>Si notas desequilibrios de sonido, como que un lado es más alto o más bajo que el otro, o si experimentas distorsión o falta de claridad durante la reproducción de sonido, es una buena idea realizar el control de altavoces izquierdo y derecho.</p>
                    </div>
                    <div>
                        <h4>¿Se puede realizar el control de altavoces izquierdo y derecho tanto en auriculares como en altavoces externos?</h4>
                        <p>Sí, nuestra herramienta permite realizar controles de altavoces izquierdo y derecho tanto en auriculares como en altavoces externos. Ya sea que utilices dispositivos con cable o inalámbricos, nuestra herramienta ofrece una solución práctica para verificar el equilibrio de sonido y su funcionalidad.</p>
                    </div>
                    <div>
                        <h4>¿Esta herramienta es compatible con dispositivos iOS?</h4>
                        <p>Absolutamente. Esta herramienta es compatible con todos los dispositivos. Simplemente abre tu navegador y visita MicTestAudio.com para acceder a la prueba de sonido. Nuestro sitio web es accesible en varios navegadores, incluyendo Chrome, Safari, Edge y Firefox.</p>
                    </div>
                    <div>
                        <h4>¿Qué debo hacer si encuentro problemas durante el control de altavoces izquierdo y derecho?</h4>
                        <p>Si detectas que un canal no emite sonido o si hay una diferencia de sonido notable entre los canales izquierdo y derecho, es posible que necesites solucionar los ajustes de sonido. Esto puede implicar ajustar la configuración de tu dispositivo, verificar las conexiones de cable o buscar ayuda profesional si es necesario.</p>
                    </div>
                    <div>
                        <h4>¿Se puede realizar el control de altavoces izquierdo y derecho en línea?</h4>
                        <p>Sí, la herramienta de prueba de sonido de nuestro sitio web te permite realizar el control de altavoces izquierdo y derecho desde tu dispositivo sin necesidad de software adicional o descargas. Accede a nuestro sitio web a través de tu navegador y sigue las instrucciones para garantizar un sonido equilibrado.</p>
                    </div>
                    <div>
                        <h4>¿Cómo puedo saber si mis altavoces o auriculares están funcionando correctamente?</h4>
                        <p>Realizar una prueba de sonido es una forma confiable de determinar la funcionalidad de tus altavoces o auriculares. Reproduciendo sonido desde diferentes canales (izquierdo y derecho), los usuarios pueden asegurarse de que ambos lados emitan sonido de manera adecuada y sin distorsiones.</p>
                    </div>
                    <div>
                        <h4>¿Cómo puedo saber si mis altavoces están funcionando?</h4>
                        <p>Cuando enciendes tus auriculares o altavoces, debes hacer clic en los botones etiquetados para controlar el sonido desde el lado izquierdo, derecho y ambos. Si haces clic en el botón izquierdo y no escuchas sonido del lado izquierdo, significa que el altavoz izquierdo no está funcionando. Lo mismo se aplica al lado derecho y ambos.</p>
                    </div>
                </div>
            </div>
    
            <Fes/>
    
        </div>
    )
    
}

export default Ses