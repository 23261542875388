import React, { useEffect } from 'react';
import './sound.css';
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Pheader from '../Header/Pheader';
import Fpt from '../fOOTER/Fpt';

const Spt = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

  const leftplay = () => {
    stopplay();
    audio1.play();
  }

  const rightplay = () => {
    stopplay();
    audio2.play();
  }

  const bothplay = () => {
    stopplay();
    audio3.play();
  }

  function stopplay() {
    audio1.pause();
    audio1.currentTime = 0;
    audio2.pause();
    audio2.currentTime = 0;
    audio3.pause();
    audio3.currentTime = 0;
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        stopplay();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopplay();
    };
  }, []);

  return (
    <div>
      <Pheader/>
      <div id='titlescontainersound'>
        <div id='titlessound'>
          <h1><strong>Ferramenta de Teste de Som Online</strong></h1>
          <h2>Verifique os fones de ouvido ou alto-falantes esquerdo/direito com um único clique</h2>
        </div>
      </div>
      <div id='buttonscontainer'>
        <div id='buttons'>
          <button title='Clique para o teste do alto-falante esquerdo' onClick={leftplay}>
            <HiPlay id='ilclass' />
            <span>Alto-Falante Esquerdo</span>
          </button>
          
          <button title='Clique para o teste de ambos os alto-falantes' onClick={bothplay}>
            <BsSpeaker id='iclass'/>
            <span>Ambos</span>
          </button>

          <button title='Clique para o teste do alto-falante direito' onClick={rightplay}>
            <HiPlay id='iclass' />
            <span>Alto-Falante Direito</span>
          </button>
        </div>
      </div>
      <div id='hw'>
        <div className='howto'>
          <h3>Como usar a Ferramenta de Teste de Áudio</h3>
          <ol>
            <li>Clique no botão <strong>Alto-Falante Esquerdo</strong> para verificar se o alto-falante esquerdo do seu dispositivo está funcionando.</li>
            <li>Clique em <strong>Ambos</strong> para verificar se o alto-falante direito do seu dispositivo está funcionando.</li>
            <li>Pressione o botão <strong>Alto-Falante Direito</strong> no meio para testar ambos os alto-falantes simultaneamente.</li>
          </ol>
        </div>
      </div>

      <div className='faqsound'>
        <div id='fnqsound'>
          <div>
            <h4>Qual é o propósito de um teste de alto-falante esquerdo-direito?</h4>
            <p>Um teste de alto-falante esquerdo-direito garante que tanto o canal esquerdo quanto o direito dos seus fones de ouvido ou alto-falantes estejam funcionando corretamente. Ajuda a manter uma saída de áudio equilibrada para uma experiência auditiva ideal em vários tipos de mídia.</p>
          </div>

          <div>
            <h4>Como sei se meus alto-falantes precisam de um teste esquerdo-direito?</h4>
            <p>Se você notar desequilíbrios no som, como um lado mais alto ou mais baixo que o outro, ou se notar distorções ou falta de clareza na reprodução de áudio, é uma boa ideia fazer um teste de alto-falante esquerdo e direito.</p>
          </div>

          <div>
            <h4>Posso fazer um teste de alto-falante esquerdo-direito tanto em fones de ouvido quanto em alto-falantes externos?</h4>
            <p>Sim, com nossa ferramenta você pode realizar o teste de alto-falante esquerdo e direito tanto em fones de ouvido quanto em alto-falantes externos. Independentemente de você estar usando dispositivos com fio ou sem fio, nossa ferramenta oferece uma solução conveniente para verificar o equilíbrio e a funcionalidade de áudio.</p>
          </div>

          <div>
  <h4>Este aplicativo é compatível com dispositivos iOS?</h4>
  <p>Sim. Este aplicativo é compatível com todos os dispositivos. Basta abrir seu navegador e acessar MicTestAudio.com para fazer o teste de áudio. Nosso site é acessível por vários navegadores, incluindo Chrome, Safari, Edge e Firefox.</p>
</div>

<div>
  <h4>O que devo fazer se encontrar problemas durante a verificação dos alto-falantes esquerdo e direito?</h4>
  <p>Se você encontrar problemas, como um canal sem som ou diferenças significativas de volume entre o canal esquerdo e o direito, talvez seja necessário solucionar problemas com sua configuração de áudio. Isso pode envolver ajustar as configurações do dispositivo, verificar as conexões dos cabos ou, se necessário, buscar ajuda profissional.</p>
</div>

<div>
  <h4>É possível realizar um teste de alto-falante esquerdo-direito online?</h4>
  <p>Sim, com nossa ferramenta de teste de som no site, você pode fazer facilmente um teste de alto-falante esquerdo-direito em seu dispositivo, sem a necessidade de software adicional ou downloads. Basta acessar nosso site pelo seu navegador da web e seguir as instruções para garantir uma saída de áudio equilibrada.</p>
</div>

<div>
  <h4>Como posso verificar se meus alto-falantes ou fones de ouvido estão funcionando corretamente?</h4>
  <p>Fazer um teste de áudio é uma maneira confiável de verificar a funcionalidade dos alto-falantes ou fones de ouvido. Ao reproduzir áudio em diferentes canais (esquerdo e direito), os usuários podem garantir que ambos os lados produzam som de forma uniforme e sem distorções.</p>
</div>

<div>
  <h4>Como saber se o alto-falante está funcionando ou não?</h4>
  <p>Se seus fones de ouvido ou alto-falantes estiverem ligados, você precisará clicar nos botões "Esquerdo", "Direito" e "Ambos" para testá-los sequencialmente. Se você clicar no botão esquerdo e não ouvir som no lado esquerdo, significa que o alto-falante esquerdo não está funcionando. O mesmo se aplica ao lado direito e a ambos os lados.</p>
</div>
</div>
</div>
<Fpt/>

    </div>
  )
}

export default Spt