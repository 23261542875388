import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Ffr from '../fOOTER/Ffr';
import { Helmet } from 'react-helmet';
import FHeader from '../Header/FHeader';

const Mfr = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>Impossible d'accéder au microphone.</h6>
            <h6>Veuillez vérifier les paramètres de votre navigateur et autoriser l'accès au microphone.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
      
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>Impossible d'accéder au microphone.</h6>
            <h6>Veuillez vérifier les paramètres de votre navigateur et autoriser l'accès au microphone.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
      
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <FHeader/>
      <Helmet>
    <meta charSet="utf-8" />
    <title>Test de Microphone en Ligne</title>
    <meta name='description' content='Une manière simple de vérifier si votre microphone fonctionne correctement. Cliquez simplement sur l icône du microphone pour vérifier si votre microphone fonctionne correctement. Pour l utiliser, cliquez simplement sur l icône du microphone.'/>
    <meta name='keywords' content='application de test de microphone, tester mon microphone, bonjour test de microphone, test de microphone en ligne, test de microphone Google'/>
</Helmet>


<div className='headingsarea'>
<div id='titlesmic'>
    <h1><span><IoMicOutline className='icons'/></span>Test de Son du Microphone</h1>
    <h2>Testez votre Microphone, Enregistrez l'Audio et Téléchargez</h2>
</div>

</div>
<div className='micarea'>
    <div className='mictester'>
    <div id='left'>
    <h3>Suivez ces 3 étapes pour tester votre microphone :</h3>
    <ul id='ulleft'>
        <li><strong>Étape 1 :</strong> Cliquez sur l'<strong>"icône du microphone"</strong> pour commencer le test <strong>et autorisez la permission.</strong></li>
        <li><strong>Étape 2 :</strong> Dites quelque chose dans votre microphone comme <strong>Bonjour</strong> pour vérifier s'il fonctionne.</li>
        <li><strong>Étape 3 :</strong> Si vous voyez des <strong>ondes sonores,</strong> alors votre microphone fonctionne parfaitement.</li>
    </ul>
</div>

        <div id='right'>
            <div className='rightone'>
                {error && <div id='errorpara'>{error}</div>}
                {!error && (
                    <>
                        {layout===1 && (
                            <div id='firststep'>
                                <h6>Cliquez sur le micro pour lancer la vérification du micro</h6>
                                <button onClick={startRecording}><FaMicrophone id='micicon' title='Comenzar Grabación'/></button>
                            </div>
                        )}
                        {layout === 2 && (
                            <div id='secondstep'>
                                <div id='xdiv'>
                                    <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                                </div>
                                <button onClick={stopRecording}><FaSquare id='stopicon' title='Detener Grabación'/></button>
                            </div>
                        )}
                        {layout === 3 && (
                            <div>
                                {audioUrl && (
                                    <div id='thirdstep'>
                                        <div id='urldiv'>
                                            <audio controls src={audioUrl}></audio>
                                        </div>
                                        <div id='bdiv'>
                                            <button onClick={refreshPage}>Intentar de Nuevo</button>
                                            <button onClick={downloadAudio}>Télécharger l'audio</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    </div>
</div>

      
<div className='faqmic'>
    <div id='fnqmic'>
        <div>
            <h4>Comment puis-je tester mon microphone en utilisant cette application ?</h4>
            <p>Pour tester votre microphone, il vous suffit de vous rendre dans la zone principale de fonctionnalités de l'application et de cliquer sur le bouton de Test de Microphone désigné. Suivez les instructions à l'écran fournies par l'application pour effectuer le test.</p>
        </div>

        <div>
            <h4>Que faire si mon microphone ne fonctionne pas pendant le test ?</h4>
            <p>Si votre microphone ne fonctionne pas pendant le test, assurez-vous d'abord qu'il est correctement connecté à votre appareil et que l'application dispose des autorisations nécessaires pour accéder à votre microphone. Si le problème persiste, essayez de rafraîchir la page ou de redémarrer votre appareil.</p>
        </div>

        <div>
            <h4>Puis-je ajuster les paramètres du microphone dans l'application ?</h4>
            <p>Actuellement, l'application peut ne pas offrir de réglages directs pour le microphone. Cependant, vous pouvez généralement ajuster les paramètres du microphone via les préférences système ou le menu de configuration de votre appareil. Consultez la documentation de votre appareil pour obtenir des instructions spécifiques sur la façon d'ajuster les paramètres du microphone.</p>
        </div>

        <div>
            <h4>Quels navigateurs sont compatibles pour le test de microphone ?</h4>
            <p>Notre application est compatible avec une large gamme de navigateurs web modernes, y compris Google Chrome, Mozilla Firefox, Safari et Microsoft Edge. Assurez-vous d'utiliser la dernière version de votre navigateur préféré pour des performances optimales.</p>
        </div>

        <div>
            <h4>Mes données de microphone sont-elles stockées ou enregistrées pendant le test ?</h4>
            <p>Non, l'application ne stocke ni n'enregistre aucune donnée de vos tests de microphone. Votre vie privée et votre sécurité sont importantes pour nous, et nous ne conservons aucun enregistrement audio ou information personnelle collectée pendant le test.</p>
        </div>

        <div>
            <h4>J'entends des bruits de fond ou de la statique pendant le test de microphone. Que dois-je faire ?</h4>
            <p>La statique ou les bruits de fond pendant le test de microphone peuvent être causés par différents facteurs, tels que des interférences environnementales ou des problèmes matériels du microphone. Essayez de tester votre microphone dans un environnement plus silencieux et assurez-vous qu'il est correctement positionné. Si le problème persiste, envisagez d'utiliser un microphone différent ou contactez le support technique pour obtenir de l'aide.</p>
        </div>

        <div>
            <h4>Cet outil est-il compatible avec les appareils iOS ?</h4>
            <p>Absolument. Cet outil est compatible avec tous les appareils. Ouvrez simplement votre navigateur et accédez à MicTestAudio.com pour tester votre microphone. Notre site web est accessible sur différents navigateurs, y compris Chrome, Safari, Edge et Firefox.</p>
        </div>

        <div>
            <h4>À quel point les résultats du test de microphone fournis par l'application sont-ils précis ?</h4>
            <p>Les résultats du test de microphone fournis par l'application sont généralement précis pour évaluer la fonctionnalité de base du microphone. Cependant, veuillez noter que la précision du test peut varier en fonction de facteurs tels que le matériel de l'appareil, la compatibilité du logiciel et les conditions environnementales.</p>
        </div>

        <div>
            <h4>J'ai terminé le test de microphone, mais j'ai toujours des problèmes avec l'entrée/sortie audio. Que dois-je faire ?</h4>
            <p>Si vous avez terminé le test de microphone mais que vous rencontrez toujours des problèmes avec l'entrée ou la sortie audio, envisagez de résoudre les paramètres audio de votre appareil ou de contacter le support technique pour obtenir de l'aide supplémentaire. Le problème peut être lié à des conflits logiciels, des mises à jour de pilotes ou un dysfonctionnement matériel.</p>
        </div>
    </div>
</div>


      <Ffr/> 
    </div>
  )
}

export default Mfr