import React from 'react';
import './about.css';
import Footer from '../fOOTER/Footer';
import Header from '../Header/Header';

export default function About() {
  return (
    <div>
      <Header/>
      <div className='mainabout'>
        <div className='titlesabout'>
          <h1>About</h1>
          <p>Greetings and welcome to MicTestAudio.com! I'm Pardeep, and I'm thrilled to introduce you to this platform that has emerged from my exploration of the programming world.</p>
          <p>The idea for MicTestAudio.com originated from a personal challenge I faced when the left speaker of my headphones stopped working. Determined to overcome this hurdle, I set out to develop a straightforward yet efficient tool for testing left-right channel audio. As my programming skills grew, I recognized the opportunity to expand this tool into a comprehensive resource for audio testing and problem-solving.</p>
          <p>MicTestAudio.com has evolved over time, incorporating additional tools and features to meet a variety of audio testing needs. However, our journey is far from over. I am dedicated to further enhancing this platform by introducing more tools and features to better serve you.</p>
          <p>Your feedback and suggestions mean the world to us. If you have any ideas or recommendations for improving MicTestAudio.com, please don't hesitate to reach out to me at sevensitexd@gmail.com. Your input allows us to evolve and better meet your audio testing needs.</p>
          <p>Once again, thank you for visiting MicTestAudio.com. We're grateful for your support and look forward to serving you better in the future.</p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
