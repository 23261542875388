import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';

const Fes = () => {
  return (
    <div>
      <footer id='footer'>
        <div id='foodiv'>
          <div id='one'>
            <ul>
              <Link id='rslinks' title='Inicio' to="/es/">Inicio</Link><span>|</span> 
              <Link id='rslinks' title='Prueba de Sonido' to="/es/sound-test">Prueba de Sonido</Link><span>|</span>
              <Link id='rslinks' title='Prueba de Micrófono' to="/es/mic-test">Prueba de Micrófono</Link><span>|</span>
              <Link id='rslinks' title='Generador de Tonos' to="/es/tone-generator">Generador de Tonos</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='two'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/es/">MicTestAudio.com</Link> <span>|</span>
              <Link id='rslinks' title='Acerca de' to="/es/about">Acerca de</Link> <span>|</span>
              <Link id='rslinks' title='Política de Privacidad' to="/privacy-policy">Política de Privacidad</Link><span>|</span>
              <Link id='rslinks' title='Contacto' to="/contact">Contacto</Link>
            </ul>
          </div>
          <div id='three'>
            <ul>
              <Link id='rslinks' title='Inicio' to="/es/">Inicio</Link><span>|</span> 
              <Link id='rslinks' title='Prueba de Sonido' to="/es/sound-test">Prueba de Sonido</Link><span>|</span>
              <Link id='rslinks' title='Prueba de Micrófono' to="/es/mic-test">Prueba de Micrófono</Link>
            </ul>
          </div>
          <div id='four'>
            <ul>
              <Link id='rslinks' title='Generador de Tonos' to="/es/tone-generator">Generador de Tonos</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='five'>
            <ul>
              <Link id='rslinks' title='Acerca de' to="/es/about">Acerca de</Link> <span>|</span>
              <Link id='rslinks' title='Política de Privacidad' to="/privacy-policy">Política de Privacidad</Link><span>|</span>
              <Link id='rslinks' title='Contacto' to="/contact">Contacto</Link>
            </ul>
          </div>
          <div id='six'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/es/">MicTestAudio.com</Link>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Fes;
