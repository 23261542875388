import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Ftr from '../fOOTER/Ftr';
import Theader from '../Header/Theader';

const Htr = () => {
  return (
    <div>
  <Theader/>
  <div id='alpha'>
    <div className='titles'>
      <h1><strong>Ses-Test-Audio</strong></h1>
      <h2>Ses ayarlarınızı, online hoparlör, kulaklık sesi ve mikrofon testini yapın</h2>
    </div>
  </div>
  <div className='flexdiv'>
    <ul className='toollist'>
      <li>
        <Link id='rlinks' title='Online Ses Testi' to="/tr/sound-test"><LuSpeaker className='icons'/>Ses Testi</Link>
      </li>
      <li>
        <Link id='rlinks' title='Online Mikrofon Testi' to='/tr/mic-test'><IoMicOutline className='icons'/>Mikrofon Testi</Link>
      </li>
      <li>
        <Link id='rlinks' title='Ton Üreteci' to='/tr/tone-generator'><PiWaveSineLight className='icons'/>Ton Üreteci</Link>
      </li>
    </ul>
  </div>
  <div className='fdiv'>
    <div id='features'>
      <div id='indiv'>
        <BiSelectMultiple id='hicons'/>
        <h6>Hızlı Performans ve Verimlilik</h6>
        <p>Araçlarımız, mikrofonunuzu, kulaklığınızı ve hoparlörlerinizi test etmenin hızlı ve etkili bir yolunu sunar. Bu ücretsiz araçlar, indirmeler veya yazılım yüklemeleri gerektirmez ve kullanımı kolaydır. Kullanıcılar, kararlı bir internet bağlantısı olduğu sürece bunları tekrar tekrar kullanabilirler.</p>
      </div>
      <div id='indiv'>
        <MdDevices id='hicons'/>
        <h6>Çoklu Cihaz Desteği</h6>
        <p>Araçlarımız farklı cihazları destekler ve farklı kurulumlar için platformlar arası uyumluluk sağlar. Masaüstü bilgisayarlardan, dizüstü bilgisayarlara, tabletlerden akıllı telefonlara kadar çeşitli cihazlardan kolayca erişebilir ve gerçek zamanlı testler yapabilirsiniz.</p>
      </div>
      <div id='indiv'>
        <IoIosLock id='hicons'/>
        <h6>Güvenlik ve Gizlilik</h6>
        <p>Güvenliğiniz ve gizliliğiniz bizim için önemlidir. Web sitemiz hesap kaydı veya kişisel veri paylaşımı gerektirmez. Mikrofon sesiniz asla kaydedilmez; sayfayı yeniden yüklediğinizde otomatik olarak silinir. Bu yaklaşım, gereksiz engellerle karşılaşmadan özelliklerimizi keşfetmenize olanak tanır.</p>
      </div>
      <div id='indiv'>
        <MdOutlineFeaturedVideo id='hicons'/>
        <h6>Özellikler</h6>
        <p>Web sitemiz, ses meraklıları için birçok harika özellik sunar. Kullanıcılar, profesyonel biri, teknoloji meraklısı veya sadece sesle ilgili meraklı olsanız bile, özellikleri keşfedebilir, sesler oluşturabilir, mikrofonlarını test edebilir ve cihazlarının nasıl ses çıkardığını kontrol edebilirler.</p>
      </div>
    </div>
  </div>
  <Ftr/>
</div>

  )
}

export default Htr;
