import React, { Component } from 'react';
import './another.css'
import Fro from '../fOOTER/Fro';
import Rheader from '../Header/Rheader';
 
class Dro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
      <div>
        <Rheader/>
<div id='tmaintone'>
  <div id='titlestone'>
    <h1>Generator de Tonuri Online</h1>
    <p>Vă rugăm să selectați frecvența preferată și să apăsați butonul de redare pentru a iniția procesul de generare a tonurilor. Acest instrument este gratuit și vă permite să testați căștile sau difuzoarele cu Generatorul de Tonuri Online. Generatorul de tonuri oferă patru forme de undă distincte: Sinusoidală, Pătrată, Dinte de ferăstrău și Triunghiulară. Puteți alege forma de undă dorită făcând clic pe butoanele corespunzătoare furnizate.</p>
  </div>
</div>

<div id='mainarea'>
  <div id='tonearea'>
    <div className='frequency'>
      <label className='labels'>Frecvență</label>
      <div id='insidefreq'>
        <div id='frdiv'>
          <input
            id='frange'
            type="range"
            min="20"
            max="2000"
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}
          />
        </div>
        <div id='hzz'>
          <div id='fnumbers'>{this.state.frequency}</div>
        </div>
        <div id='hz'>Hz</div>
      </div>
    </div>

    <div className='playbuttons'>
      <div className='buttons'>
        <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
          Redare
        </button>
        <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
          Stop
        </button>
      </div>
    </div>

    <div className='waveform'>
      <label>Formă de Undă</label>
      <div className='waveforms'>
        <label>
          <input
            type="radio"
            value="sine"
            checked={this.state.waveform === 'sine'}
            onChange={this.handleWaveformChange}
          />
          Sinusoidală
        </label>
        <label>
          <input
            type="radio"
            value="square"
            checked={this.state.waveform === 'square'}
            onChange={this.handleWaveformChange}
          />
          Pătrată
        </label>
        <label>
          <input
            type="radio"
            value="triangle"
            checked={this.state.waveform === 'triangle'}
            onChange={this.handleWaveformChange}
          />
          Triunghiulară
        </label>
        <label>
          <input
            type="radio"
            value="sawtooth"
            checked={this.state.waveform === 'sawtooth'}
            onChange={this.handleWaveformChange}
          />
          Dinte de Ferăstrău
        </label>
      </div>
    </div>

    <div className='volume'>
      <label className='labels'>Volum</label>
      <div id='insidevol'>
        <div id='voldiv'>
          <input
            id='vrange'
            type="range"
            min="0"
            max="15"
            step="1"
            value={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        </div>
        <div id='volumenumber'>
          {this.state.volume}
        </div>
      </div>
    </div>

    <div className='balance'>
      <div id='bal'>
        <label>Balanță</label>
        <div id='balancediv'>
          <div>L</div>
          <input
            id='brange'
            type="range"
            min="-100"
            max="100"
            value={this.state.balance}
            onChange={this.handleBalanceChange}
          />
          <div>R</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='headi'>
  <div id='hhdiv'>
    <h5>Vă rugăm să rețineți să mențineți volumul căștilor sau difuzoarelor la un nivel scăzut pentru a preveni prejudiciile aduse urechilor sau echipamentului. Unele echipamente audio nu pot gestiona bine frecvențele extreme. Fiți atenți la capacitățile difuzoarelor sau căștilor dumneavoastră atunci când generați tonuri, în special la frecvențe înalte sau joase, pentru a evita posibilele daune.</h5>
  </div>
</div>

<div id='hdiv'>
  <div id='howto'>
    <h3>Cum se folosește Generatorul de Tonuri</h3>
    <ol>
      <li><strong>Selecția Frecvenței:</strong> Începeți prin ajustarea frecvenței dorite folosind cursorul de frecvență furnizat.</li>
      <li><strong>Selecția Formei de Undă:</strong> Alegeți forma de undă corespunzătoare din selecția disponibilă în secțiunea de forme de undă.</li>
      <li><strong>Ajustarea Volumului:</strong> Înainte de a genera tonuri, se recomandă să ajustați volumul la un nivel mai scăzut inițial.</li>
      <li><strong>Redarea Tonurilor:</strong> Faceți clic pe butonul Redare pentru a iniția generarea tonurilor. Pentru a opri generarea tonurilor, faceți pur și simplu clic pe butonul Stop.</li>
      <li><strong>Ajustarea Balanței Canalului:</strong> Reglați fin balanța între canalele stâng și drept folosind controlul de balanță.</li>
    </ol>
  </div>
</div>

      
<div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>Ce este un generator de tonuri?</h4>
      <p>Un generator de tonuri este un instrument care produce tonuri audio de diferite frecvențe. Este folosit în mod obișnuit pentru testarea echipamentului audio, crearea muzicii sau ca instrument terapeutic.</p>
    </div>

    <div>
      <h4>Cum funcționează generatorul de tonuri?</h4>
      <p>Aplicația generatorului de tonuri generează tonuri audio producând frecvențe specifice ale undelor sonore. Utilizatorii pot ajusta frecvența, amplitudinea și forma de undă a tonurilor generate.</p>
    </div>

    <div>
      <h4>Pentru ce pot folosi generatorul de tonuri?</h4>
      <p>Generatorul de tonuri poate fi folosit pentru diverse scopuri, cum ar fi testarea echipamentului audio, acordarea instrumentelor muzicale, efectuarea experimentelor în ingineria sunetului și chiar pentru relaxare.</p>
    </div>

    <div>
      <h4>Pot folosi generatorul de tonuri pentru a acorda instrumente muzicale?</h4>
      <p>Da, puteți folosi generatorul de tonuri pentru a acorda instrumente muzicale redând tonuri de frecvențe specifice corespunzătoare notelor diferite.</p>
    </div>

    <div>
      <h4>Acest instrument este compatibil cu dispozitivele iOS?</h4>
      <p>Absolut. Acest instrument este compatibil cu toate dispozitivele. Pur și simplu deschideți browser-ul și navigați către MicTestAudio.com pentru a accesa Generatorul de Tonuri Online. Site-ul nostru este accesibil pe diverse browsere, inclusiv Chrome, Safari, Edge și Firefox.</p>
    </div>

    <div>
      <h4>Ce tipuri de forme de undă suportă generatorul de tonuri?</h4>
      <p>Generatorul de tonuri suportă în mod obișnuit diverse forme de undă, inclusiv sinusoidală, pătrată, triunghiulară și cu dinte de ferăstrău. Fiecare formă de undă produce un sunet distinct caracteristic.</p>
    </div>

    <div>
      <h4>Cât de precise sunt frecvențele produse de generatorul de tonuri?</h4>
      <p>Frecvențele produse de generatorul de tonuri sunt în general precise într-un anumit nivel de toleranță, în funcție de precizia algoritmului de generare audio și de capacitățile hardware ale dispozitivului.</p>
    </div>

    <div>
      <h4>Este aplicația generatorului de tonuri gratuită?</h4>
      <p>Multe aplicații de generator de tonuri oferă funcționalitate de bază gratuit, în timp ce funcționalitățile avansate pot fi disponibile prin achiziții în aplicație sau versiuni premium.</p>
    </div>

    <div>
      <h4>Este aplicația generatorului de tonuri compatibilă cu toate dispozitivele și browserele?</h4>
      <p>Compatibilitatea poate varia în funcție de dispozitiv și browser. Se recomandă verificarea cerințelor de sistem ale aplicației pentru performanțe optime.</p>
    </div>
  </div>
</div>

      <Fro/>
      </div>
    );
  }
}

export default Dro;
