import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { IoMicOutline } from "react-icons/io5";
import './Header.css';
import logo from'./logo512.png';
import { IoIosGlobe } from "react-icons/io";


export default function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" >
      <Container id='cos'>
        <Navbar.Brand ><Link id='rlinks' title='MicTestAudio.com' to="/" ><img id='himage' src={logo} height={40} width={40} alt="MicTestAudio.com"/></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <NavDropdown title="Tools" id="basic-nav-dropdown">
              <NavDropdown.Item className='dropmenuitems'> <Link id='rlinks' title='Sound Test' to="/sound-test" > <LuSpeaker className='icons' />Sound Test</Link></NavDropdown.Item>
              <NavDropdown.Item className='dropmenuitems'>
              <Link id='rlinks' title='Mic Test' to="/mic-test" > <IoMicOutline className='icons'/>Mic Test</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className='dropmenuitems'> <Link id='rlinks' title='Tone Generator' to="/tone-generator" > <PiWaveSineLight className='icons'/>Tone Generator</Link></NavDropdown.Item>
            </NavDropdown>
          <Nav className="me-auto">
            <a id='rlinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a>
            
          </Nav>

          <NavDropdown title={<span id='globeicon'><IoIosGlobe/></span>} id="basic-nav-dropdown">
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/">English</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/de/">Deutsch</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/tr/">Türkçe</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/es/">Español</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/pt/">Português</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/ro/">Română</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/nl/">Nederlands</Link></NavDropdown.Item>
          <NavDropdown.Item className='dropmenuitems'><Link id='llinks' to="/fr/">Français</Link></NavDropdown.Item>
            </NavDropdown>

        </Navbar.Collapse>
        
      </Container>
      
    </Navbar>
  );
  
}
