import React, { Component } from 'react';
import './another.css'
import Footer from '../fOOTER/Footer';
import Header from '../Header/Header';
 
class Dts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
      <div>
        <Header/>
        <div id='tmaintone'>
        <div id='titlestone'>
        <h1>Online Tone Generator</h1>
        <p>Please select your preferred frequency and click the play button to initiate the tone generation process. This Tool is free to use, try and test your headphones or speakers with this Online Tone Generator. The tone generator offers four distinct waveforms: Sine, Square, Sawtooth, and Triangle. You may choose the desired waveform by clicking on the corresponding buttons provided.</p>
        
        </div>
        </div>
      
      <div id='mainarea'>
      <div id='tonearea'>


        <div className='frequency'>
          <label className='labels'>Frequency</label>
        <div id='insidefreq'>
          <div id='frdiv'>
          <input
              id='frange'
              type="range"
              min="20"
              max="2000"
              value={this.state.frequency}
              onChange={this.handleFrequencyChange}
            />
            </div>
            <div id='hzz'>
              <div id='fnumbers'>{this.state.frequency}</div>
            </div>
            <div id='hz'>Hz</div>
       
        </div>
        </div>

        

        <div className='playbuttons'>
        <div className='buttons'>
          <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
            Play
          </button>
          <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
            Stop
          </button>
        </div>

        </div>

        <div className='waveform'>
          <label>Waveform</label>
        <div className='waveforms'>
              <label>
                <input
                  type="radio"
                  value="sine"
                  checked={this.state.waveform === 'sine'}
                  onChange={this.handleWaveformChange}
                />
                Sine
              </label>
              <label>
                <input
                  type="radio"
                  value="square"
                  checked={this.state.waveform === 'square'}
                  onChange={this.handleWaveformChange}
                />
                Square
              </label>
              <label>
                <input
                  type="radio"
                  value="triangle"
                  checked={this.state.waveform === 'triangle'}
                  onChange={this.handleWaveformChange}
                />
                Triangle
              </label>
              <label>
                <input
                  type="radio"
                  value="sawtooth"
                  checked={this.state.waveform === 'sawtooth'}
                  onChange={this.handleWaveformChange}
                />
                Sawtooth
              </label>
            </div>

        </div>

        <div className='volume'>
          <label className='labels'>Volume</label>
        <div id='insidevol'>
          <div id='voldiv'>
            <input
            id='vrange'
              type="range"
              min="0"
              max="15"
              step="1"
              value={this.state.volume}
              onChange={this.handleVolumeChange}
            />
            </div>
            <div id='volumenumber'>
            {this.state.volume}
            </div>  
        </div>
        </div>

        <div className='balance'>
        <div id='bal'>
          <label>
            Balance
            </label>
            <div id='balancediv'>
              <div>L</div>
            <input
            id='brange'
              type="range"
              min="-100"
              max="100"
              value={this.state.balance}
              onChange={this.handleBalanceChange}
            />
            <div>R</div>
            </div>
            
          
        </div>

        </div>




 
      </div>
      </div>

      <div id='headi'>
        <div id='hhdiv'>
        <h5>Please remember to keep the volume of your headphones or speakers low to prevent harm to your ears or the equipment. Some audio equipment may not handle extreme frequencies well. Be mindful of the capabilities of your speakers or headphones when generating tones, particularly at high or low frequencies, to avoid potential damage.
        </h5>
        </div>
      </div>
      <div id='hdiv'>
        <div id='howto'>
          <h3>How To Use Tone Generator</h3>
          <ol>
            <li><strong>Frequency Selection:</strong>Begin by adjusting the desired frequency using the frequency range slider provided.</li>
            <li><strong>Waveform Selection:</strong> Choose the appropriate waveform from the selection available in the waveform section.</li>
            <li><strong>Volume Adjustment:</strong> Prior to generating tones, it is recommended to adjust the volume to a lower level initially.</li>
            <li><strong>Playing Tones:</strong> Click on the Play button to initiate tone generation. To halt the generation of tones, simply click on the Stop button.</li>
            <li><strong>Channel Balance Adjustment:</strong> Fine-tune the balance between the left and right channels using the Balance Range control.</li>
          </ol>
        </div>
      </div>
      
      <div className='faqtone'>
        <div id='fnqtone'>
          <div>
            <h4>What is a tone generator?</h4>
            <p>A tone generator is a tool that produces audio tones of different frequencies. It's commonly used for testing audio equipment, creating music, or as a therapeutic tool.</p>
          </div>

          <div>
            <h4>How does the tone generator work?</h4>
            <p>The tone generator app generates audio tones by producing specific frequencies of sound waves. Users can adjust the frequency, amplitude, and waveform of the generated tones.</p>
          </div>

          <div>
            <h4>What can I use the tone generator for?</h4>
            <p>The tone generator can be used for various purposes such as testing audio equipment, tuning musical instruments, conducting experiments in sound engineering, and even for relaxation.</p>
          </div>

          <div>
            <h4>Can I use the tone generator to tune musical instruments?</h4>
            <p>Yes, you can use the tone generator to tune musical instruments by playing tones of specific frequencies corresponding to different notes.</p>
          </div>

          <div>
            <h4>Is this tool compatible with iOS devices?</h4>
            <p>Absolutely. This tool is compatible with all devices. Simply open your browser and navigate to MicTestAudio.com to access the Online Tone Generator. Our website is accessible on various browsers including Chrome, Safari, Edge, and Firefox.</p>
          </div>

          <div>
            <h4>What types of waveforms does the tone generator support?</h4>
            <p>The tone generator typically supports various waveforms including sine, square, triangle, and sawtooth waves. Each waveform produces a distinct sound characteristic.</p>
          </div>

          <div>
            <h4>How accurate are the frequencies produced by the tone generator?</h4>
            <p>The frequencies produced by the tone generator are generally accurate within a certain tolerance level, depending on the precision of the audio generation algorithm and the device's hardware capabilities.</p>
          </div>

          <div>
            <h4>Is the tone generator app free to use?</h4>
            <p>Many tone generator apps offer basic functionality for free, while advanced features may be available through in-app purchases or premium versions.</p>
          </div>

          <div>
            <h4>Is the tone generator app compatible with all devices and browsers?</h4>
            <p>Compatibility may vary depending on the device and browser. It's recommended to check the app's system requirements for optimal performance.</p>
          </div>

        </div>
      </div>
      <Footer/>
      </div>
    );
  }
}

export default Dts;
