import React, { Component } from 'react';
import './another.css'
import Ftr from '../fOOTER/Ftr';
import Theader from '../Header/Theader';

 
class Dtr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
      <div>
        <Theader/>
    <div id='tmaintone'>
  <div id='titlestone'>
    <h1>Online Ton Üretici</h1>
    <p>Lütfen tercih ettiğiniz frekansı seçin ve ton üretme sürecini başlatmak için oynatma düğmesine tıklayın. Bu Araç ücretsizdir, kulaklıklarınızı veya hoparlörlerinizi bu Çevrimiçi Ton Üretici ile deneyin ve test edin. Ton üreticisi dört farklı dalga biçimi sunar: Sinüs, Kare, Testere dişi ve Üçgen. İlgili düğmelere tıklayarak istediğiniz dalga biçimini seçebilirsiniz.</p>
  </div>
</div>

<div id='mainarea'>
  <div id='tonearea'>
    <div className='frequency'>
      <label className='labels'>Frekans</label>
      <div id='insidefreq'>
        <div id='frdiv'>
          <input
            id='frange'
            type="range"
            min="20"
            max="2000"
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}
          />
        </div>
        <div id='hzz'>
          <div id='fnumbers'>{this.state.frequency}</div>
        </div>
        <div id='hz'>Hz</div>
      </div>
    </div>

    <div className='playbuttons'>
      <div className='buttons'>
        <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
          Oynat
        </button>
        <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
          Durdur
        </button>
      </div>
    </div>

    <div className='waveform'>
      <label>Dalga Biçimi</label>
      <div className='waveforms'>
        <label>
          <input
            type="radio"
            value="sine"
            checked={this.state.waveform === 'sine'}
            onChange={this.handleWaveformChange}
          />
          Sinüs
        </label>
        <label>
          <input
            type="radio"
            value="square"
            checked={this.state.waveform === 'square'}
            onChange={this.handleWaveformChange}
          />
          Kare
        </label>
        <label>
          <input
            type="radio"
            value="triangle"
            checked={this.state.waveform === 'triangle'}
            onChange={this.handleWaveformChange}
          />
          Üçgen
        </label>
        <label>
          <input
            type="radio"
            value="sawtooth"
            checked={this.state.waveform === 'sawtooth'}
            onChange={this.handleWaveformChange}
          />
          Testere Dişi
        </label>
      </div>
    </div>

    <div className='volume'>
      <label className='labels'>Ses Seviyesi</label>
      <div id='insidevol'>
        <div id='voldiv'>
          <input
            id='vrange'
            type="range"
            min="0"
            max="15"
            step="1"
            value={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        </div>
        <div id='volumenumber'>
          {this.state.volume}
        </div>  
      </div>
    </div>

    <div className='balance'>
      <div id='bal'>
        <label>Denge</label>
        <div id='balancediv'>
          <div>L</div>
          <input
            id='brange'
            type="range"
            min="-100"
            max="100"
            value={this.state.balance}
            onChange={this.handleBalanceChange}
          />
          <div>R</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='headi'>
  <div id='hhdiv'>
    <h5>Lütfen kulaklıklarınızın veya hoparlörlerinizin ses seviyesini düşük tutmayı unutmayın, böylece kulaklarınıza veya ekipmanınıza zarar gelmez. Bazı ses ekipmanları aşırı frekansları iyi işleyemeyebilir. Potansiyel hasarları önlemek için ton üretirken hoparlörlerinizin veya kulaklıklarınızın yeteneklerine dikkat edin, özellikle yüksek veya düşük frekanslarda.</h5>
  </div>
</div>

<div id='hdiv'>
  <div id='howto'>
    <h3>Ton Üretici Nasıl Kullanılır</h3>
    <ol>
      <li><strong>Frekans Seçimi:</strong> İstenen frekansı frekans aralığı kaydırıcısını kullanarak ayarlayarak başlayın.</li>
      <li><strong>Dalga Biçimi Seçimi:</strong> Dalga biçimi bölümünde mevcut seçenekler arasından uygun dalga biçimini seçin.</li>
      <li><strong>Ses Seviyesi Ayarı:</strong> Ton üretmeden önce ses seviyesini başlangıçta daha düşük bir seviyeye ayarlamanız önerilir.</li>
      <li><strong>Tonları Oynatma:</strong> Ton üretimini başlatmak için Oynat düğmesine tıklayın. Ton üretimini durdurmak için basitçe Duraklat düğmesine tıklayın.</li>
      <li><strong>Kanal Denge Ayarı:</strong> Sol ve sağ kanallar arasındaki dengeyi ince ayarlamak için Denge Aralığı kontrolünü kullanın.</li>
    </ol>
  </div>
</div>

      
<div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>Ton üreteci nedir?</h4>
      <p>Bir ton üreteci, farklı frekanslarda ses tonları üreten bir araçtır. Genellikle ses ekipmanlarını test etmek, müzik oluşturmak veya terapötik bir araç olarak kullanılır.</p>
    </div>

    <div>
      <h4>Ton üretici nasıl çalışır?</h4>
      <p>Ton üretici uygulaması, belirli frekanslarda ses dalgaları üreterek ses tonlarını oluşturur. Kullanıcılar, üretilen tonların frekansını, genliğini ve dalga formunu ayarlayabilirler.</p>
    </div>

    <div>
      <h4>Ton üreticisini ne için kullanabilirim?</h4>
      <p>Ton üreticisi, ses ekipmanlarını test etme, müzik aletlerini ayarlama, ses mühendisliği deneyleri yapma ve hatta rahatlama gibi çeşitli amaçlar için kullanılabilir.</p>
    </div>

    <div>
      <h4>Ton üreticisini müzik aletlerini ayarlamak için kullanabilir miyim?</h4>
      <p>Evet, müzik aletlerini ayarlamak için ton üreticisini belirli frekanslara sahip tonlar çalarak kullanabilirsiniz.</p>
    </div>

    <div>
      <h4>Bu araç iOS cihazları ile uyumlu mu?</h4>
      <p>Kesinlikle. Bu araç tüm cihazlarla uyumludur. Sadece tarayıcınızı açın ve Online Ton Üreticisine erişmek için MicTestAudio.com'a gidin. Web sitemiz, Chrome, Safari, Edge ve Firefox dahil olmak üzere çeşitli tarayıcılarda erişilebilir.</p>
    </div>

    <div>
      <h4>Ton üreticisi hangi tür dalga formlarını destekler?</h4>
      <p>Ton üreticisi genellikle sinüs, kare, üçgen ve testere dişi dalgaları dahil olmak üzere çeşitli dalga formlarını destekler. Her dalga formu farklı bir ses karakteristiği üretir.</p>
    </div>

    <div>
      <h4>Ton üretici tarafından üretilen frekanslar ne kadar doğru?</h4>
      <p>Ton üreticisi tarafından üretilen frekanslar, ses üretim algoritmasının hassasiyetine ve cihazın donanım yeteneklerine bağlı olarak belirli bir tolerans seviyesi içinde genellikle doğrudur.</p>
    </div>

    <div>
      <h4>Ton üretici uygulaması ücretsiz mi?</h4>
      <p>Birçok ton üretici uygulama temel işlevselliği ücretsiz sunarken, gelişmiş özellikler genellikle uygulama içi satın alımlar veya premium sürümler aracılığıyla mevcut olabilir.</p>
    </div>

    <div>
      <h4>Ton üretici uygulaması tüm cihazlar ve tarayıcılarla uyumlu mu?</h4>
      <p>Uyumluluk, cihaza ve tarayıcıya bağlı olarak değişebilir. Optimal performans için uygulamanın sistem gereksinimlerini kontrol etmek önerilir.</p>
    </div>
  </div>
</div>

      <Ftr/>
      </div>
    );
  }
}

export default Dtr;
