import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Ftr from '../fOOTER/Ftr';
import { Helmet } from 'react-helmet';
import Theader from '../Header/Theader';

const Mtr = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(<div id='failedaccess'><h6>Mikrofona erişim başarısız oldu.</h6><h6>Lütfen tarayıcı ayarlarınızı kontrol edin ve mikrofon erişimini etkinleştirin.</h6><IoIosWarning id='sicon'/></div>);

      }
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch(error){
        setError(<div id='failedaccess'><h6>Mikrofona erişim başarısız oldu.</h6><h6>Lütfen tarayıcı ayarlarınızı kontrol edin ve mikrofon erişimini etkinleştirin.</h6><IoIosWarning id='sicon'/></div>);

      }
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <Theader/>
      <Helmet>
    <meta charSet="utf-8" />
    <title>Mikrofon Testi Online</title>
    <meta name='description' content='Mikrofonunuzun çalışıp çalışmadığını test etmenin kolay bir yolu. Mikrofon ikonuna tıklayarak mikrofonunuzun hatasız çalışıp çalışmadığını kontrol edin. Kullanmak için sadece mikrofon simgesine tıklayın.'/>
    <meta name='keywords' content='mikrofon test uygulaması, mikrofonumu test et, merhaba mikrofon testi, mikrofon testi online, google mikrofon testi'/>
</Helmet>

<div className='headingsarea'>
  <div id='titlesmic'>
    <h1><span><IoMicOutline className='icons'/></span>Mikrofon Ses Testi</h1>
    <h2>Cihazınızın Mikrofonunu Test Edin, Ses Kaydedin ve İndirin</h2>
  </div>
</div>
<div className='micarea'>
  <div className='mictester'>
    <div id='left'>
      <h3>Mikrofonunuzu test etmek için bu 3 adımı izleyin:</h3>
      <ul id='ulleft'>
        <li><strong>Adım 1: </strong>Testi başlatmak için <strong> "mikrofon simgesine" </strong>tıklayın <strong> ve izin verin.</strong></li>
        <li><strong>Adım 2: </strong>Mikrofonunuzda bir şeyler söyleyin, örneğin <strong>Merhaba </strong>demeniz yeterlidir.</li>
        <li><strong>Adım 3: </strong> Eğer <strong>ses dalgalarını</strong> görüyorsanız, mikrofonunuz mükemmel şekilde çalışıyor demektir.</li>
      </ul>
    </div>
    <div id='right'>
      <div className='rightone'>
        {error && <div id='errorpara'>{error}</div>}
        {!error && (
          <>
            {layout===1 && (
              <div id='firststep'>
                <h6>Mikrofon Testini Başlatmak İçin Mikrofona Tıklayın</h6>
                <button onClick={startRecording}><FaMicrophone id='micicon' title='Kayda Başla'/></button>
              </div>
            )}
            {layout === 2 && (
              <div id='secondstep'>
                <div id='xdiv'>
                  <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                </div>
                <button onClick={stopRecording}><FaSquare id='stopicon' title='Kaydı Durdur'/></button>
              </div>
            )}
            {layout === 3 && (
              <div>
                {audioUrl && (
                  <div id='thirdstep'>
                    <div id='urldiv'>
                      <audio controls src={audioUrl}></audio>
                    </div>
                    <div id='bdiv'>
                      <button onClick={refreshPage}>Yeniden Deneyin</button>
                      <button onClick={downloadAudio}>Sesi İndir</button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  </div>
</div>

      
<div className='faqmic'>
  <div id='fnqmic'>
    <div>
      <h4>Bu uygulama ile mikrofonumu nasıl test ederim?</h4>
      <p>Mikrofonunuzu test etmek için, sadece uygulamanın ana işlev alanına gidin ve belirlenmiş Test Mikrofonu düğmesine tıklayın. Uygulama tarafından sağlanan ekran talimatlarını takip ederek testi tamamlayın.</p>
    </div>

    <div>
      <h4>Test sırasında mikrofonum çalışmıyorsa ne yapmalıyım?</h4>
      <p>Eğer test sırasında mikrofonunuz çalışmıyorsa, öncelikle cihazınıza doğru şekilde bağlı olduğundan ve uygulamanın mikrofonunuza erişim izinlerinin verildiğinden emin olun. Sorun devam ederse, sayfayı yenilemeyi veya cihazınızı yeniden başlatmayı deneyin.</p>
    </div>

    <div>
      <h4>Uygulama içinde mikrofon ayarlarını ayarlayabilir miyim?</h4>
      <p>Şu anda, uygulama doğrudan mikrofon ayarı sunmayabilir. Ancak, genellikle mikrofon ayarlarınızı cihazınızın sistem tercihleri veya ayarlar menüsü aracılığıyla ayarlayabilirsiniz. Mikrofon ayarlarını nasıl ayarlayacağınızla ilgili spesifik talimatlar için cihazınızın belgelerine başvurun.</p>
    </div>

    <div>
      <h4>Mikrofon testi için hangi tarayıcılar destekleniyor?</h4>
      <p>Uygulamamız Google Chrome, Mozilla Firefox, Safari ve Microsoft Edge gibi geniş bir modern web tarayıcısı yelpazesini destekler. Optimal performans için tercih ettiğiniz tarayıcının en son sürümünü kullanmaya özen gösterin.</p>
    </div>

    <div>
      <h4>Mikrofon testi sırasında mikrofon verilerim saklanıyor veya kaydediliyor mu?</h4>
      <p>Hayır, uygulama mikrofon testlerinizden herhangi bir veriyi saklamaz veya kaydetmez. Gizliliğiniz ve güvenliğiniz bizim için önemlidir ve test sırasında toplanan ses kayıtları veya kişisel bilgileri saklamayız.</p>
    </div>

    <div>
      <h4>Mikrofon testi sırasında statik veya arka plan gürültüsü duyuyorum. Ne yapmalıyım?</h4>
      <p>Mikrofon testi sırasında statik veya arka plan gürültüsü, çevresel etkiler veya mikrofon donanımı sorunları gibi çeşitli faktörlerden kaynaklanabilir. Mikrofonunuzu daha sessiz bir ortamda test etmeyi deneyin ve doğru şekilde konumlandığından emin olun. Sorun devam ederse, farklı bir mikrofon kullanmayı veya teknik destek için iletişime geçmeyi düşünün.</p>
    </div>

    <div>
      <h4>Bu araç iOS cihazlarıyla uyumlu mu?</h4>
      <p>Evet. Bu araç tüm cihazlarla uyumludur. Sadece tarayıcınızı açın ve MicTestAudio.com adresine giderek Mikrofon testine erişin. Web sitemiz, Chrome, Safari, Edge ve Firefox gibi çeşitli tarayıcılarda erişilebilir.</p>
    </div>

    <div>
      <h4>Uygulama tarafından sağlanan mikrofon testi sonuçları ne kadar doğru?</h4>
      <p>Uygulama tarafından sağlanan mikrofon testi sonuçları genellikle temel mikrofon işlevselliğini değerlendirmek için doğrudur. Ancak, lütfen testin doğruluğunun, cihaz donanımı, yazılım uyumluluğu ve çevresel koşullar gibi faktörlere bağlı olarak değişebileceğini unutmayın.</p>
    </div>

    <div>
      <h4>Mikrofon testini tamamladım ancak hala ses giriş/çıkışı sorunları yaşıyorum. Ne yapmalıyım?</h4>
      <p>Eğer mikrofon testini tamamladıysanız ancak ses giriş veya çıkışı sorunları yaşıyorsanız, cihazınızın ses ayarlarını sorun gidermeyi veya teknik destek için iletişime geçmeyi düşünün. Sorunun yazılım çatışmaları, sürücü güncellemeleri veya donanım arızalarıyla ilgili olabileceği mümkündür.</p>
    </div>
  </div>
</div>

      <Ftr/> 
    </div>
  )
}

export default Mtr