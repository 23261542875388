import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Gheader from '../Header/Gheader';
import Fde from '../fOOTER/Fde';

const Hde = () => {
  return (
    <div>
      <Gheader/>
      <div id='alpha'>
        <div className='titles'>
          <h1><strong>Sound-Test-Audio</strong></h1>
          <h2>Testen Sie Ihren Setup-Online-Lautsprecher, Kopfhörer-Sound und Mikrofon-Test</h2>
        </div>
      </div>
      <div className='flexdiv'>
        <ul className='toollist'>
          <li>
            <Link id='rlinks' title='Online-Sound-Test' to="/de/sound-test"><LuSpeaker className='icons'/>Sound-Test</Link>
          </li>
          <li>
            <Link id='rlinks' title='Online-Mikrofon-Test' to='/de/mic-test'><IoMicOutline className='icons'/>Mikrofon-Test</Link>
          </li>
          <li>
            <Link id='rlinks' title='Tongenerator' to='/de/tone-generator'><PiWaveSineLight className='icons'/>Tongenerator</Link>
          </li>
        </ul>
      </div>
      <div className='fdiv'>
        <div id='features'>
          <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
            <h6>Schnelle Leistung und Effizienz</h6>
            <p>Unsere Tools bieten eine schnelle und effektive Methode zum Testen Ihres Mikrofons, Ihrer Kopfhörer und Lautsprecher. Diese kostenlosen Dienstprogramme entfernen die Notwendigkeit für Downloads oder Softwareinstallationen und gewährleisten eine bequeme Zugänglichkeit. Benutzer können sie wiederholt nutzen, vorausgesetzt, dass eine stabile Internetverbindung besteht.</p>
          </div>
          <div id='indiv'>
            <MdDevices id='hicons'/>
            <h6>Mehrfachgerätefähigkeit</h6>
            <p>Unsere Tools unterstützen verschiedene Geräte und bieten Kompatibilität über Plattformen für unterschiedliche Setups. Greifen Sie problemlos auf sie auf Desktops, Laptops, Tablets oder Smartphones zu, um Echtzeit-Tests durchzuführen und Töne zu generieren.</p>
          </div>
          <div id='indiv'>
            <IoIosLock id='hicons'/>
            <h6>Sicherheit und Datenschutz</h6>
            <p>Ihre Sicherheit und Privatsphäre haben für uns oberste Priorität. Unsere Website erfordert keine Registrierung eines Kontos oder das Teilen persönlicher Daten. Ihr Mikrofonaudio wird nie gespeichert; es wird jedes Mal automatisch gelöscht, wenn Sie die Seite neu laden. Dieser Ansatz ermöglicht es Ihnen, sich vollständig auf die Erkundung unserer Funktionen zu konzentrieren, ohne auf unnötige Hindernisse zu stoßen.</p>
          </div>
          <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
            <h6>Funktionen</h6>
            <p>Unsere Website bietet viele coole Funktionen für Audiobegeisterte. Benutzer können mühelos Funktionen erkunden, Töne erstellen, ihr Mikrofon testen und überprüfen, wie ihr Gerät klingt. Es ist einfach zu bedienen, egal ob Sie ein Profi, ein Technikfreak oder einfach nur neugierig auf Sound sind.</p>
          </div>
        </div>
      </div>
      <Fde/>
    </div>
  )
}

export default Hde;
