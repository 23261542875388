import React, { useEffect } from 'react';
import './sound.css';
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Fro from '../fOOTER/Fro';
import Rheader from '../Header/Rheader';

const Sro = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

  const leftplay = () => {
    stopplay();
    audio1.play();
  }

  const rightplay = () => {
    stopplay();
    audio2.play();
  }

  const bothplay = () => {
    stopplay();
    audio3.play();
  }

  const stopplay = () => {
    audio1.pause();
    audio1.currentTime = 0;
    audio2.pause();
    audio2.currentTime = 0;
    audio3.pause();
    audio3.currentTime = 0;
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        stopplay();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopplay();
    };
  }, []);

  return (
    <div>
      <Rheader/>
      <div id='titlescontainersound'>
        <div id='titlessound'>
          <h1><strong>Instrument de Testare a Sunetului Online</strong></h1>
          <h2>Verifică Funcționarea Difuzoarelor sau a Căștilor, Controlând Sunetul Stânga-Dreapta cu un Singur Click</h2>
        </div>
      </div>
      <div id='buttonscontainer'>
        <div id='buttons'>
          <button title='Apasă pentru Controlul din Stânga' onClick={leftplay}>
            <HiPlay id='ilclass' />
            <span>Difuzor Stânga</span>
          </button>
          <button title='Apasă pentru Controlul Amândurora' onClick={bothplay}>
            <BsSpeaker id='iclass'/>
            <span>Amândoi</span>
          </button>
          <button title='Apasă pentru Controlul din Dreapta' onClick={rightplay}>
            <HiPlay id='iclass' />
            <span>Difuzor Dreapta</span>
          </button>
        </div>
      </div>
      <div id='hw'>
        <div className='howto'>
          <h3>Cum Folosești Instrumentul de Testare a Sunetului</h3>
          <ol>
            <li>Apasă butonul <strong>Difuzor Stânga</strong> pentru a verifica dacă difuzorul stâng al dispozitivului tău funcționează.</li>
            <li>Apasă butonul din mijloc pentru a verifica <strong>Amândoi</strong>.</li>
            <li>Apasă butonul <strong>Difuzor Dreapta</strong> pentru a testa ambele difuzoare simultan.</li>
          </ol>
        </div>
      </div>
      <div className='faqsound'>
        <div id='fnqsound'>
          <div>
            <h4>Care este scopul controlului difuzoarelor stânga-dreapta?</h4>
            <p>Controlul difuzoarelor stânga-dreapta se asigură că difuzoarele sau căștile tale funcționează corect pe canalele stânga și dreapta. Menține un sunet echilibrat pentru o experiență auditivă optimă în diferite medii.</p>
          </div>
          <div>
            <h4>Difuzoarele mele au nevoie de control stânga-dreapta?</h4>
            <p>Dacă observi dezechilibre în sunet, cum ar fi un canal mai puternic sau mai silențios decât celălalt, sau distorsiuni sau lipsa clarității în timpul redării sunetului, este o idee bună să efectuezi controlul difuzoarelor stânga-dreapta.</p>
          </div>
          <div>
            <h4>Se poate face controlul difuzoarelor stânga-dreapta atât pe căști, cât și pe difuzoare externe?</h4>
            <p>Da, instrumentul nostru permite controlul difuzoarelor stânga-dreapta atât pe căști, cât și pe difuzoare externe. Indiferent dacă folosești dispozitive cablate sau wireless, instrumentul nostru oferă o soluție practică pentru a verifica echilibrul și funcționalitatea sunetului.</p>
          </div>
          <div>
            <h4>Acest instrument este compatibil cu dispozitivele iOS?</h4>
            <p>Desigur. Acest instrument este compatibil cu toate dispozitivele. Pur și simplu deschide-ți browser-ul și navighează la SesTestAudio.com pentru a accesa testul de sunet. Site-ul nostru este accesibil pe diferite browsere, inclusiv Chrome, Safari, Edge și Firefox.</p>
          </div>
    <div>
    
  <h4>Ce ar trebui să fac dacă întâmpin probleme în timpul controlului difuzoarelor stânga-dreapta?</h4>
  <p>Dacă observați că un canal nu emite sunet sau că există o diferență semnificativă între canalele stânga și dreapta, poate fi necesar să verificați setările audio. Acest lucru poate include ajustarea setărilor dispozitivului, verificarea conexiunilor cablurilor sau, în cazul necesar, obținerea de asistență profesională.</p>
</div>
<div>
  <h4>Se poate realiza controlul difuzoarelor stânga-dreapta online?</h4>
  <p>Da, instrumentul nostru de testare a sunetului vă permite să efectuați controlul difuzoarelor stânga-dreapta de pe dispozitivul dvs. fără a fi necesară descărcarea unui software suplimentar. Accesați site-ul nostru prin intermediul browserului și urmați instrucțiunile pentru a asigura un sunet echilibrat.</p>
</div>
<div>
  <h4>Cum pot să-mi dau seama dacă difuzoarele sau căștile funcționează corect?</h4>
  <p>Realizarea unui test de sunet este o modalitate fiabilă de a determina funcționalitatea difuzoarelor sau a căștilor. Redând sunet din canale diferite (stânga și dreapta), utilizatorii pot fi siguri că ambele părți emit sunet în mod corespunzător și fără distorsiuni.</p>
</div>
<div>
  <h4>Cum pot să-mi dau seama dacă difuzoarele funcționează?</h4>
  <p>Pentru a testa dacă difuzoarele sau căștile funcționează, trebuie să apăsați butoanele etichetate pentru stânga, dreapta și ambele atunci când sunt activate. Dacă apăsați butonul pentru stânga și nu auziți sunet din partea stângă, difuzorul stâng poate fi nefuncțional. Aceeași logică se aplică și pentru partea dreaptă și pentru ambele părți.</p>
</div>

  </div>
</div>

      <Fro/>

    </div>
  )
}

export default Sro