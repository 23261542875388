import React, {useEffect, useTransition} from 'react'
import './sound.css'
import { HiPlay } from "react-icons/hi2";
import { BsSpeaker } from "react-icons/bs";
import { left, right, both } from '../Audios';
import Ftr from '../fOOTER/Ftr';
import Theader from '../Header/Theader';


const Str = () => {
  
  const audio1 = new Audio(left);
  const audio2 = new Audio(right);
  const audio3 = new Audio(both);

    const leftplay = ()=>{
      stopplay();
      audio1.play();
    }

    const rightplay = ()=>{
      stopplay();
      audio2.play();
    }

    const bothplay = ()=>{
      stopplay();
      audio3.play();
    }

    function stopplay(){
      audio1.pause();
      audio1.currentTime = 0;
      audio2.pause();
      audio2.currentTime = 0;
      audio3.pause();
      audio3.currentTime = 0;
    }

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          stopplay();
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        stopplay();
      };
    }, []);
 
  return (
    <div>
      <Theader/>
      
      <div id='titlescontainersound'>
  <div id='titlessound'>
    <h1><strong>Çevrimiçi Ses Test Aracı</strong></h1>
    <h2>Kulaklık veya Hoparlör Sol Sağ Kontrolü Tek Tıklamayla</h2>
  </div>
</div>
<div id='buttonscontainer'>
  <div id='buttons'>
    <button title='Sol Kontrol İçin Tıklayın' onClick={leftplay}>
      <HiPlay id='ilclass' />
      <span>Sol Hoparlör</span>
    </button>
    <button title='Her İki Hoparlörü Kontrol İçin Tıklayın' onClick={bothplay}>
      <BsSpeaker id='iclass'/>
      <span>Her İkisi</span>
    </button>
    <button title='Sağ Kontrol İçin Tıklayın' onClick={rightplay}>
      <HiPlay id='iclass' />
      <span>Sağ Hoparlör</span>
    </button>
  </div>
</div>
<div id='hw'>
  <div className='howto'>
    <h3>Ses Test Aracını Nasıl Kullanırım</h3>
    <ol>
      <li><strong>Sol Hoparlör</strong> düğmesine tıklayarak cihazınızın sol hoparlörünün çalışıp çalışmadığını kontrol edin.</li>
      <li><strong>Her İkisi</strong>ni kontrol etmek için ortadaki düğmeye tıklayın.</li>
      <li><strong>Sağ Hoparlör</strong> düğmesine basarak her iki hoparlörü aynı anda test edin.</li>
    </ol>
  </div>
</div>
<div className='faqsound'>
  <div id='fnqsound'>
    <div>
      <h4>Sol-sağ hoparlör kontrolünün amacı nedir?</h4>
      <p>Sol-sağ hoparlör kontrolü, kulaklıklarınızın veya hoparlörlerinizin sol ve sağ kanallarının doğru bir şekilde çalıştığından emin olur. Farklı ortamlarda optimal dinleme deneyimi için dengeli ses çıkışını korur.</p>
    </div>
    <div>
      <h4>Hoparlörlerim sol-sağ kontrolüne ihtiyaç duyuyor mu?</h4>
      <p>Ses dengesizlikleri, bir tarafın diğerinden daha yüksek veya daha sessiz olması gibi durumları fark ederseniz veya ses çalma sırasında bozulma veya netlik eksikliği yaşarsanız, sol-sağ hoparlör kontrolünü yapmanız iyi bir fikirdir.</p>
    </div>
    <div>
      <h4>Sol-sağ hoparlör kontrolü hem kulaklıklarda hem de harici hoparlörlerde yapılabilir mi?</h4>
      <p>Evet, aracımız hem kulaklıklarda hem de harici hoparlörlerde sol-sağ hoparlör kontrolleri yapmanıza olanak tanır. Kablolu veya kablosuz cihazlar kullanıyor olun, aracımız ses dengesini ve işlevselliği doğrulamak için pratik bir çözüm sunar.</p>
    </div>
    <div>
      <h4>Bu araç iOS cihazlarıyla uyumlu mu?</h4>
      <p>Kesinlikle. Bu araç tüm cihazlarla uyumludur. Sadece tarayıcınızı açın ve SesTestAudio.com adresine giderek ses testine erişin. Web sitemiz, Chrome, Safari, Edge ve Firefox gibi çeşitli tarayıcılarda erişilebilir.</p>
    </div>
    <div>
      <h4>Sol-sağ hoparlör kontrolü sırasında sorunlar tespit edersem ne yapmalıyım?</h4>
      <p>Bir kanalın ses çıkarmadığını veya sol ve sağ kanallar arasında fark edilir bir ses farkını tespit ederseniz, ses kurulumunuzu sorun gidermek gerekebilir. Bu cihaz ayarlarını ayarlamayı, kablo bağlantılarını kontrol etmeyi veya gerektiğinde profesyonel yardım almayı içerebilir.</p>
    </div>
    <div>
      <h4>Çevrimiçi olarak sol-sağ hoparlör kontrolü yapılabilir mi?</h4>
      <p>Evet, web sitemizin ses test aracı, ek yazılım veya indirmeler gerekmeden cihazınızdan kolayca sol-sağ hoparlör kontrolü yapmanızı sağlar. Web sitemize tarayıcınız aracılığıyla erişin ve dengeli ses çıkışını sağlamak için talimatları izleyin.</p>
    </div>
    <div>
      <h4>Hoparlörlerim veya kulaklıklarımın doğru çalışıp çalışmadığını nasıl anlarım?</h4>
      <p>Bir ses testi yapmak, hoparlörlerin veya kulaklıkların işlevselliğini belirlemenin güvenilir bir yoludur. Farklı kanallardan (sol ve sağ) ses çalarak, kullanıcılar her iki tarafın da sesi düzgün ve bozulmasız bir şekilde çıkardığından emin olabilirler.</p>
    </div>
    <div>
      <h4>Hoparlörün çalışıp çalışmadığını nasıl anlarım?</h4>
      <p>Kulaklıklarınız veya hoparlörleriniz açıldığında, sol, sağ ve her ikisini kontrol etmek için etiketli düğmelere tıklamanız gerekir. Sol düğmesine tıklarsanız ve sol taraftan ses duymazsanız, sol hoparlörün çalışmadığı anlamına gelir. Aynı şekilde, sağ tarafta ve her iki tarafta da aynıdır.</p>
    </div>
  </div>
</div>

      <Ftr/>

    </div>
  )
}

export default Str