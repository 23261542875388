import React from 'react';
import './about.css';
import Theader from '../Header/Theader';
import Ftr from '../fOOTER/Ftr';

export default function Atr() {
  return (
    <div>
      <Theader/>
      <div className='mainabout'>
        <div className='titlesabout'>
          <h1>Hakkında</h1>
          <p>Merhaba ve MicTestAudio.com'a hoş geldiniz! Ben Pardeep, ve sizi programlama dünyasının bir keşfi olan bu platforma tanıtmaktan heyecan duyuyorum.</p>
          <p>MicTestAudio.com fikri, kulaklığımın sol hoparlörünün çalışmamasıyla karşılaştığım kişisel bir zorluktan kaynaklandı. Bu engeli aşmaya kararlı olarak, sol-sağ kanal sesini test etmek için basit ancak etkili bir araç geliştirmeye karar verdim. Programlama becerilerim geliştikçe, bu aracı ses testi ve sorun giderme için kapsamlı bir kaynak haline dönüştürme fırsatını gördüm.</p>
          <p>MicTestAudio.com zamanla evrim geçirdi, çeşitli ses testi ihtiyaçlarını karşılamak için ek araçlar ve özellikler içerdi. Ancak, yolculuğumuz henüz bitmedi. Sizi daha iyi hizmet etmek için daha fazla araç ve özellik ekleyerek bu platformu daha da geliştirmeye kararlıyım.</p>
          <p>Geri bildiriminiz ve önerileriniz bizim için çok değerlidir. MicTestAudio.com'u nasıl iyileştirebileceğimize dair herhangi bir fikriniz veya öneriniz varsa, lütfen bana sevensitexd@gmail.com adresinden ulaşmaktan çekinmeyin. Görüşleriniz bize evrim geçirme ve ses testi ihtiyaçlarınızı daha iyi karşılama fırsatı sunar.</p>
          <p>Bir kez daha, MicTestAudio.com'u ziyaret ettiğiniz için teşekkür ederiz. Desteğiniz için minnettarız ve gelecekte sizi daha iyi hizmet etmeyi dört gözle bekliyoruz.</p>
        </div>
      </div>
      <Ftr/>
    </div>
  );
}
