import React from 'react';
import '../Home.css';
import { Link } from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Fro from '../fOOTER/Fro';
import Rheader from '../Header/Rheader';

const Hro = () => {
  return (
    <div>
      <Rheader/>
      <div id='alpha'>
        <div className='titles'>
          <h1><strong>Test de Sunet Online</strong></h1>
          <h2>Efectuați ajustări ale sunetului, teste ale difuzoarelor online, căști și microfon</h2>
        </div>
      </div>
      <div className='flexdiv'>
        <ul className='toollist'>
          <li>
            <Link id='rlinks' title='Test de Sunet Online' to="/ro/sound-test"><LuSpeaker className='icons'/>Test de Sunet</Link>
          </li>
          <li>
            <Link id='rlinks' title='Test de Microfon Online' to='/ro/mic-test'><IoMicOutline className='icons'/>Test de Microfon</Link>
          </li>
          <li>
            <Link id='rlinks' title='Generator de Tonuri' to='/ro/tone-generator'><PiWaveSineLight className='icons'/>Generator de Tonuri</Link>
          </li>
        </ul>
      </div>
      <div className='fdiv'>
        <div id='features'>
          <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
            <h6>Performanță și Eficiență Ridicate</h6>
            <p>Instrumentele noastre oferă o modalitate rapidă și eficientă de a testa microfonul, căștile și difuzoarele. Aceste instrumente gratuite nu necesită descărcări sau instalare de software și sunt ușor de folosit. Utilizatorii le pot utiliza de mai multe ori atâta timp cât au o conexiune stabilă la internet.</p>
          </div>
          <div id='indiv'>
            <MdDevices id='hicons'/>
            <h6>Suport pentru Mai Multe Dispozitive</h6>
            <p>Instrumentele noastre acceptă diferite dispozitive și oferă compatibilitate între platforme pentru diferite configurații. Puteți accesa și efectua teste în timp real ușor pe o varietate de dispozitive, de la computere desktop și laptopuri la tablete și smartphone-uri.</p>
          </div>
          <div id='indiv'>
            <IoIosLock id='hicons'/>
            <h6>Securitate și Confidențialitate</h6>
            <p>Siguranța și confidențialitatea dumneavoastră sunt importante pentru noi. Site-ul nostru nu necesită înregistrarea unui cont sau schimbul de date personale. Sunetul microfonului dumneavoastră nu este niciodată înregistrat; este șters automat când actualizați pagina. Această abordare vă permite să explorați caracteristicile noastre fără a întâlni obstacole inutile.</p>
          </div>
          <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
            <h6>Caracteristici</h6>
            <p>Site-ul nostru oferă multe caracteristici excelente pentru entuziaștii de sunet. Indiferent dacă sunteți profesionist în domeniul sunetului, un entuziast al tehnologiei sau pur și simplu sunteți curios de sunet, puteți explora caracteristicile noastre, crea sunete, testa microfonul și controla modul în care sună dispozitivele dumneavoastră.</p>
          </div>
        </div>
      </div>
      <Fro/>
    </div>
  )
}

export default Hro;
