import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Fes from '../fOOTER/Fes';
import { Helmet } from 'react-helmet';
import ESHeader from '../Header/ESHeader';

const Mes = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>No se pudo acceder al micrófono.</h6>
            <h6>Por favor, verifica la configuración de tu navegador y habilita el acceso al micrófono.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch (error) {
        setError(
          <div id='failedaccess'>
            <h6>No se pudo acceder al micrófono.</h6>
            <h6>Por favor, verifica la configuración de tu navegador y habilita el acceso al micrófono.</h6>
            <IoIosWarning id='sicon'/>
          </div>
        );
      }
      
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <ESHeader/>
      <Helmet>
    <meta charSet="utf-8" />
    <title>Prueba de Micrófono en Línea</title>
    <meta name='description' content='Forma fácil de verificar si tu micrófono funciona correctamente. Simplemente haz clic en el icono del micrófono para comprobar si tu micrófono está funcionando sin problemas. Para usarlo, simplemente haz clic en el icono del micrófono.'/>
    <meta name='keywords' content='aplicación de prueba de micrófono, probar mi micrófono, hola prueba de micrófono, prueba de micrófono en línea, prueba de micrófono de Google'/>
</Helmet>

<div className='headingsarea'>
    <div id='titlesmic'>
        <h1><span><IoMicOutline className='icons'/></span>Prueba de Sonido del Micrófono</h1>
        <h2>Prueba tu Micrófono, Graba Audio y Descarga</h2>
    </div>
</div>
<div className='micarea'>
    <div className='mictester'>
        <div id='left'>
            <h3>Sigue estos 3 pasos para probar tu micrófono:</h3>
            <ul id='ulleft'>
                <li><strong>Paso 1:</strong> Haz clic en el <strong>"icono del micrófono"</strong> para comenzar la prueba <strong>y permite el permiso.</strong></li>
                <li><strong>Paso 2:</strong> Di algo en tu micrófono como <strong>Hola</strong> para verificar si funciona.</li>
                <li><strong>Paso 3:</strong> Si ves <strong>ondas de sonido,</strong> entonces tu micrófono está funcionando perfectamente.</li>
            </ul>
        </div>
        <div id='right'>
            <div className='rightone'>
                {error && <div id='errorpara'>{error}</div>}
                {!error && (
                    <>
                        {layout===1 && (
                            <div id='firststep'>
                                <h6>Haz Clic en el Micrófono Para Iniciar la Prueba del Micrófono</h6>
                                <button onClick={startRecording}><FaMicrophone id='micicon' title='Comenzar Grabación'/></button>
                            </div>
                        )}
                        {layout === 2 && (
                            <div id='secondstep'>
                                <div id='xdiv'>
                                    <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                                </div>
                                <button onClick={stopRecording}><FaSquare id='stopicon' title='Detener Grabación'/></button>
                            </div>
                        )}
                        {layout === 3 && (
                            <div>
                                {audioUrl && (
                                    <div id='thirdstep'>
                                        <div id='urldiv'>
                                            <audio controls src={audioUrl}></audio>
                                        </div>
                                        <div id='bdiv'>
                                            <button onClick={refreshPage}>Intentar de Nuevo</button>
                                            <button onClick={downloadAudio}>Descargar Audio</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    </div>
</div>

      
<div className='faqmic'>
    <div id='fnqmic'>
        <div>
            <h4>¿Cómo puedo probar mi micrófono usando esta aplicación?</h4>
            <p>Para probar tu micrófono, simplemente navega hasta el área principal de funcionalidad de la aplicación y haz clic en el botón designado de Prueba de Micrófono. Sigue cualquier instrucción en pantalla proporcionada por la aplicación para completar la prueba.</p>
        </div>

        <div>
            <h4>¿Qué debo hacer si mi micrófono no funciona durante la prueba?</h4>
            <p>Si tu micrófono no funciona durante la prueba, primero asegúrate de que esté correctamente conectado a tu dispositivo y de que la aplicación tenga los permisos necesarios para acceder a tu micrófono. Si el problema persiste, intenta actualizar la página o reiniciar tu dispositivo.</p>
        </div>

        <div>
            <h4>¿Puedo ajustar la configuración del micrófono dentro de la aplicación?</h4>
            <p>Actualmente, la aplicación puede no ofrecer ajustes directos para el micrófono. Sin embargo, generalmente puedes ajustar la configuración del micrófono a través de las preferencias del sistema o el menú de configuración de tu dispositivo. Consulta la documentación de tu dispositivo para obtener instrucciones específicas sobre cómo ajustar la configuración del micrófono.</p>
        </div>

        <div>
            <h4>¿Qué navegadores son compatibles para la prueba de micrófono?</h4>
            <p>Nuestra aplicación es compatible con una amplia gama de navegadores web modernos, incluyendo Google Chrome, Mozilla Firefox, Safari y Microsoft Edge. Asegúrate de estar utilizando la última versión de tu navegador preferido para un rendimiento óptimo.</p>
        </div>

        <div>
            <h4>¿Se almacenan o graban mis datos de micrófono durante la prueba?</h4>
            <p>No, la aplicación no almacena ni graba ningún dato de tus pruebas de micrófono. Tu privacidad y seguridad son importantes para nosotros, y no conservamos ninguna grabación de audio o información personal recopilada durante la prueba.</p>
        </div>

        <div>
            <h4>Escucho estática o ruido de fondo durante la prueba de micrófono. ¿Qué debo hacer?</h4>
            <p>La estática o el ruido de fondo durante la prueba de micrófono pueden ser causados por varios factores, como interferencia ambiental o problemas de hardware del micrófono. Intenta probar tu micrófono en un entorno más silencioso y asegúrate de que esté correctamente posicionado. Si el problema persiste, considera utilizar un micrófono diferente o contactar al soporte técnico para obtener ayuda.</p>
        </div>

        <div>
            <h4>¿Esta herramienta es compatible con dispositivos iOS?</h4>
            <p>Absolutamente. Esta herramienta es compatible con todos los dispositivos. Simplemente abre tu navegador y navega hasta MicTestAudio.com para acceder a la prueba de micrófono. Nuestro sitio web es accesible en varios navegadores, incluyendo Chrome, Safari, Edge y Firefox.</p>
        </div>

        <div>
            <h4>¿Qué tan precisos son los resultados de la prueba de micrófono proporcionados por la aplicación?</h4>
            <p>Los resultados de la prueba de micrófono proporcionados por la aplicación son generalmente precisos para evaluar la funcionalidad básica del micrófono. Sin embargo, ten en cuenta que la precisión de la prueba puede variar según factores como el hardware del dispositivo, la compatibilidad del software y las condiciones ambientales.</p>
        </div>

        <div>
            <h4>He completado la prueba de micrófono, pero aún tengo problemas con la entrada/salida de audio. ¿Qué debo hacer?</h4>
            <p>Si has completado la prueba de micrófono pero aún tienes problemas con la entrada o salida de audio, considera solucionar los ajustes de audio de tu dispositivo o consultar con el soporte técnico para obtener ayuda adicional. Es posible que el problema esté relacionado con conflictos de software, actualizaciones de controladores o mal funcionamiento del hardware.</p>
        </div>
    </div>
</div>

      <Fes/> 
    </div>
  )
}

export default Mes