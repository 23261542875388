import React from 'react';
import './about.css';
import Fnl from '../fOOTER/Fnl';
import NHeader from '../Header/NHeader';

export default function Anl() {
  return (
    <div>
      <NHeader/>
      <div className='mainabout'>
        <div className='titlesabout'>
          <h1>Over</h1>
          <p>Hallo en welkom bij MicTestAudio.com! Ik ben Pardeep, en ik ben enthousiast om je dit platform te presenteren dat is voortgekomen uit mijn verkenning in de wereld van programmeren.</p>
          <p>Het idee voor MicTestAudio.com ontstond uit een persoonlijke uitdaging die ik tegenkwam toen de linkerluidspreker van mijn koptelefoon het begaf. Vastbesloten om deze hindernis te overwinnen, besloot ik een eenvoudig maar efficiënt hulpmiddel te ontwikkelen om het audio van het linker- en rechterkanaal te testen. Naarmate mijn programmeervaardigheden groeiden, herkende ik de kans om dit hulpmiddel uit te breiden tot een uitgebreide bron voor audio-testen en probleemoplossing.</p>
          <p>MicTestAudio.com heeft zich in de loop der tijd ontwikkeld, waarbij extra tools en functies zijn toegevoegd om aan verschillende audio-testbehoeften te voldoen. Maar ons traject is nog lang niet voorbij. Ik ben toegewijd om dit platform verder te verbeteren door meer tools en functies te introduceren om je beter van dienst te zijn.</p>
          <p>Je feedback en suggesties betekenen veel voor ons. Als je ideeën of aanbevelingen hebt om MicTestAudio.com te verbeteren, aarzel dan niet om contact met me op te nemen via sevensitexd@gmail.com. Jouw bijdragen stellen ons in staat om te evolueren en beter aan je audio-testbehoeften te voldoen.</p>
          <p>Nogmaals bedankt voor je bezoek aan MicTestAudio.com. We zijn dankbaar voor je steun en hopen je in de toekomst nog beter van dienst te kunnen zijn.</p>
        </div>
      </div>
      <Fnl/>
    </div>
  );
}
