import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Fpt from '../fOOTER/Fpt';
import { Helmet } from 'react-helmet';
import Pheader from '../Header/Pheader';

const Mpt = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch(error){
        setError(<div id='failedaccess'><h6>Falha ao acessar o microfone.</h6><h6>Por favor, verifique as configurações do seu navegador e habilite o acesso ao microfone.</h6><IoIosWarning id='sicon'/></div>);
      }
      
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch(error){
        setError(<div id='failedaccess'><h6>Falha ao acessar o microfone.</h6><h6>Por favor, verifique as configurações do seu navegador e habilite o acesso ao microfone.</h6><IoIosWarning id='sicon'/></div>);
      }
      
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <Pheader/>
      <Helmet>
  <meta charSet="utf-8" />
  <title>Teste de Microfone Online</title>
  <meta name='description' content='Maneira fácil de testar se o seu microfone está funcionando ou não. Basta clicar no ícone do microfone para verificar se o seu microfone está funcionando perfeitamente. Para usar, basta clicar no ícone do microfone.'/>
  <meta name='keywords' content='aplicativo de teste de microfone, testar meu microfone, teste de microfone online, teste de microfone do google'/>
</Helmet>

<div className='headingsarea'>
  <div id='titlesmic'>
    <h1><span><IoMicOutline className='icons'/></span>Teste de Som do Microfone</h1>
    <h2>Teste o Microfone do seu Dispositivo, Grave Áudio e Baixe</h2>
  </div>
</div>
<div className='micarea'>
  <div className='mictester'>
    <div id='left'>
      <h3>Siga estes 3 passos para testar o seu microfone:</h3>
      <ul id='ulleft'>
        <li><strong>Passo 1: </strong>Clique no <strong>ícone do "microfone"</strong> para iniciar o teste <strong>e permita a permissão.</strong></li>
        <li><strong>Passo 2: </strong>Diga algo no seu microfone, como <strong>Olá</strong>, para verificar se funciona.</li>
        <li><strong>Passo 3: </strong> Se você ver <strong>ondas sonoras,</strong> então seu microfone está funcionando perfeitamente.</li>
      </ul>
    </div>
    <div id='right'>
      <div className='rightone'>
        {error && <div id='errorpara'>{error}</div>}
        {!error && (
          <>
            {layout===1 && (
              <div id='firststep'>
                <h6>Clique no Microfone para Iniciar o Teste do Microfone</h6>
                <button onClick={startRecording}><FaMicrophone id='micicon' title='Iniciar Gravação'/></button>
              </div>
            )}
            {layout === 2 && (
              <div id='secondstep'>
                <div id='xdiv'>
                  <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                </div>
                <button onClick={stopRecording}><FaSquare id='stopicon' title='Parar Gravação'/></button>
              </div>
            )}
            {layout === 3 && (
              <div>
                {audioUrl && (
                  <div id='thirdstep'>
                    <div id='urldiv'>
                      <audio controls src={audioUrl}></audio>
                    </div>
                    <div id='bdiv'>
                      <button onClick={refreshPage}>Tentar Novamente</button>
                      <button onClick={downloadAudio}>Baixar Áudio</button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  </div>
</div>

      
<div className='faqmic'>
  <div id='fnqmic'>
    <div>
      <h4>Como faço para testar meu microfone usando este aplicativo?</h4>
      <p>Para testar seu microfone, basta navegar até a área principal de funcionalidade do aplicativo e clicar no botão designado Testar Microfone. Siga quaisquer instruções na tela fornecidas pelo aplicativo para concluir o teste.</p>
    </div>

    <div>
      <h4>O que devo fazer se meu microfone não estiver funcionando durante o teste?</h4>
      <p>Se seu microfone não estiver funcionando durante o teste, primeiro verifique se ele está devidamente conectado ao seu dispositivo e se as permissões necessárias foram concedidas ao aplicativo para acessar seu microfone. Se o problema persistir, tente atualizar a página ou reiniciar o dispositivo.</p>
    </div>

    <div>
      <h4>Posso ajustar as configurações do microfone dentro do aplicativo?</h4>
      <p>Atualmente, o aplicativo pode não oferecer ajustes diretos para o microfone. No entanto, geralmente é possível ajustar as configurações do microfone por meio das preferências do sistema ou do menu de configurações do dispositivo. Consulte a documentação do seu dispositivo para obter instruções específicas sobre ajustes do microfone.</p>
    </div>

    <div>
      <h4>Quais navegadores são suportados para teste de microfone?</h4>
      <p>Nosso aplicativo suporta uma ampla gama de navegadores modernos, incluindo Google Chrome, Mozilla Firefox, Safari e Microsoft Edge. Certifique-se de estar usando a versão mais recente do seu navegador preferido para um desempenho ideal.</p>
    </div>

    <div>
      <h4>Meus dados de microfone são armazenados ou gravados durante o teste?</h4>
      <p>Não, o aplicativo não armazena nem grava dados dos seus testes de microfone. Sua privacidade e segurança são importantes para nós, e não retemos nenhuma gravação de áudio ou informações pessoais coletadas durante o teste.</p>
    </div>

    <div>
      <h4>Eu ouço estática ou ruído de fundo durante o teste de microfone. O que devo fazer?</h4>
      <p>A estática ou ruído de fundo durante o teste de microfone pode ser causada por vários fatores, como interferência ambiental ou problemas de hardware do microfone. Tente testar seu microfone em um ambiente mais silencioso e verifique se ele está posicionado corretamente. Se o problema persistir, considere usar um microfone diferente ou entrar em contato com o suporte técnico para obter assistência.</p>
    </div>

    <div>
      <h4>Esta ferramenta é compatível com dispositivos iOS?</h4>
      <p>Com certeza. Esta ferramenta é compatível com todos os dispositivos. Basta abrir seu navegador e acessar MicTestAudio.com para acessar o teste de microfone. Nosso site é acessível em vários navegadores, incluindo Chrome, Safari, Edge e Firefox.</p>
    </div>

    <div>
      <h4>Quão precisos são os resultados do teste de microfone fornecidos pelo aplicativo?</h4>
      <p>Os resultados do teste de microfone fornecidos pelo aplicativo são geralmente precisos para avaliar a funcionalidade básica do microfone. No entanto, observe que a precisão do teste pode variar dependendo de fatores como hardware do dispositivo, compatibilidade de software e condições ambientais.</p>
    </div>

    <div>
      <h4>Concluí o teste de microfone, mas ainda estou enfrentando problemas com a entrada/saída de áudio. O que devo fazer?</h4>
      <p>Se você concluiu o teste de microfone, mas ainda está enfrentando problemas com a entrada ou saída de áudio, considere solucionar as configurações de áudio do seu dispositivo ou consultar o suporte técnico para obter assistência adicional. É possível que o problema esteja relacionado a conflitos de software, atualizações de drivers ou falhas de hardware.</p>
    </div>
  </div>
</div>

      <Fpt/> 
    </div>
  )
}

export default Mpt