import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';

const Fro = () => {
  return (
    <div>
      <footer id='footer'>
        <div id='foodiv'>
          <div id='one'>
            <ul>
              <Link id='rslinks' title='Acasă' to="/ro/" >Acasă</Link><span>|</span> 
              <Link id='rslinks' title='Test de Sunet' to="/ro/sound-test" >Test de Sunet</Link><span>|</span>
              <Link id='rslinks' title='Test de Microfon' to="/ro/mic-test" >Test de Microfon</Link><span>|</span>
              <Link id='rslinks' title='Generator de Tonuri' to="/ro/tone-generator" >Generator de Tono</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='two'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/ro/" >MicTestAudio.com</Link> <span>|</span>
              <Link id='rslinks' title='Despre' to="/ro/about" >Despre</Link> <span>|</span>
              <Link id='rslinks' title='Politica de Confidențialitate' to="/privacy-policy" >Politica de Confidențialitate</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact" >Contact</Link>
            </ul>
          </div>
          <div id='three'>
            <ul>
              <Link id='rslinks' title='Acasă' to="/ro/" >Acasă</Link><span>|</span> 
              <Link id='rslinks' title='Test de Sunet' to="/ro/sound-test" >Test de Sunet</Link><span>|</span>
              <Link id='rslinks' title='Test de Microfon' to="/ro/mic-test" >Test de Microfon</Link>
            </ul>
          </div>
          <div id='four'>
            <ul>
              <Link id='rslinks' title='Generator de Tonuri' to="/ro/tone-generator" >Generator de Tono</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='five'>
            <ul>
              <Link id='rslinks' title='Despre' to="/ro/about" >Despre</Link> <span>|</span>
              <Link id='rslinks' title='Politica de Confidențialitate' to="/privacy-policy" >Politica de Confidențialitate</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact" >Contact</Link>
            </ul>
          </div>
          <div id='six'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/ro/" >MicTestAudio.com</Link>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Fro;
