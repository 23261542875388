import React from 'react';
import './about.css';
import Gheader from '../Header/Gheader';
import Fde from '../fOOTER/Fde';

export default function Ade() {
  return (
    <div>
      <Gheader/>
      <div className='mainabout'>
  <div className='titlesabout'>
    <h1>Über</h1>
    <p>Grüße und willkommen auf MicTestAudio.com! Ich bin Pardeep und ich freue mich, Ihnen diese Plattform vorzustellen, die aus meiner Erkundung der Programmierwelt entstanden ist.</p>
    <p>Die Idee für MicTestAudio.com entstand aus einer persönlichen Herausforderung, als der linke Lautsprecher meiner Kopfhörer nicht mehr funktionierte. Entschlossen, diese Hürde zu überwinden, machte ich mich daran, ein einfaches, aber effizientes Werkzeug zur Überprüfung des links-rechts-Kanal-Audios zu entwickeln. Mit zunehmenden Programmierkenntnissen erkannte ich die Möglichkeit, dieses Werkzeug zu einer umfassenden Ressource für Audio-Tests und -Problemlösungen auszubauen.</p>
    <p>MicTestAudio.com hat sich im Laufe der Zeit weiterentwickelt und zusätzliche Tools und Funktionen integriert, um verschiedenen Audio-Testanforderungen gerecht zu werden. Doch unsere Reise ist noch lange nicht zu Ende. Ich bin bestrebt, diese Plattform weiter zu verbessern, indem ich mehr Tools und Funktionen einführe, um Ihnen besser zu dienen.</p>
    <p>Ihr Feedback und Ihre Vorschläge bedeuten uns sehr viel. Wenn Sie Ideen oder Empfehlungen zur Verbesserung von MicTestAudio.com haben, zögern Sie bitte nicht, mich unter sevensitexd@gmail.com zu kontaktieren. Ihre Eingaben ermöglichen es uns, uns weiterzuentwickeln und besser auf Ihre Audio-Testbedürfnisse einzugehen.</p>
    <p>Nochmals vielen Dank, dass Sie MicTestAudio.com besucht haben. Wir sind dankbar für Ihre Unterstützung und freuen uns darauf, Sie in Zukunft noch besser bedienen zu können.</p>
  </div>
</div>

      <Fde/>
    </div>
  );
}
