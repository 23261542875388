import React, { Component } from 'react';
import './another.css'
import Fnl from '../fOOTER/Fnl';
import NHeader from '../Header/NHeader';
 
class Dnl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
        <div>
        <NHeader/>
        <div id='tmaintone'>
          <div id='titlestone'>
            <h1>Online Toongenerator</h1>
            <p>Selecteer uw gewenste frequentie en klik op de afspeelknop om het proces voor het genereren van tonen te starten. Deze tool is gratis te gebruiken, probeer en test uw koptelefoon of luidsprekers met deze online toongenerator. De toongenerator biedt vier verschillende golfvormen: Sinus, Vierkant, Zaagtand en Driehoek. U kunt de gewenste golfvorm kiezen door op de bijbehorende knoppen te klikken.</p>
          </div>
        </div>
      
        <div id='mainarea'>
          <div id='tonearea'>
            <div className='frequency'>
              <label className='labels'>Frequentie</label>
              <div id='insidefreq'>
                <div id='frdiv'>
                  <input
                    id='frange'
                    type="range"
                    min="20"
                    max="2000"
                    value={this.state.frequency}
                    onChange={this.handleFrequencyChange}
                  />
                </div>
                <div id='hzz'>
                  <div id='fnumbers'>{this.state.frequency}</div>
                </div>
                <div id='hz'>Hz</div>
              </div>
            </div>
      
            <div className='playbuttons'>
              <div className='buttons'>
                <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
                  Afspelen
                </button>
                <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
                  Stoppen
                </button>
              </div>
            </div>
      
            <div className='waveform'>
              <label>Golfvorm</label>
              <div className='waveforms'>
                <label>
                  <input
                    type="radio"
                    value="sine"
                    checked={this.state.waveform === 'sine'}
                    onChange={this.handleWaveformChange}
                  />
                  Sinus
                </label>
                <label>
                  <input
                    type="radio"
                    value="square"
                    checked={this.state.waveform === 'square'}
                    onChange={this.handleWaveformChange}
                  />
                  Vierkant
                </label>
                <label>
                  <input
                    type="radio"
                    value="triangle"
                    checked={this.state.waveform === 'triangle'}
                    onChange={this.handleWaveformChange}
                  />
                  Driehoek
                </label>
                <label>
                  <input
                    type="radio"
                    value="sawtooth"
                    checked={this.state.waveform === 'sawtooth'}
                    onChange={this.handleWaveformChange}
                  />
                  Zaagtand
                </label>
              </div>
            </div>
      
            <div className='volume'>
              <label className='labels'>Volume</label>
              <div id='insidevol'>
                <div id='voldiv'>
                  <input
                    id='vrange'
                    type="range"
                    min="0"
                    max="15"
                    step="1"
                    value={this.state.volume}
                    onChange={this.handleVolumeChange}
                  />
                </div>
                <div id='volumenumber'>
                  {this.state.volume}
                </div>
              </div>
            </div>
      
            <div className='balance'>
              <div id='bal'>
                <label>Balans</label>
                <div id='balancediv'>
                  <div>L</div>
                  <input
                    id='brange'
                    type="range"
                    min="-100"
                    max="100"
                    value={this.state.balance}
                    onChange={this.handleBalanceChange}
                  />
                  <div>R</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div id='headi'>
          <div id='hhdiv'>
            <h5>Vergeet niet om het volume van uw koptelefoon of luidsprekers laag te houden om schade aan uw oren of apparatuur te voorkomen. Sommige audioapparatuur kan extreme frequenties mogelijk niet goed verwerken. Wees u bewust van de mogelijkheden van uw luidsprekers of koptelefoon bij het genereren van tonen, met name bij hoge of lage frequenties, om mogelijke schade te voorkomen.</h5>
          </div>
        </div>
      
        <div id='hdiv'>
          <div id='howto'>
            <h3>Hoe de Toongenerator te gebruiken</h3>
            <ol>
              <li><strong>Frequentie Selectie:</strong> Begin met het aanpassen van de gewenste frequentie met behulp van de frequentie schuifregelaar.</li>
              <li><strong>Golfvorm Selectie:</strong> Kies de juiste golfvorm uit de selectie die beschikbaar is in het golfvormgedeelte.</li>
              <li><strong>Volume Aanpassing:</strong> Voordat u tonen genereert, wordt aanbevolen om het volume aanvankelijk op een lager niveau in te stellen.</li>
              <li><strong>Tonen Afspelen:</strong> Klik op de Afspeelknop om de toongeneratie te starten. Om de generatie van tonen te stoppen, klikt u eenvoudig op de Stopknop.</li>
              <li><strong>Balance Aanpassing:</strong> Verfijn de balans tussen de linker- en rechterkanalen met behulp van de Balans Schuifregelaar.</li>
            </ol>
          </div>
        </div>
      
      
      
        <div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>Wat is een toongenerator?</h4>
      <p>Een toongenerator is een hulpmiddel dat audiotoon van verschillende frequenties produceert. Het wordt vaak gebruikt voor het testen van audioapparatuur, het maken van muziek, of als een therapeutisch hulpmiddel.</p>
    </div>

    <div>
      <h4>Hoe werkt de toongenerator?</h4>
      <p>De toongenerator-app genereert audiotoon door specifieke frequenties van geluidsgolven te produceren. Gebruikers kunnen de frequentie, amplitude en golfvorm van de gegenereerde tonen aanpassen.</p>
    </div>

    <div>
      <h4>Waarvoor kan ik de toongenerator gebruiken?</h4>
      <p>De toongenerator kan worden gebruikt voor verschillende doeleinden zoals het testen van audioapparatuur, het stemmen van muziekinstrumenten, het uitvoeren van experimenten in geluidstechniek, en zelfs voor ontspanning.</p>
    </div>

    <div>
      <h4>Kan ik de toongenerator gebruiken om muziekinstrumenten te stemmen?</h4>
      <p>Ja, je kunt de toongenerator gebruiken om muziekinstrumenten te stemmen door tonen van specifieke frequenties te spelen die overeenkomen met verschillende noten.</p>
    </div>

    <div>
      <h4>Is deze tool compatibel met iOS-apparaten?</h4>
      <p>Zeker weten. Deze tool is compatibel met alle apparaten. Open eenvoudigweg je browser en ga naar MicTestAudio.com om toegang te krijgen tot de Online Toongenerator. Onze website is toegankelijk op verschillende browsers, waaronder Chrome, Safari, Edge en Firefox.</p>
    </div>

    <div>
      <h4>Welke soorten golfvormen ondersteunt de toongenerator?</h4>
      <p>De toongenerator ondersteunt doorgaans verschillende golfvormen, waaronder sinus-, vierkant-, driehoek- en zaagtandgolven. Elke golfvorm produceert een onderscheidend geluidskarakter.</p>
    </div>

    <div>
      <h4>Hoe nauwkeurig zijn de frequenties die door de toongenerator worden geproduceerd?</h4>
      <p>De frequenties die door de toongenerator worden geproduceerd, zijn over het algemeen nauwkeurig binnen een bepaald tolerantieniveau, afhankelijk van de precisie van het audiogeneratie-algoritme en de hardwaremogelijkheden van het apparaat.</p>
    </div>

    <div>
      <h4>Is de toongenerator-app gratis te gebruiken?</h4>
      <p>Veel toongenerator-apps bieden basisfunctionaliteit gratis aan, terwijl geavanceerde functies beschikbaar kunnen zijn via in-app-aankopen of premiumversies.</p>
    </div>

    <div>
      <h4>Is de toongenerator-app compatibel met alle apparaten en browsers?</h4>
      <p>De compatibiliteit kan variëren afhankelijk van het apparaat en de browser. Het wordt aanbevolen om de systeemvereisten van de app te controleren voor optimale prestaties.</p>
    </div>
  </div>
</div>

      <Fnl/>
      </div>
    );
  }
}

export default Dnl;
