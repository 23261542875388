import React from 'react'
import { Link} from "react-router-dom";
import './footer.css';

const Footer = () => {
  return (
    <div>
        <footer id='footer'>
          <div id='foodiv'>
        <div id='one'>
            <ul>
            <Link id='rslinks' title='Home' to="/" >Home</Link><span>|</span> 
            <Link id='rslinks' title='Sound Test' to="/sound-test" >Sound Test</Link><span>|</span>
           <Link id='rslinks' title='Mic Test' to="/mic-test" >Mic Test</Link><span>|</span>
            <Link id='rslinks' title='Tone Generator' to="/tone-generator" >Tone Generator</Link> <span>|</span>
            <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
        </div>
        <div id='two'>
            <ul>
            <span>©</span><Link id='rslinks' title='MicTestAudio' to="/" >MicTestAudio.com</Link> <span>|</span>
            <Link id='rslinks' title='About' to="/about" >About</Link> <span>|</span>
            <Link id='rslinks' title='Privacy Policy' to="/privacy-policy" >Privacy Policy</Link><span>|</span>
            <Link id='rslinks' title='Contact Us' to="/contact" >Contact</Link>
            </ul>
        </div>

        <div id='three'>
          <ul>
            <Link id='rslinks' title='Home' to="/" >Home</Link><span>|</span> 
            <Link id='rslinks' title='Sound Test' to="/sound-test" >Sound Test</Link><span>|</span>
           <Link id='rslinks' title='Mic Test' to="/mic-test" >Mic Test</Link>
          </ul>
        </div>

        <div id='four'>
        <ul>
        <Link id='rslinks' title='Tone Generator' to="/tone-generator" >Tone Generator</Link> <span>|</span>
        <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
        </ul>
        </div>

        <div id='five'>
        <ul>
        <Link id='rslinks' title='About' to="/about" >About</Link> <span>|</span>
            <Link id='rslinks' title='Privacy Policy' to="/privacy-policy" >Privacy Policy</Link><span>|</span>
            <Link id='rslinks' title='Contact Us' to="/contact" >Contact</Link>
        </ul>
        </div>

        <div id='six'>
          <ul>
          <span>©</span><Link id='rslinks' title='MicTestAudio' to="/" >MicTestAudio.com</Link>
          </ul>
        </div>
        </div>
        </footer>
    </div>
  )
}

export default Footer