import React, { Component } from 'react';
import './another.css'
import Fpt from '../fOOTER/Fpt';
import Pheader from '../Header/Pheader';

 
class Dpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
        <div>
        <Pheader/>
        <div id='tmaintone'>
          <div id='titlestone'>
          <h1>Gerador de Tons Online</h1>
            <p>Por favor, escolha a frequência desejada e clique no botão de reprodução para iniciar o processo de geração de tons. Esta ferramenta é gratuita, experimente com seus fones de ouvido ou alto-falantes. O gerador de tons oferece quatro formas de onda diferentes: Seno, Quadrada, Serra e Triangular. Você pode selecionar a forma de onda desejada clicando nos botões correspondentes.</p>

          </div>
        </div>

        <div id='mainarea'>
  <div id='tonearea'>
    <div className='frequency'>
      <label className='labels'>Frequência</label>
      <div id='insidefreq'>
        <div id='frdiv'>
          <input
            id='frange'
            type="range"
            min="20"
            max="2000"
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}
          />
        </div>
        <div id='hzz'>
          <div id='fnumbers'>{this.state.frequency}</div>
        </div>
        <div id='hz'>Hz</div>
      </div>
    </div>

    <div className='playbuttons'>
      <div className='buttons'>
        <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
          Reproduzir
        </button>
        <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
          Parar
        </button>
      </div>
    </div>

    <div className='waveform'>
      <label>Forma de Onda</label>
      <div className='waveforms'>
        <label>
          <input
            type="radio"
            value="sine"
            checked={this.state.waveform === 'sine'}
            onChange={this.handleWaveformChange}
          />
          Seno
        </label>
        <label>
          <input
            type="radio"
            value="square"
            checked={this.state.waveform === 'square'}
            onChange={this.handleWaveformChange}
          />
          Quadrada
        </label>
        <label>
          <input
            type="radio"
            value="triangle"
            checked={this.state.waveform === 'triangle'}
            onChange={this.handleWaveformChange}
          />
          Triangular
        </label>
        <label>
          <input
            type="radio"
            value="sawtooth"
            checked={this.state.waveform === 'sawtooth'}
            onChange={this.handleWaveformChange}
          />
          Serra
        </label>
      </div>
    </div>

    <div className='volume'>
      <label className='labels'>Volume</label>
      <div id='insidevol'>
        <div id='voldiv'>
          <input
            id='vrange'
            type="range"
            min="0"
            max="15"
            step="1"
            value={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        </div>
        <div id='volumenumber'>
          {this.state.volume}
        </div>  
      </div>
    </div>

    <div className='balance'>
      <div id='bal'>
        <label>Balanço</label>
        <div id='balancediv'>
          <div>Esq</div>
          <input
            id='brange'
            type="range"
            min="-100"
            max="100"
            value={this.state.balance}
            onChange={this.handleBalanceChange}
          />
          <div>Dir</div>
        </div>
      </div>
    </div>
  </div>
</div>


<div id='headi'>
  <div id='hhdiv'>
    <h5>Lembre-se de manter os níveis de volume dos seus auscultadores ou colunas baixos para evitar danificar os seus ouvidos ou equipamento. Alguns equipamentos de áudio podem não lidar bem com frequências extremas. Tenha cuidado com as capacidades das suas colunas ou auscultadores ao gerar tons, especialmente em frequências altas ou baixas, para evitar possíveis danos.</h5>
  </div>
</div>

<div id='hdiv'>
  <div id='howto'>
    <h3>Como Utilizar o Gerador de Tons</h3>
    <ol>
      <li><strong>Seleção de Frequência:</strong> Comece ajustando a frequência desejada utilizando o controle deslizante de intervalo de frequência.</li>
      <li><strong>Seleção de Forma de Onda:</strong> Escolha a forma de onda adequada entre as opções disponíveis na seção de forma de onda.</li>
      <li><strong>Ajuste de Volume:</strong> Recomenda-se ajustar inicialmente o volume para um nível baixo antes de gerar tons.</li>
      <li><strong>Reprodução de Tons:</strong> Clique no botão Reproduzir para iniciar a geração de tons. Basta clicar no botão Parar para interromper a geração de tons.</li>
      <li><strong>Ajuste do Equilíbrio de Canal:</strong> Utilize o controle de intervalo de equilíbrio para ajustar finamente o equilíbrio entre os canais esquerdo e direito.</li>
    </ol>
  </div>
</div>

<div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>O que é um gerador de tons?</h4>
      <p>Um gerador de tons é uma ferramenta que produz tons sonoros em diferentes frequências. É comumente usado para testar equipamentos de áudio, criar música ou como uma ferramenta terapêutica.</p>
    </div>

    <div>
      <h4>Como funciona um gerador de tons?</h4>
      <p>Um aplicativo de gerador de tons gera tons sonoros produzindo ondas sonoras em frequências específicas. Os usuários podem ajustar a frequência, amplitude e forma de onda dos tons gerados.</p>
    </div>

    <div>
      <h4>Para que posso usar um gerador de tons?</h4>
      <p>Um gerador de tons pode ser usado para diversos fins, como testar equipamentos de áudio, ajustar instrumentos musicais, realizar experimentos de engenharia de som e até mesmo para relaxamento.</p>
    </div>

    <div>
      <h4>Posso usar um gerador de tons para ajustar instrumentos musicais?</h4>
      <p>Sim, você pode usar um gerador de tons reproduzindo tons com frequências específicas para ajustar instrumentos musicais.</p>
    </div>

    <div>
      <h4>Esta ferramenta é compatível com dispositivos iOS?</h4>
      <p>Definitivamente. Esta ferramenta é compatível com todos os dispositivos. Basta abrir o seu navegador e ir para MicTestAudio.com para acessar o Gerador de Tons online. Nosso site é acessível em vários navegadores, incluindo Chrome, Safari, Edge e Firefox.</p>
    </div>

    <div>
      <h4>Que tipos de formas de onda o gerador de tons suporta?</h4>
      <p>O gerador de tons geralmente suporta várias formas de onda, incluindo senoidal, quadrada, triangular e dente de serra. Cada forma de onda produz uma característica sonora diferente.</p>
    </div>

    <div>
      <h4>Quão precisas são as frequências geradas pelo gerador de tons?</h4>
      <p>As frequências geradas pelo gerador de tons são geralmente precisas dentro de um certo nível de tolerância, dependendo da sensibilidade do algoritmo de geração de som e das capacidades do hardware do dispositivo.</p>
    </div>

    <div>
      <h4>O aplicativo de gerador de tons é gratuito?</h4>
      <p>Muitos aplicativos de gerador de tons oferecem funcionalidades básicas gratuitamente, enquanto recursos avançados geralmente estão disponíveis por meio de compras dentro do aplicativo ou versões premium.</p>
    </div>

    <div>
      <h4>O aplicativo de gerador de tons é compatível com todos os dispositivos e navegadores?</h4>
      <p>A compatibilidade pode variar de acordo com o dispositivo e o navegador. É recomendável verificar os requisitos do sistema do aplicativo para obter o melhor desempenho.</p>
    </div>
  </div>
</div>



      <Fpt/>
      </div>
    );
  }
}

export default Dpt;
