import React, { useState, useEffect, useRef } from 'react';
import { IoMicOutline } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import './mic.css'
import { FaMicrophone } from "react-icons/fa6";
import { FaSquare } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import Fnl from '../fOOTER/Fnl';
import { Helmet } from 'react-helmet';
import NHeader from '../Header/NHeader';

const Mnl = () => {

  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [layout, setLayout] = useState(1);

  const canvasRef = useRef(null);
  const analyserRef = useRef(null);
  const animationIdRef = useRef(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
 

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = (e) => {
          setAudioChunks((chunks) => [...chunks, e.data]);
        };
      } catch(error){
        setError(<div id='failedaccess'><h6>Toegang tot microfoon mislukt.</h6><h6>Controleer uw browserinstellingen en schakel microfoontoegang in.</h6><IoIosWarning id='sicon'/></div>);
      }
      
    };

    getMediaStream();

    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    
    };
  }, []);

  


  useEffect(() => {
    if (audioStream && !analyserRef.current) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);
      analyserRef.current = analyser;
    }
  }, [audioStream]); 
  
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [navigate, audioStream]);


  const startRecording = () => {
    if (audioRecorder) {
      try{
      audioChunks.length = 0;
      audioRecorder.start();
      setIsRecording(true);
      setLayout(2);
      drawWaveform();
      }
      catch(error){
        setError(<div id='failedaccess'><h6>Toegang tot microfoon mislukt.</h6><h6>Controleer uw browserinstellingen en schakel microfoontoegang in.</h6><IoIosWarning id='sicon'/></div>);
      }
      
    }
  };



  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      setLayout(3);
    }
  };

  const downloadAudio = () => {
    if (audioUrl) {
      const element = document.createElement('a');
      element.href = audioUrl;
      element.download = 'recorded_audio.wav';
      element.click();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

  
  const drawWaveform = () => {
    if (layout === 2) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);
      analyserRef.current.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height); 

      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(72, 131, 219)';

      ctx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();

      animationIdRef.current = requestAnimationFrame(drawWaveform);
    }
  };

  useEffect(() => {
    if (layout === 2) {
      drawWaveform();
    } else {
      cancelAnimationFrame(animationIdRef.current); 
    }
  }, [layout]);

  useEffect(() => {
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioChunks]);

  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationIdRef.current); 
    };
  }, []);


 
  return (
    <div>
      <NHeader/>
      <Helmet>
    <meta charSet="utf-8" />
    <title>Microfoontest Online</title>
    <meta name='description' content='Eenvoudige manier om te controleren of uw microfoon werkt. Klik gewoon op het microfoonpictogram om te controleren of uw microfoon perfect werkt. Om het te gebruiken, klik gewoon op het microfoonpictogram.'/>
    <meta name='keywords' content='microfoontest app, test mijn microfoon, hallo microfoontest, microfoontest online, Google microfoontest'/>
</Helmet>

<div className='headingsarea'>
  <div id='titlesmic'>
    <h1><span><IoMicOutline className='icons'/></span>Microfoongeluid Testen</h1>
    <h2>Test Uw Apparaatmicrofoon, Neem Geluid Op en Download</h2>
  </div>
</div>
<div className='micarea'>
  <div className='mictester'>
    <div id='left'>
      <h3>Volg deze 3 stappen om uw microfoon te testen:</h3>
      <ul id='ulleft'>
        <li><strong>Stap 1: </strong>Klik op het <strong>"microfoonpictogram"</strong> om de test te starten <strong>en sta toestemming toe.</strong></li>
        <li><strong>Stap 2: </strong>Zeg iets in uw microfoon zoals <strong>Hallo </strong>om te controleren of het werkt.</li>
        <li><strong>Stap 3: </strong>Als u <strong>geluidsgolven</strong> ziet, dan werkt uw microfoon perfect.</li>
      </ul>
    </div>
    <div id='right'>
      <div className='rightone'>
        {error && <div id='errorpara'>{error}</div>}
        {!error && (
          <>
            {layout===1 && (
              <div id='firststep'>
                <h6>Klik op de Microfoon om de Microfoontest te starten</h6>
                <button onClick={startRecording}><FaMicrophone id='micicon' title='Opnemen Starten'/></button>
              </div>
            )}

            {layout === 2 && (
              <div id='secondstep'>
                <div id='xdiv'>
                  <canvas id='canvas' ref={canvasRef} width="200" height="80"></canvas>
                </div>
                <button onClick={stopRecording}><FaSquare id='stopicon' title='Opname Stoppen'/></button>
              </div>
            )}

            {layout === 3 && (
              <div>
                {audioUrl && (
                  <div id='thirdstep'>
                    <div id='urldiv'>
                      <audio controls src={audioUrl}></audio>
                    </div>
                    <div id='bdiv'>
                      <button onClick={refreshPage}>Opnieuw Proberen</button>
                      <button onClick={downloadAudio}>Geluid Downloaden</button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  </div>
</div>

      
<div className='faqmic'>
  <div id='fnqmic'>
    <div>
      <h4>Hoe kan ik mijn microfoon testen met behulp van deze app?</h4>
      <p>Om uw microfoon te testen, navigeert u eenvoudig naar het hoofdfunctionaliteitsgebied van de app en klikt u op de aangewezen Test Microfoon knop. Volg eventuele instructies op het scherm die door de app worden verstrekt om de test te voltooien.</p>
    </div>

    <div>
      <h4>Wat moet ik doen als mijn microfoon niet werkt tijdens de test?</h4>
      <p>Als uw microfoon niet werkt tijdens de test, zorg er dan eerst voor dat deze correct is aangesloten op uw apparaat en dat de benodigde toestemmingen aan de app zijn verleend om toegang te krijgen tot uw microfoon. Als het probleem aanhoudt, probeer dan de pagina te vernieuwen of uw apparaat opnieuw op te starten.</p>
    </div>

    <div>
      <h4>Kan ik de microfooninstellingen binnen de app aanpassen?</h4>
      <p>Op dit moment biedt de app mogelijk geen directe instellingen voor het aanpassen van de microfoon. U kunt echter meestal de microfooninstellingen aanpassen via de systeemvoorkeuren of instellingenmenu van uw apparaat. Raadpleeg de documentatie van uw apparaat voor specifieke instructies over het aanpassen van de microfooninstellingen.</p>
    </div>

    <div>
      <h4>Welke browsers worden ondersteund voor het testen van de microfoon?</h4>
      <p>Onze app ondersteunt een breed scala aan moderne webbrowsers, waaronder Google Chrome, Mozilla Firefox, Safari en Microsoft Edge. Zorg ervoor dat u de nieuwste versie van uw voorkeursbrowser gebruikt voor optimale prestaties.</p>
    </div>

    <div>
      <h4>Worden mijn microfoongegevens opgeslagen of opgenomen tijdens de test?</h4>
      <p>Nee, de app slaat geen gegevens op of neemt geen gegevens op van uw microfoontests. Uw privacy en veiligheid zijn belangrijk voor ons, en we behouden geen audioregistraties of persoonlijke informatie die tijdens de test is verzameld.</p>
    </div>

    <div>
      <h4>Ik hoor ruis of achtergrondgeluid tijdens de microfoontest. Wat moet ik doen?</h4>
      <p>Ruis of achtergrondgeluid tijdens de microfoontest kan worden veroorzaakt door verschillende factoren zoals omgevingsinterferentie of problemen met de microfoonhardware. Probeer uw microfoon te testen in een stillere omgeving en zorg ervoor dat deze correct is gepositioneerd. Als het probleem aanhoudt, overweeg dan een andere microfoon te gebruiken of neem contact op met technische ondersteuning voor hulp.</p>
    </div>

    <div>
      <h4>Is deze tool compatibel met iOS-apparaten?</h4>
      <p>Absoluut. Deze tool is compatibel met alle apparaten. Open eenvoudig uw browser en ga naar MicTestAudio.com om de microfoontest te openen. Onze website is toegankelijk op verschillende browsers, waaronder Chrome, Safari, Edge en Firefox.</p>
    </div>

    <div>
      <h4>Hoe nauwkeurig zijn de microfoontestresultaten die door de app worden geleverd?</h4>
      <p>De microfoontestresultaten die door de app worden geleverd, zijn over het algemeen nauwkeurig voor het beoordelen van de basisfunctionaliteit van de microfoon. Houd er echter rekening mee dat de nauwkeurigheid van de test kan variëren afhankelijk van factoren zoals apparaathardware, softwarecompatibiliteit en omgevingsomstandigheden.</p>
    </div>

    <div>
      <h4>Ik heb de microfoontest voltooid, maar ik ondervind nog steeds problemen met audio-invoer/-uitvoer. Wat moet ik doen?</h4>
      <p>Als u de microfoontest hebt voltooid, maar nog steeds problemen ondervindt met audio-invoer of -uitvoer, overweeg dan het oplossen van de audioproblemen van uw apparaat of raadpleeg technische ondersteuning voor verdere assistentie. Het is mogelijk dat het probleem te maken heeft met softwareconflicten, driver-updates of hardwarestoringen.</p>
    </div>
  </div>
</div>

      <Fnl/> 
    </div>
  )
}

export default Mnl