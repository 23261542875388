import React from 'react'
import './Home.css'
import { Link} from "react-router-dom";
import { MdDevices } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { PiWaveSineLight } from "react-icons/pi";
import { LuSpeaker } from "react-icons/lu";
import { MdOutlineFeaturedVideo } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import { IoMicOutline } from "react-icons/io5";
import Footer from './fOOTER/Footer';
import Header from './Header/Header';



const Home = () => {

  return (
    <div>
      <Header/>
        <div id='alpha'>
        <div className='titles'>
        <h1><strong>Sound Test Audio</strong></h1>
        <h2 >Test Your Setup Online Speaker, Headphones Sound and Mic Test</h2>
        </div>
        </div>
        <div className='flexdiv'>
            <ul className='toollist'>
                <li>
                  
                  <Link id='rlinks' title='Online Sound Test' to="/sound-test" ><LuSpeaker className='icons'/>Sound Test</Link>
                    
                   
                </li>
                <li>
                  
                <Link id='rlinks' title='Online Mic Test' to='/mic-test' ><IoMicOutline className='icons'/>Mic Test</Link>
                
                
                </li>
                <li>
                  
                <Link id='rlinks' title='Tone Generator' to='/tone-generator' ><PiWaveSineLight className='icons'/>Tone Generator</Link>
                
                
                </li>
            </ul>
        </div>

        <div className='fdiv'>
          <div id='features'>
            <div id='indiv'>
            <BiSelectMultiple id='hicons'/>
              <h6>Fast Performance and Efficiency</h6>
              <p>Our tools offer a swift and effective method for testing your microphone, headphones, and speakers. These complimentary utilities remove the necessity for downloads or software installations, ensuring convenient accessibility. Users can utilize them repeatedly, contingent upon maintaining a stable internet connection.</p>
            </div>

            <div id='indiv'>
              <MdDevices id='hicons'/>
              <h6>Multiple Device Capability</h6>
              <p>Our tools support various devices, providing compatibility across platforms for diverse setups. Access them effortlessly on desktops, laptops, tablets, or smartphones to perform real-time testing and generate tones.</p>
            </div>

            <div id='indiv'>
            <IoIosLock id='hicons'/>
              <h6>Security and Privacy</h6>
              <p>Ensuring your security and privacy is our top priority. Our website removes the requirement for account registration or sharing personal data. Your mic audio is never stored; it is automatically deleted each time you refresh the page. This approach enables you to concentrate fully on exploring our features without encountering unnecessary obstacles.</p>
            </div>

            <div id='indiv'>
            <MdOutlineFeaturedVideo id='hicons'/>
              <h6>Features</h6>
              <p>Our website has lots of cool stuff for audio lovers. Users can effortlessly explore features, You can make tones, test your microphone, and check how your device sounds. It's easy to use whether you're a pro, a tech person, or just curious about sound.</p>
            </div>
          </div>

        </div>
        <Footer/>
  
    </div>
  )
}

export default Home