import React, { Component } from 'react';
import './another.css'
import Ffr from '../fOOTER/Ffr';
import FHeader from '../Header/FHeader';
 
class Dfr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frequency: 440, 
      waveform: 'sine',
      volume: 0.5, 
      balance: 0, 
      isPlaying: false
    };
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.oscillator = null;
    this.leftGainNode = this.audioContext.createGain();
    this.rightGainNode = this.audioContext.createGain();
    this.panNode = this.audioContext.createStereoPanner();
    this.setBalance(0);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.stopSound(); 
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.stopSound();
    } else {
      
      if (this.state.isPlaying) {
        this.playSound();
      }
    }
  };

  handleFrequencyChange = (e) => {
    this.setState({ frequency: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleWaveformChange = (e) => {
    this.setState({ waveform: e.target.value });
    if (this.state.isPlaying) {
      this.playSound();
    }
  };

  handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    this.setState({ volume });
    this.leftGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    this.rightGainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
  };

  handleBalanceChange = (e) => {
    const balance = parseFloat(e.target.value);
    this.setState({ balance });
    this.setBalance(balance);
  };

  setBalance = (balance) => {
    const panValue = balance / 100;
    this.panNode.pan.setValueAtTime(panValue, this.audioContext.currentTime);
  };

  playSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
    }
    this.oscillator = this.audioContext.createOscillator();
    this.oscillator.type = this.state.waveform;
    this.oscillator.frequency.setValueAtTime(this.state.frequency, this.audioContext.currentTime);

    this.oscillator.connect(this.panNode);
    this.panNode.connect(this.leftGainNode);
    this.panNode.connect(this.rightGainNode);
    this.leftGainNode.connect(this.audioContext.destination); 
    this.rightGainNode.connect(this.audioContext.destination); 

    this.oscillator.start();
    this.setState({ isPlaying: true });
};


  stopSound = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.oscillator.disconnect();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    return (
      <div>
        <FHeader/>
        <div id='tmaintone'>
  <div id='titlestone'>
    <h1>Générateur de Tons en Ligne</h1>
    <p>Veuillez sélectionner la fréquence préférée et appuyer sur le bouton de lecture pour initier le processus de génération de tons. Cet outil est gratuit et vous permet de tester vos écouteurs ou haut-parleurs avec le Générateur de Tons en Ligne. Le Générateur de Tons propose quatre formes d'onde distinctes : Sinusoïdale, Carrée, Dent de scie et Triangulaire. Vous pouvez choisir la forme d'onde souhaitée en cliquant sur les boutons correspondants fournis.</p>
  </div>
</div>

<div id='mainarea'>
  <div id='tonearea'>
    <div className='frequency'>
      <label className='labels'>Fréquence</label>
      <div id='insidefreq'>
        <div id='frdiv'>
          <input
            id='frange'
            type="range"
            min="20"
            max="2000"
            value={this.state.frequency}
            onChange={this.handleFrequencyChange}
          />
        </div>
        <div id='hzz'>
          <div id='fnumbers'>{this.state.frequency}</div>
        </div>
        <div id='hz'>Hz</div>
      </div>
    </div>

    <div className='playbuttons'>
      <div className='buttons'>
        <button id='p1' onClick={this.playSound} disabled={this.state.isPlaying}>
          Lecture
        </button>
        <button id='p2' onClick={this.stopSound} disabled={!this.state.isPlaying}>
          Arrêter
        </button>
      </div>
    </div>

    <div className='waveform'>
      <label>Forme d'Onde</label>
      <div className='waveforms'>
        <label>
          <input
            type="radio"
            value="sine"
            checked={this.state.waveform === 'sine'}
            onChange={this.handleWaveformChange}
          />
          Sinusoïdale
        </label>
        <label>
          <input
            type="radio"
            value="square"
            checked={this.state.waveform === 'square'}
            onChange={this.handleWaveformChange}
          />
          Carrée
        </label>
        <label>
          <input
            type="radio"
            value="triangle"
            checked={this.state.waveform === 'triangle'}
            onChange={this.handleWaveformChange}
          />
          Triangulaire
        </label>
        <label>
          <input
            type="radio"
            value="sawtooth"
            checked={this.state.waveform === 'sawtooth'}
            onChange={this.handleWaveformChange}
          />
          Dent de Scie
        </label>
      </div>
    </div>

    <div className='volume'>
      <label className='labels'>Volume</label>
      <div id='insidevol'>
        <div id='voldiv'>
          <input
            id='vrange'
            type="range"
            min="0"
            max="15"
            step="1"
            value={this.state.volume}
            onChange={this.handleVolumeChange}
          />
        </div>
        <div id='volumenumber'>
          {this.state.volume}
        </div>
      </div>
    </div>

    <div className='balance'>
      <div id='bal'>
        <label>Balance</label>
        <div id='balancediv'>
          <div>G</div>
          <input
            id='brange'
            type="range"
            min="-100"
            max="100"
            value={this.state.balance}
            onChange={this.handleBalanceChange}
          />
          <div>D</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id='headi'>
  <div id='hhdiv'>
    <h5>Veuillez noter de maintenir le volume de vos écouteurs ou haut-parleurs à un niveau bas pour éviter de causer des dommages à vos oreilles ou à votre équipement. Certains équipements audio peuvent ne pas bien gérer les fréquences extrêmes. Soyez attentif aux capacités de vos haut-parleurs ou écouteurs lors de la génération de tons, en particulier aux fréquences élevées ou basses, pour éviter d'éventuels dommages.</h5>
  </div>
</div>

<div id='hdiv'>
  <div id='howto'>
    <h3>Comment Utiliser le Générateur de Tons</h3>
    <ol>
      <li><strong>Sélection de la Fréquence :</strong> Commencez par ajuster la fréquence souhaitée à l'aide du curseur de fréquence fourni.</li>
      <li><strong>Sélection de la Forme d'Onde :</strong> Choisissez la forme d'onde appropriée parmi la sélection disponible dans la section des formes d'onde.</li>
      <li><strong>Ajustement du Volume :</strong> Avant de générer des tons, il est recommandé d'ajuster le volume à un niveau initial plus bas.</li>
      <li><strong>Lecture des Tons :</strong> Cliquez sur le bouton Lecture pour initier la génération des tons. Pour arrêter la génération des tons, il suffit de cliquer sur le bouton Arrêter.</li>
      <li><strong>Ajustement de la Balance des Canaux :</strong> Ajustez finement la balance entre les canaux gauche et droit à l'aide du contrôle de balance.</li>
    </ol>
  </div>
</div>


      
<div className='faqtone'>
  <div id='fnqtone'>
    <div>
      <h4>Qu'est-ce qu'un générateur de tonalités ?</h4>
      <p>Un générateur de tonalités est un instrument qui produit des tonalités audio à différentes fréquences. Il est couramment utilisé pour tester le matériel audio, créer de la musique ou comme outil thérapeutique.</p>
    </div>

    <div>
      <h4>Comment fonctionne le générateur de tonalités ?</h4>
      <p>L'application du générateur de tonalités génère des tonalités audio en produisant des fréquences spécifiques d'ondes sonores. Les utilisateurs peuvent ajuster la fréquence, l'amplitude et la forme d'onde des tonalités générées.</p>
    </div>

    <div>
      <h4>À quoi puis-je utiliser le générateur de tonalités ?</h4>
      <p>Le générateur de tonalités peut être utilisé à diverses fins, telles que le test du matériel audio, l'accordage des instruments de musique, la réalisation d'expériences en ingénierie sonore et même pour la relaxation.</p>
    </div>

    <div>
      <h4>Puis-je utiliser le générateur de tonalités pour accorder des instruments de musique ?</h4>
      <p>Oui, vous pouvez utiliser le générateur de tonalités pour accorder des instruments de musique en jouant des tonalités de fréquences spécifiques correspondant à différentes notes.</p>
    </div>

    <div>
      <h4>Cet instrument est-il compatible avec les appareils iOS ?</h4>
      <p>Absolument. Cet instrument est compatible avec tous les appareils. Il vous suffit d'ouvrir un navigateur et de vous rendre sur MicTestAudio.com pour accéder au Générateur de Tonalités en Ligne. Notre site est accessible sur différents navigateurs, y compris Chrome, Safari, Edge et Firefox.</p>
    </div>

    <div>
      <h4>Quels types de formes d'onde le générateur de tonalités prend-il en charge ?</h4>
      <p>Le générateur de tonalités prend généralement en charge diverses formes d'onde, y compris sinusoïdale, carrée, triangulaire et en dents de scie. Chaque forme d'onde produit un son distinctif caractéristique.</p>
    </div>

    <div>
      <h4>À quel point les fréquences produites par le générateur de tonalités sont-elles précises ?</h4>
      <p>Les fréquences produites par le générateur de tonalités sont généralement précises dans une certaine mesure de tolérance, en fonction de la précision de l'algorithme de génération audio et des capacités matérielles de l'appareil.</p>
    </div>

    <div>
      <h4>L'application du générateur de tonalités est-elle gratuite ?</h4>
      <p>De nombreuses applications de générateur de tonalités offrent une fonctionnalité de base gratuite, tandis que des fonctionnalités avancées peuvent être disponibles via des achats in-app ou des versions premium.</p>
    </div>

    <div>
      <h4>L'application du générateur de tonalités est-elle compatible avec tous les appareils et navigateurs ?</h4>
      <p>La compatibilité peut varier en fonction de l'appareil et du navigateur. Il est recommandé de vérifier les exigences système de l'application pour des performances optimales.</p>
    </div>
  </div>
</div>


      <Ffr/>
      </div>
    );
  }
}

export default Dfr;
