import React from 'react';
import './about.css';
import FHeader from '../Header/FHeader';
import Ffr from '../fOOTER/Ffr';

export default function Afr() {
  return (
    <div>
      <FHeader/>
      <div className='mainabout'>
        <div className='titlesabout'>
          <h1>À propos</h1>
          <p>Salutations et bienvenue sur MicTestAudio.com ! Je suis Pardeep et je suis heureux de vous présenter cette plateforme, qui est née de mon exploration du monde de la programmation.</p>
          <p>L'idée de MicTestAudio.com est née d'un défi personnel, lorsque le haut-parleur gauche de mes écouteurs a cessé de fonctionner. Déterminé à surmonter cet obstacle, j'ai commencé à développer un outil simple mais efficace pour vérifier l'audio des canaux gauche et droit. Avec l'augmentation de mes connaissances en programmation, j'ai réalisé qu'il y avait une opportunité de transformer cet outil en une ressource complète pour les tests audio et le dépannage.</p>
          <p>MicTestAudio.com a évolué au fil du temps et a intégré des outils et des fonctionnalités supplémentaires pour répondre à divers besoins de test audio. Cependant, notre voyage est loin d'être terminé. Je m'engage à améliorer continuellement cette plateforme en introduisant davantage d'outils et de fonctionnalités pour mieux vous servir.</p>
          <p>Vos retours et suggestions sont très importants pour nous. Si vous avez des idées ou des recommandations pour améliorer MicTestAudio.com, n'hésitez pas à me contacter à l'adresse sevensitexd@gmail.com. Vos contributions nous aident à évoluer et à mieux répondre à vos besoins en matière de test audio.</p>
          <p>Merci encore de visiter MicTestAudio.com. Nous vous remercions de votre soutien et avons hâte de vous servir encore mieux à l'avenir.</p>
        </div>
      </div>
      <Ffr/>
    </div>
  );
}
