import React from 'react';
import { Link } from "react-router-dom";
import './footer.css';

const Ffr = () => {
  return (
    <div>
      <footer id='footer'>
        <div id='foodiv'>
          <div id='one'>
            <ul>
              <Link id='rslinks' title='Accueil' to="/fr/">Accueil</Link><span>|</span> 
              <Link id='rslinks' title='Test de Son' to="/fr/sound-test">Test de Son</Link><span>|</span>
              <Link id='rslinks' title='Test de Microphone' to="/fr/mic-test">Test de Microphone</Link><span>|</span>
              <Link id='rslinks' title='Générateur de Tons' to="/fr/tone-generator">Générateur de Tons</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='two'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/fr/">MicTestAudio.com</Link> <span>|</span>
              <Link id='rslinks' title='À Propos de' to="/fr/about">À Propos de</Link> <span>|</span>
              <Link id='rslinks' title='Politique de Confidentialité' to="/privacy-policy">Politique de Confidentialité</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact">Contact</Link>
            </ul>
          </div>
          <div id='three'>
            <ul>
              <Link id='rslinks' title='Accueil' to="/fr/">Accueil</Link><span>|</span> 
              <Link id='rslinks' title='Test de Son' to="/fr/sound-test">Test de Son</Link><span>|</span>
              <Link id='rslinks' title='Test de Microphone' to="/fr/mic-test">Test de Microphone</Link>
            </ul>
          </div>
          <div id='four'>
            <ul>
              <Link id='rslinks' title='Générateur de Tons' to="/fr/tone-generator">Générateur de Tons</Link> <span>|</span>
              <a id='rslinks' href='https://blog.mictestaudio.com/' target="_blank" rel="noopener noreferrer">Blog</a> 
            </ul>
          </div>
          <div id='five'>
            <ul>
              <Link id='rslinks' title='À Propos de' to="/fr/about">À Propos de</Link> <span>|</span>
              <Link id='rslinks' title='Politique de Confidentialité' to="/privacy-policy">Politique de Confidentialité</Link><span>|</span>
              <Link id='rslinks' title='Contact' to="/contact">Contact</Link>
            </ul>
          </div>
          <div id='six'>
            <ul>
              <span>©</span><Link id='rslinks' title='MicTestAudio' to="/fr/">MicTestAudio.com</Link>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Ffr;
